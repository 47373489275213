import React, {useEffect, useRef} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Checkbox, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup} from "@mui/material";
import {useState} from "react";
import {useHistory, useLocation} from "react-router";
import {ReactSession} from "react-client-session";
import useFetch from "./UseFetch";
import {toast} from "react-toastify";
import {DataGrid} from '@mui/x-data-grid';
import ImageUploading from "react-images-uploading";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {ReactComponent as LogoSvgHeartOutline} from "../images/svg/heart_outline.svg";
import {ReactComponent as LogoSvgHeart} from "../images/svg/heart.svg";
import {ReactComponent as LogoUser} from "../images/svg/Login.svg";
import AddIcon from "@mui/icons-material/Add";
import {ReactComponent as TrashIcon} from "../images/svg/mulleimer.svg";
import ClearIcon from "@mui/icons-material/Clear";
import {CssTextField3} from "./CssTextField3";
import {CssTextField4} from "./CssTextField4";
import {CustomButton} from "./CustomButton";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import validator from "validator"; // Import css

const EditAddress = React.forwardRef((props, ref) => {
    const {
        type,
        index,
        id,
        createInvoiceAddress,
        updateInvoiceAddress,
        deleteInvoiceAddress,
        isDefault,
        setIsDefault,
        companyName,
        setCompanyName,
        companyNameError,
        setCompanyNameError,
        street,
        setStreet,
        streetError,
        setStreetError,
        nr,
        setNr,
        nrError,
        setNrError,
        zipcode,
        setZipcode,
        zipcodeError,
        setZipcodeError,
        city,
        setCity,
        cityError,
        setCityError,
        email,
        setEmail,
        emailError,
        setEmailError,
        ustId,
        setUstId,
        ustIdError,
        setUstIdError,
        newAddress,
        setNewAddress,
        setEditAddress
    } = props;

    const validateEmail = email => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
    };

    const validateInvoiceAddress = () => {
        let error = false;
        if (!companyName || companyName == '') {
            setCompanyNameError('Firmenname darf nicht leer sein');
            error = true;
        } else {
            setCompanyNameError(false);
        }
        if (!street || street == '') {
            setStreetError('Straße darf nicht leer sein');
            error = true;
        } else {
            setStreetError(false);
        }

        if (!nr || nr == '') {
            setNrError('Nr darf nicht leer sein');
            error = true;
        } else {
            setNrError(false);
        }
        if (!zipcode || zipcode == '') {
            setZipcodeError('PLZ darf nicht leer sein');
            error = true;
        } else {
            setZipcodeError(false);
        }
        if (!city || city == '') {
            setCityError('Ort darf nicht leer sein');
            error = true;
        } else {
            setCityError(false);
        }
        if (!ustId || ustId == '') {
            setUstIdError('UST-ID-Nummer darf nicht leer sein');
            error = true;
        } else {
            setUstIdError(false);
        }


        if (!email || email == '') {
            setEmailError('E-Mail darf nicht leer sein');
            error = true;
        } else {
            setEmailError(false);
        }

        if (email && !validateEmail(email)) {
            setEmailError('Keine gültige E-Mail');
            error = true;
        }
        return !error;
    }

    return (
        <div ref={ref} key={id} style={{
            border: '2px solid #C7D300',
            borderRadius: 25,
            // height: 200,
            marginTop: (index === 0 || id === -1) ? 35 : 15,
            // padding: '10px 15px 15px 15px',
            padding: '10px 15px 20px 15px',
            textAlign: 'left'
        }}>
            <Grid container>
                <Grid item xs={8} style={{paddingTop: 5}}>
                    <p style={{
                        margin: 0,
                        padding: 0,
                        textAlign: 'left',
                        fontFamily: 'VagRoundedBold',
                        fontSize: 14
                    }}
                       className={'color-blue'}>Adresse {type === 'new' ? <>anlegen</> : <>bearbeiten</>}</p>
                    <FormControl className={'testC'} style={{color: '#C7D300'}}>
                        <RadioGroup row>
                            <FormControlLabel onClick={(e) => {
                                e.preventDefault()
                                console.log('click')
                                setIsDefault(!isDefault)
                            }} control={<Radio checked={isDefault}/>}
                                              label="Als Standardadresse festlegen"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={4} style={{textAlign: 'right'}}>
                    {(id && id !== -1) && <TrashIcon
                        onClick={() => {
                            deleteInvoiceAddress(id);
                        }}
                        style={{
                            height: 30,
                            width: 30,
                            marginRight: 2,
                            transition: 'all 0.2s ease-in-out',
                            textAlign: 'center',
                            fill: '#C7D300',
                            cursor: 'pointer',
                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                        }}
                    />}
                    <ClearIcon
                        className={'not-yellow'}
                        onClick={() => {
                            setIsDefault(false);
                            setCompanyName('');
                            setCompanyNameError(false);
                            setStreet('');
                            setStreetError(false);
                            setNr('');
                            setNrError(false);
                            setZipcode('');
                            setZipcodeError(false);
                            setCity('');
                            setCityError(false);
                            setEmail('');
                            setEmailError(false);
                            setUstId('');
                            setUstIdError(false);
                            if (type === 'new') {
                                setNewAddress(false);
                                window.scrollTo(0, 0);
                            } else if (type === 'edit') {
                                setEditAddress(-1);
                            }
                        }}
                        style={{
                            height: 30,
                            width: 30,
                            marginRight: 2,
                            transition: 'all 0.2s ease-in-out',
                            textAlign: 'center',
                            fill: '#00374e',
                            cursor: 'pointer',
                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CssTextField4
                        label="Firmenname*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setCompanyNameError(true);
                            } else {
                                setCompanyNameError(false);
                            }
                            setCompanyName(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={companyName}
                        error={!!companyNameError}
                        helperText={companyNameError}
                    />
                </Grid>
                <Grid item xs={10}>
                    <CssTextField4
                        label="Straße*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setStreetError(true);
                            } else {
                                setStreetError(false);
                            }
                            setStreet(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={street}
                        error={!!streetError}
                        helperText={streetError}
                    />
                </Grid>
                <Grid item xs={2}>
                    <CssTextField4
                        label="Nr*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setNrError(true);
                            } else {
                                setNrError(false);
                            }
                            setNr(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={nr}
                        error={!!nrError}
                        helperText={nrError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="PLZ*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setZipcodeError(true);
                            } else {
                                setZipcodeError(false);
                            }
                            setZipcode(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={zipcode}
                        error={!!zipcodeError}
                        helperText={zipcodeError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="Ort / Sitz*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setCityError(true);
                            } else {
                                setCityError(false);
                            }
                            setCity(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={city}
                        error={!!cityError}
                        helperText={cityError}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CssTextField4
                        label="UST-ID-Nummer*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setUstIdError(true);
                            } else {
                                setUstIdError(false);
                            }
                            setUstId(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={ustId}
                        error={!!ustIdError}
                        helperText={ustIdError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="E-Mail*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setEmailError(true);
                            } else {
                                setEmailError(false);
                            }
                            setEmail(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={email}
                        error={!!emailError}
                        helperText={emailError}
                    />
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right', marginTop: 7}}>
                    <CustomButton
                        buttontype={'yellowBlue'}
                        style={{padding: '1.7px 15px', marginRight: 10}}
                        onClick={() => {
                            if (type === 'new' && validateInvoiceAddress()) createInvoiceAddress();
                            else if (type === 'edit' && validateInvoiceAddress()) updateInvoiceAddress(id);
                        }}
                    >Speichern</CustomButton>
                </Grid>
            </Grid>
        </div>
    );
});

export default function Rechnungen(props) {

    const {locationChange, menuAppBarRef} = props;
    const location = useLocation();
    const history = useHistory();
    const api = useFetch();

    useEffect(() => {
        if (ReactSession.get('user') != 'Arbeitgeber') {
            menuAppBarRef.current.logNeeded('Arbeitgeber');
            history.push('/');
        } else {
            document.title = 'Rechnungen';
            locationChange(location);
            getInvoiceAddresses();
            getInvoices();
        }
    }, [])

    const [buttonHover, setButtonHover] = useState(null);
    const [activeMatchItem, setActiveMatchItem] = useState(null);
    const [favorite, setFavorite] = useState(null);
    const [invoiceAddresses, setInvoiceAddresses] = React.useState([]);
    const [invoices, setInvoices] = React.useState([]);


    const [isDefault, setIsDefault] = useState(false);
    const [companyName, setCompanyName] = React.useState('');
    const [companyNameError, setCompanyNameError] = React.useState(false);
    const [street, setStreet] = React.useState('');
    const [streetError, setStreetError] = React.useState(false);
    const [nr, setNr] = React.useState('');
    const [nrError, setNrError] = React.useState(false);
    const [zipcode, setZipcode] = React.useState('');
    const [zipcodeError, setZipcodeError] = React.useState(false);
    const [city, setCity] = React.useState('');
    const [cityError, setCityError] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [ustId, setUstId] = React.useState('');
    const [ustIdError, setUstIdError] = React.useState(false);


    const [editAddress, setEditAddress] = React.useState(-1);
    const [newAddress, setNewAddress] = React.useState(false);
    const [getRef, setGetRef] = React.useState(false);
    const newAddressRef = React.createRef();


    const [images, setImages] = React.useState([]);
    const maxNumber = 1;

    const getInvoiceAddresses = async () => {

        let rows = [];
        let moreData = true;
        let pageNumber = 1;

        for (let i = 1; i < pageNumber + 1; i++) {
            let url = ENV.REACT_APP_API_URL + '/invoice-addresses?page%5Bsize%5D=20&page%5Bnumber%5D=' + i;

            let {response, data} = await api(url)


            if (!response || response.status == 401) {
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                history.push('/arbeitgeber');
                return;
            }
            if (data && data.data) {
                data.data.map((item, index) => {
                    item.attributes.isDefault = (item.attributes.isDefault == 1) ? true : false;
                    if (rows.filter(i => i.id === item.id).length === 0) {
                        rows.push(item);
                    } else {
                        rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])] = item;
                    }
                })

                if (data.meta.pagination.total === rows.length) {
                    moreData = false;
                }

            } else if (response.error) {
                notify('API Fehler: ' + response.status, true);
            }
        }

        rows.sort((a, b) => (a.attributes.isDefault == 1 ? -1 : 1));
        setInvoiceAddresses(rows);
    }

    const getInvoices = async () => {

        let rows = [];

        let url = ENV.REACT_APP_API_URL + '/purchase-overviews?page%5Bsize%5D=999';

        let {response, data} = await api(url)

        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/arbeitgeber');
            return;
        }
        console.log(data);
        if (data && data.data) {
            data.data.map((item, index) => {
                if (rows.filter(i => i.id === item.id).length === 0 && item.attributes.creator_id == ReactSession.get('userId')) {
                    rows.push(item);
                } else {
                    rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])] = item;
                }
            })

        } else if (response.error) {
            notify('API Fehler: ' + response.status, true);
        }

        setInvoices(rows);
    }

    useEffect(() => {
        if (newAddressRef.current && getRef && invoiceAddresses && invoiceAddresses.length > 1) {
            setGetRef(false);
            const yOffset = 0;
            const y = (newAddressRef.current.getBoundingClientRect().bottom - newAddressRef.current.getBoundingClientRect().y) + yOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }, [newAddressRef])

    const notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    const getPDF = async (url) => {

        let token = ReactSession.get('token');
        let headers = new Headers();
        let config;

        if (!url.includes('/user/login')) {
            headers.append('Authorization', `Bearer ${token}`);
        }
        config = {
            method: 'GET',
            headers: headers
        }

        let response = await fetch(url, config);
        if (response.body) {
            //Create a Blob from the PDF Stream
            const blob = await response.blob();
            //Build a URL from the file
            const fileURL = URL.createObjectURL(blob);
            //Open the URL on new Window
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
        }
    }

    const createInvoiceAddress = async () => {
        let updatedAttributes = {
            data: {
                attributes: {
                    isDefault: isDefault === true ? 1 : 0,
                    companyName: companyName,
                    street: street,
                    houseNumber: nr,
                    zipcode: zipcode,
                    city,
                    email,
                    ustId
                },
                relationships: {}
            }
        }

        let url = ENV.REACT_APP_API_URL + "/invoice-addresses";
        const {response, data} = await api(url, 'POST', JSON.stringify(updatedAttributes));

        if (response && response.status != '404' && data) {
            setIsDefault(false);
            setCompanyName('');
            setCompanyNameError(false);
            setStreet('');
            setStreetError(false);
            setNr('');
            setNrError(false);
            setZipcode('');
            setZipcodeError(false);
            setCity('');
            setCityError(false);
            setEmail('');
            setEmailError(false);
            setUstId('');
            setUstIdError(false);
            setNewAddress(false);
            setEditAddress(-1);
            getInvoiceAddresses();
            notify('Deine Adresse wurde erfolgreich angelegt');
        } else if (response.status == '404') {

        }
    }

    const updateInvoiceAddress = async (id) => {
        let updatedAttributes = {
            data: {
                attributes: {
                    isDefault: isDefault === true ? 1 : 0,
                    companyName: companyName,
                    street: street,
                    houseNumber: nr,
                    zipcode: zipcode,
                    city,
                    email,
                    ustId
                },
                relationships: {}
            }
        }

        let url = ENV.REACT_APP_API_URL + "/invoice-address/" + id;
        const {response, data} = await api(url, 'PATCH', JSON.stringify(updatedAttributes));

        if (response && response.status != '404' && data) {
            setIsDefault(false);
            setCompanyName('');
            setCompanyNameError(false);
            setStreet('');
            setStreetError(false);
            setNr('');
            setNrError(false);
            setZipcode('');
            setZipcodeError(false);
            setCity('');
            setCityError(false);
            setEmail('');
            setEmailError(false);
            setUstId('');
            setUstIdError(false);
            setNewAddress(false);
            setEditAddress(-1);
            getInvoiceAddresses();
            notify('Deine Adresse wurde erfolgreich aktualisiert');
        } else if (response.status == '404') {

        }
    }

    const deleteInvoiceAddress = (id) => {
        console.log('DELETE')
        confirmAlert({
            title: 'Bestätigung',
            message: 'Möchtest Du diese Adresse wirklich löschen?',
            buttons: [
                {
                    label: 'Ja',
                    onClick: async () => {
                        console.log(id);
                        let url = ENV.REACT_APP_API_URL + '/invoice-address/' + id;
                        const {response, data} = await api(url, 'DELETE');
                        if (response && response.status == 204) {
                            setIsDefault(false);
                            setCompanyName('');
                            setCompanyNameError(false);
                            setStreet('');
                            setStreetError(false);
                            setNr('');
                            setNrError(false);
                            setZipcode('');
                            setZipcodeError(false);
                            setCity('');
                            setCityError(false);
                            setEmail('');
                            setEmailError(false);
                            setUstId('');
                            setUstIdError(false);
                            setNewAddress(false);
                            setEditAddress(-1);
                            getInvoiceAddresses();

                            notify('Deine Adresse wurde erfolgreich gelöscht');
                        } else if (response && response.status == 404) {

                        }else if (response && response.status == 500) {
                            notify('Löschen der Adresse nicht möglich. Diese Adresse wird aktuell in mindestens einer Stellenanzeige verwendet', true)
                        }
                    }
                },
                {
                    label: 'Nein',
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        });
    }

    return (
        <Grid className="mainApp" container style={{
            padding: 0,
        }}>
            <Grid id={'unternehmensprofil'} item xs={12} style={{
                display: 'block',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 64,
                marginBottom: activeMatchItem !== null ? 50 : 0,
                padding: '30px 50px 50px',
                width: '100%',
                minHeight: '800px',
            }}>

                <Grid id={'step5'} container style={{padding: 15}}>
                    <Grid item xs={12} style={{padding: '0'}}>
                        <p style={{margin: '20px 0', textAlign: 'left', fontFamily: 'VagRoundedBold', fontSize: 32}}
                           className={'color-blue'}>Rechnungen</p>
                    </Grid>
                    <Grid item xs={6} style={{padding: 5}}>
                        <div style={{
                            margin: '20px 0',
                            textAlign: 'left',
                            fontFamily: 'VagRoundedBold',
                            fontSize: 32,
                            position: 'relative',
                            marginTop: 2,
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingLeft: 20,
                            width: 'calc(100% - 20px)',
                            background: '#C7D300',
                            border: '2px solid #C7D300',
                            borderRadius: 100,
                            color: 'white',
                        }}
                             className={'color-blue'}>
                            Rechnungsadressen
                            <div style={{
                                display: 'flex',
                                position: 'absolute',
                                top: 9,
                                right: 0,
                                paddingRight: 15,
                                zIndex: 1
                            }}>
                                <ClearIcon
                                    className={'not-yellow'}
                                    onClick={() => {
                                        setGetRef(true);
                                        setNewAddress(true);
                                    }}
                                    style={{
                                        height: 35,
                                        width: 35,
                                        marginRight: 2,
                                        transition: 'all 0.2s ease-in-out',
                                        textAlign: 'center',
                                        fill: '#00374e',
                                        cursor: 'pointer',
                                        transform: 'rotate(45deg)',
                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                    }}
                                />
                            </div>
                        </div>
                        <FormControl className={'testD'} style={{
                            color: '#00374e',
                            textAlign: 'left',
                            width: '100%',
                            fontFamily: 'VagRoundedBold'
                        }}>
                            <RadioGroup row>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <span style={{
                                            color: '#00374e',
                                            paddingRight: 30,
                                            fontSize: 14
                                        }}>Rechnungen per</span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel onClick={(e) => {
                                            e.preventDefault()
                                        }} control={<Radio checked={true}/>}
                                                          label="E-Mail"/>
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                        <FormControl className={'testD'} style={{
                            color: '#00374e',
                            textAlign: 'left',
                            width: '100%',
                            fontFamily: 'VagRoundedBold'
                        }}>
                            <RadioGroup row>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <span style={{
                                            color: '#00374e',
                                            paddingRight: 30,
                                            fontSize: 14
                                        }}>Zahlungsmethoden</span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel onClick={(e) => {
                                            e.preventDefault()
                                        }} control={<Radio checked={true}/>}
                                                          label="Rechnung"/>
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>

                        {invoiceAddresses && invoiceAddresses.map((item, index) => {

                            if (editAddress === index) {
                                return (
                                    <EditAddress
                                        type={'edit'}
                                        index={index}
                                        id={item.id}
                                        isDefault={isDefault}
                                        setIsDefault={setIsDefault}
                                        createInvoiceAddress={createInvoiceAddress}
                                        updateInvoiceAddress={updateInvoiceAddress}
                                        deleteInvoiceAddress={deleteInvoiceAddress}
                                        companyName={companyName}
                                        setCompanyName={setCompanyName}
                                        companyNameError={companyNameError}
                                        setCompanyNameError={setCompanyNameError}
                                        street={street}
                                        setStreet={setStreet}
                                        streetError={streetError}
                                        setStreetError={setStreetError}
                                        nr={nr}
                                        setNr={setNr}
                                        nrError={nrError}
                                        setNrError={setNrError}
                                        zipcode={zipcode}
                                        setZipcode={setZipcode}
                                        zipcodeError={zipcodeError}
                                        setZipcodeError={setZipcodeError}
                                        city={city}
                                        setCity={setCity}
                                        cityError={cityError}
                                        setCityError={setCityError}
                                        email={email}
                                        setEmail={setEmail}
                                        emailError={emailError}
                                        setEmailError={setEmailError}
                                        ustId={ustId}
                                        setUstId={setUstId}
                                        ustIdError={ustIdError}
                                        setUstIdError={setUstIdError}
                                        newAddress={newAddress}
                                        setNewAddress={setNewAddress}
                                        setEditAddress={setEditAddress}
                                    />
                                );
                            }


                            if (editAddress !== index) {
                                return (

                                    <div key={index} style={{
                                        background: '#eee',
                                        border: '2px solid #eee',
                                        borderRadius: 25,
                                        margin: index === 0 ? '35px 0 0' : '15px 0 0',
                                        padding: '10px 15px 20px 25px',
                                        textAlign: 'left'
                                    }}>
                                        <Grid container>
                                            <Grid item xs={8}>

                                                <Grid container>
                                                    <Grid item xs={12} style={{paddingTop: 10}}>
                                                        <p style={{margin: 0}}>{item.attributes.companyName}</p>
                                                        <p style={{margin: 0}}>{item.attributes.street}&nbsp;{item.attributes.houseNumber}</p>
                                                        <p style={{margin: 0}}>{item.attributes.zipcode}&nbsp;{item.attributes.city}</p>
                                                        <p style={{margin: 0}}>{item.attributes.ustId}</p>
                                                        <p style={{margin: 0}}>{item.attributes.email}</p>
                                                    </Grid>
                                                </Grid>


                                            </Grid>
                                            <Grid item xs={4} style={{textAlign: 'right'}}>
                                                <TrashIcon
                                                    className={'not-yellow'}
                                                    onClick={() => {
                                                        deleteInvoiceAddress(item.id);
                                                    }}
                                                    style={{
                                                        height: 30,
                                                        width: 30,
                                                        marginRight: 2,
                                                        transition: 'all 0.2s ease-in-out',
                                                        textAlign: 'center',
                                                        fill: '#00374e',
                                                        cursor: 'pointer',
                                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                    }}
                                                />
                                                <EditIcon
                                                    className={'not-yellow'}
                                                    style={{
                                                        height: 30,
                                                        width: 30,
                                                        marginRight: 2,
                                                        transition: 'all 0.2s ease-in-out',
                                                        textAlign: 'center',
                                                        fill: '#00374e',
                                                        cursor: 'pointer',
                                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                    }}
                                                    onClick={() => {
                                                        console.log('EDIT')
                                                        setIsDefault(item.attributes.isDefault);
                                                        setCompanyName(item.attributes.companyName);
                                                        setCompanyNameError(false);
                                                        setStreet(item.attributes.street);
                                                        setStreetError(false);
                                                        setNr(item.attributes.houseNumber);
                                                        setNrError(false);
                                                        setZipcode(item.attributes.zipcode);
                                                        setZipcodeError(false);
                                                        setCity(item.attributes.city);
                                                        setCityError(false);
                                                        setEmail(item.attributes.email);
                                                        setEmailError(false);
                                                        setUstId(item.attributes.ustId);
                                                        setUstIdError(false);
                                                        setNewAddress(false);
                                                        setEditAddress(index);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                );
                            }
                        })}
                        <div style={{
                            margin: '0px 10px 20px 20px',
                            textAlign: 'left',
                            fontFamily: 'VagRoundedBold',
                            fontSize: 14,
                            position: 'relative',
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingLeft: 20,
                            width: 'auto',
                            background: 'rgba(238,238,238,0.2)',
                            // border: '2px solid #C7D300',
                            borderRadius: 100,
                            color: 'white',
                        }}
                             className={'color-blue'}>
                            Neue Adresse anlegen
                            <div style={{
                                display: 'flex',
                                position: 'absolute',
                                top: 6,
                                right: 0,
                                paddingRight: 15,
                                zIndex: 1
                            }}>
                                <ClearIcon
                                    className={'not-yellow-white'}
                                    onClick={() => {
                                        setGetRef(true);
                                        setNewAddress(true);
                                    }}
                                    style={{
                                        height: 18,
                                        width: 18,
                                        marginRight: 2,
                                        transition: 'all 0.2s ease-in-out',
                                        textAlign: 'center',
                                        fill: 'white',
                                        cursor: 'pointer',
                                        transform: 'rotate(45deg)',
                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                    }}
                                />
                            </div>
                        </div>
                        {newAddress && <EditAddress
                            ref={newAddressRef}
                            type={'new'}
                            id={-1}
                            isDefault={isDefault}
                            setIsDefault={setIsDefault}
                            createInvoiceAddress={createInvoiceAddress}
                            companyName={companyName}
                            setCompanyName={setCompanyName}
                            companyNameError={companyNameError}
                            setCompanyNameError={setCompanyNameError}
                            street={street}
                            setStreet={setStreet}
                            streetError={streetError}
                            setStreetError={setStreetError}
                            nr={nr}
                            setNr={setNr}
                            nrError={nrError}
                            setNrError={setNrError}
                            zipcode={zipcode}
                            setZipcode={setZipcode}
                            zipcodeError={zipcodeError}
                            setZipcodeError={setZipcodeError}
                            city={city}
                            setCity={setCity}
                            cityError={cityError}
                            setCityError={setCityError}
                            email={email}
                            setEmail={setEmail}
                            emailError={emailError}
                            setEmailError={setEmailError}
                            ustId={ustId}
                            setUstId={setUstId}
                            ustIdError={ustIdError}
                            setUstIdError={setUstIdError}
                            newAddress={newAddress}
                            setNewAddress={setNewAddress}
                        />}
                    </Grid>


                    <Grid item xs={6} style={{padding: 5}}>
                        <div style={{
                            margin: '20px 0 0 0',
                            textAlign: 'left',
                            fontFamily: 'VagRoundedBold',
                            fontSize: 32,
                            position: 'relative',
                            marginTop: 2,
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingLeft: 20,
                            width: 'calc(100% - 20px)',
                            background: '#C7D300',
                            border: '2px solid #C7D300',
                            borderRadius: 100,
                            color: 'white',
                        }}
                             className={'color-blue'}>
                            Rechnungen
                        </div>
                        {(invoices && invoices.length > 0) && invoices.map((item, index) => {
                            return (
                                <div key={index} style={{
                                    background: '#eee',
                                    border: '2px solid #eee',
                                    borderRadius: 25,
                                    margin: index === 0 ? '35px 0 0' : '15px 0 0',
                                    padding: '10px 15px 20px 25px',
                                    textAlign: 'left'
                                }}>
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <Grid container>
                                                <Grid item xs={12}
                                                      style={{paddingTop: 10, fontFamily: 'VagRoundedBold'}}
                                                      className={'color-blue'}>
                                                    <p style={{margin: 0}}>Rechnung Nr. {item.attributes.invoice_id}</p>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4} style={{textAlign: 'right'}}>
                                            <Grid container>
                                                <Grid item xs={12} style={{paddingTop: 10}}>
                                                    <a style={{textDecoration: 'underline', cursor: 'pointer'}} target={'_blank'} onClick={() => getPDF(ENV.REACT_APP_API_URL + '/file/' + item.attributes.invoice_id + '/download/content')}
                                                       >öffnen</a>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
