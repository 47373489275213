import React, { useEffect, useRef } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
    FormControlLabel,
    FormGroup, FormLabel,
    Grid,
    IconButton,
    Link,
    Modal, Popover, Radio, RadioGroup, Switch,
    TextareaAutosize,
    Tooltip,
    tooltipClasses
} from "@mui/material";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { ReactComponent as LogoSvgArbeitgeber1 } from "../images/svg/swiitcher_icon.svg";
import { ReactComponent as LogoSvgGefunden } from "../images/svg/gefunden.svg";
import { ReactComponent as LogoSvgHeart } from "../images/svg/heart.svg";
import { ReactComponent as LogoSvgHeartOutline } from "../images/svg/heart_outline.svg";
import { ReactComponent as LogoSvgPuzzle } from "../images/svg/puzzle.svg";
import { CssTextField3 } from "./CssTextField3";
import { useMemo } from "react";
import debounce from "lodash.debounce";
import LoremIpsum from "react-lorem-ipsum";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as LogoSvgQouselduselmark } from "../images/svg/qouselduselmark.svg";
import { ReactComponent as Einkaufswagen } from '../images/svg/einkaufswagen.svg';
import useFetch from "./UseFetch";
import { ReactSession } from "react-client-session";
import htmlToDraft from "html-to-draftjs";
import ContentState from "draft-js/lib/ContentState";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { toast } from "react-toastify";
import { CustomButton } from "./CustomButton";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import { ReactComponent as TestLogo } from "../images/svg/swiitcher_icon_blau.svg";
import moment from 'moment'
import useWindowDimensions from "./useWindowDimensions";
import ToggleSwitchMatchesArbeitgeber from "./ToggleSwitchMatchesArbeitgeber";
import InfiniteScroll from "react-infinite-scroll-component";
import { styled } from "@mui/styles";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { PlayArrow } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import EditIcon from "@mui/icons-material/Edit";
import { ReactComponent as LogoSvgB } from "../images/svg/B.svg";
import { ReactComponent as LogoSvgC } from "../images/svg/C.svg";
import { ReactComponent as LogoSvgD } from "../images/svg/D.svg";
import { ReactComponent as LogoSvgL } from "../images/svg/L.svg";
import { ReactComponent as LogoSvgFL } from "../images/svg/FL.svg";
import FormControl from '@mui/material/FormControl';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const useQuery = () => new URLSearchParams(useLocation().search);

export default function MatchesArbeitgeber(props) {

    const { confirmedMatchesCount, locationChange, menuAppBarRef } = props;

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }}/>
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            border: '1px solid #dadde9',
        },
    }));

    const location = useLocation();
    const itemsRef = useRef([]);
    const history = useHistory();
    const api = useFetch();
    const query = useQuery();

    useEffect(() => {
        if (ReactSession.get('user') != 'Arbeitgeber') {
            if (!ReactSession.get('user')) {
                menuAppBarRef.current.logNeeded();
                history.push('/');
            } else if (ReactSession.get('user') == 'Arbeitnehmer') {
                menuAppBarRef.current.logNeeded('Arbeitgeber');
                history.push('/arbeitgeber');
            }
        }
            document.title = 'Matches';
            locationChange(location);
            if (query.get('id')) {
                checkIfMatchExists();
            } else {
                getData();
            }


    }, [])

    const [filterJobAdvertisementId, setFilterJobAdvertisementId] = useState(null);
    const [filterType, setFilterType] = useState(null);
    const [filterId, setFilterId] = useState(null);
    const [filterIndex1, setFilterIndex1] = useState(null);
    const [filterIndex2, setFilterIndex2] = useState(null);
    const [pageOne, setPageOne] = useState(true);
    const [editDescription, setEditDescription] = useState(false);
    const [noteArbeitnehmer, setNoteArbeitnehmer] = useState('');


    const checkIfMatchExists = async () => {
        let url = ENV.REACT_APP_API_URL + '/matchings?filter%5Bid%5D%5Beq%5D=' + query.get('id');
        let { response, data } = await api(url)



        if (data && data.data && data.data.length == 1) {
            setFilterJobAdvertisementId(data.data[0].attributes.job_advertisement_id);
            setFilterType(!!data.data[0].attributes.scoreGeneral);
            setFilterId(query.get('id'));
        }

    }

    useEffect(() => {
        if (filterJobAdvertisementId && filterType !== null && filterId) {
            setTimeout(() => {
                getData();
            }, 100)
        }
    }, [filterJobAdvertisementId, filterType, filterId])

    const testRef = useRef(null);
    const [questions, setQuestions] = useState([]);
    const [answeredQuestions, setAnsweredQuestions] = useState([]);
    const [buttonHover, setButtonHover] = useState(null);
    const [activeMatchItem, setActiveMatchItem] = useState(null);
    const [favorite, setFavorite] = useState(null);
    const loadingSpinnerRef = useRef(null)
    const { height, width } = useWindowDimensions();
    const scrollRef = useRef(null);
    const matchingJobtitleRef = useRef([]);
    const matchingGeneralRef = useRef([]);
    const [isChecked, setIsChecked] = useState([]);
    const [description, setDescription] = useState(null);

    const [onContact, setOnContact] = useState(false);
    const [onMatchkorb, setOnMatchkorb] = useState(false);
    const [confirmedMatches, setConfirmedMatches] = useState();
    const [currentRange, setCurrentRange] = useState(30);

    const [jobs, setJobs] = useState('');
    const [plz, setPlz] = useState('');
    const [test, setTest] = useState(false);
    const [rows, setRows] = useState([]);
    const [helperText, setHelperText] = useState(null);
    const [icon, setIcon] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMoreData, setHasMoreData] = useState({});
    const [windowPageYOffset, setWindowPageYOffset] = useState(null);
    const [isViewModeEdit, setisViewModeEdit] = useState(false);
    const [filterFavourite, setFilterFavourite] = useState(true);
    const [filterRead, setFilterRead] = useState(true);
    const [filterUnread, setFilterUnread] = useState(true);
    const [filterHidden, setFilterHidden] = useState(false);
    const [isLoading, setIsLoading] = useState(true)

    const onContactStyle = {
        position: 'absolute',
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '20%',
        minWidth: 250,
        maxHeight: '70%',
        overflow: 'auto',
        backgroundColor: 'rgba(0, 55, 78, 0.9)',
        borderRadius: 5,
        padding: '5px 32px 20px',
        boxShadow: 24,
        zIndex: 4,
        lineHeight: '13px',
    };

    const onMatchkorbStyle = {
        position: 'absolute',
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: '20%',
        minWidth: 200,
        maxHeight: '70%',
        overflow: 'auto',
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        borderRadius: 5,
        padding: '0 16px 10px',
        boxShadow: 24,
        zIndex: 4,
        lineHeight: '13px',
    };

    function currencyFormat(num) {
        if (num) {
            return num.toFixed(2).toString().replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        }
        return false;
    }

    const items = [
        {
            title: 'Produktmanager (m/w/d) in Vollzeit',
            content: [
                { text: 'Swiitcher 111', percent: 80, type: 'Student', timestamp: 'heute' },
                { text: 'Swiitcher 112', percent: 70, type: 'Arbeitnehmer', timestamp: 'vor 1 Tag' },
                { text: 'Swiitcher 113', percent: 60, type: 'Arbeitnehmer', timestamp: 'vor 5 Tag' }
            ]
        },
        {
            title: 'Job Nummer Zwei (m/w/d) in Teilzeit',
            content: [
                { text: 'Swiitcher 114', percent: 80, type: 'Arbeitnehmer', timestamp: 'heute' },
                { text: 'Swiitcher 115', percent: 70, type: 'Student', timestamp: 'vor 1 Tag' },
                { text: 'Swiitcher 116', percent: 60, type: 'Arbeitnehmer', timestamp: 'vor 5 Tag' }
            ]
        }, {
            title: 'Dritte Stellenanzeige (m/w/d) in Vollzeit',
            content: [
                { text: 'Swiitcher 117', percent: 80, type: 'Arbeitnehmer', timestamp: 'heute' },
                { text: 'Swiitcher 118', percent: 70, type: 'Arbeitnehmer', timestamp: 'vor 1 Tag' },
                { text: 'Swiitcher 119', percent: 60, type: 'Arbeitnehmer', timestamp: 'vor 5 Tag' }
            ]
        },
    ];

    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, items.length);
    }, [items]);


    useEffect(() => {
        // setTimeout(() => {
        //     if (rows && rows.length > 0) {
        //         getMatchingCounts(rows);
        //     }
        // }, 0)
    }, [rows])

    const getData = async () => {

        let isChecked = [];

        let url = ENV.REACT_APP_API_URL + '/arbeitgeber/1/job-advertisements';

        let { response, data } = await api(url)

        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/arbeitgeber');
            return;
        }

        let checked = false;
        if (data && data.data) {
            let rowsTemp = [];

            let t = [];
            await Promise.all(data.data.map(async (item, index) => {
                let temp = {};
                isChecked[index] = false;
                if (filterType == 1 && filterJobAdvertisementId && item.id == filterJobAdvertisementId) {
                    isChecked[index] = true;
                    checked = true;
                }
                temp = await getMatchings(item.id, isChecked[index], index, true, false, true)//item.jobAdvertisement.id, temp[index], index, false, false, true
                // console.log("PROMISE TEMP", temp);
                temp.jobAdvertisement = item;
                if (temp.jobAdvertisement && temp.jobAdvertisement.attributes.active == 1) {
                    rowsTemp.push(temp);
                }

                t[item.id] = [];
            }));
            setIsChecked(isChecked);

            let newRows = await getMatchingCounts(rowsTemp);

            // newRows.sort((a, b) => (b.jobAdvertisement.id > a.jobAdvertisement.id ? -1 : 1))

            if (filterJobAdvertisementId) {
                let index1 = null;
                let index2 = null;
                let test = newRows.filter(i => i.jobAdvertisement.id == filterJobAdvertisementId)[0];
                index1 = newRows.indexOf(test);
                setFilterIndex1(newRows.indexOf(test))
                let matchItem = null;
                if (checked === true && newRows[index1].matchingsScoreGeneral.length > 0 && newRows[index1].matchingsScoreGeneral.filter(i => i.id == filterId).length > 0) {
                    index2 = newRows[index1] ? newRows[index1].matchingsScoreGeneral.indexOf(newRows[index1].matchingsScoreGeneral.filter(i => i.id == filterId)[0]) : null;
                    matchItem = newRows[index1].matchingsScoreGeneral.filter(i => i.id == filterId)[0];
                    setActiveMatchItem(matchItem);
                    setFilterIndex2(index2);
                    matchItem.index1 = newRows.indexOf(test);
                    matchItem.index2 = index2;
                    window.history.replaceState("", document.title, window.location.pathname);


                } else if (checked === false && newRows[index1].matchingsScoreForJobtitle.length > 0 && newRows[index1].matchingsScoreForJobtitle.filter(i => i.id == filterId).length > 0) {
                    index2 = newRows[index1] ? newRows[index1].matchingsScoreForJobtitle.indexOf(newRows[index1].matchingsScoreForJobtitle.filter(i => i.id == filterId)[0]) : null;
                    matchItem = newRows[index1].matchingsScoreForJobtitle.filter(i => i.id == filterId)[0];
                    setActiveMatchItem(matchItem);
                    setFilterIndex2(index2);
                    matchItem.index1 = newRows.indexOf(test);
                    matchItem.index2 = index2;
                    window.history.replaceState("", document.title, window.location.pathname);
                }

                // if (index2 >= 0 && matchItem) {
                //     setFilterIndex2(index2);
                //     matchItem.index1 = newRows.indexOf(test);
                //     matchItem.index2 = index2;
                //     setTimeout(() => {
                //     }, 0)
                // }
            }


            setRows(newRows);
            matchingGeneralRef.current = t;
            matchingJobtitleRef.current = t;
        } else if (response.error) {
        }
    }

    const getMatchingCounts = async (rowsTemp) => {

        const types = ['left', 'right'];
        let toggles = document.getElementsByClassName('toggle-switch-inner')

        let newRows = rowsTemp;

        for (let item of newRows) {
            const index = newRows.indexOf(item);
            for (let type of types) {
                let url = ENV.REACT_APP_API_URL + '/matchings?filter%5Bid%5D%5Beq%5D=' + item.jobAdvertisement.id + '&page%5Bsize%5D=10&filter%5Btype%5D%5Beq%5D=' + type + '&hidden=' + filterHidden + '&favourite=' + filterFavourite + '&unread=' + filterUnread + '&read=' + filterRead;
                // if (filterTarget == 'hidden') {
                //     url += '&hidden=true'
                // } else if (filterTarget == 'favourite') {
                //     url += '&favourite=true'
                // } else if (filterTarget == 'unread') {
                //     url += '&unread=true'
                // } else if (filterTarget == 'read') {
                //     url += '&unread=false'
                // }
                let { response, data } = await api(url)
                if (data && data.meta && data.meta.pagination.total) {
                    newRows[index][type] = data.meta.pagination.total;

                } else {
                    newRows[index][type] = 0;

                }

                // let count = -1
                //
                // for (const match in data.data) {
                //     count += 1
                // }
                // newRows[index][type] = count;
                // let beforeElStyles = window.getComputedStyle(toggles[index],':before')
                // let beforeEl = beforeElStyles['content'];
                // beforeEl = "test";
                // toggles[index].innerHtml = '<p>!' + toggles[index].getAttribute('data-yes') + '!</p>'


            }
        }
        setIsLoading(false)
        return newRows;
    }

    const saveFavourite = async (item, bool) => {
        let url = ENV.REACT_APP_API_URL + '/matching/' + item.id;

        let { response, data } = await api(url, 'PATCH', JSON.stringify({
            data: {
                attributes: {
                    matchingArbeitgeberFavourite: bool
                }
            }
        }))

        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/arbeitgeber');
            return;
        }

    }
    const saveHidden = async (item, bool) => {
        let url = ENV.REACT_APP_API_URL + '/matching/' + item.id;

        let { response, data } = await api(url, 'PATCH', JSON.stringify({
            data: {
                attributes: {
                    matchingArbeitgeberHidden: bool
                }
            }
        }))

        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/arbeitgeber');
            return;
        }

    }
    const getMatchings = async (id, checked, index, init, hasMore = true, resetActiveMatchitem = false) => {

        let tempActiveMatchItem = resetActiveMatchitem === false ? activeMatchItem : null;

        let pageYOffset = window.pageYOffset;
        let tempPageNumber = 1;
        let temp = {};
        let t = [];
        let tt = [];

        let moreData = true;
        let matchingCount = 0;
        console.log("HASMOREDATA", hasMoreData, hasMore)
        if (hasMoreData[id] === true && hasMore) {
            let pageN = pageNumber;
            let temp = ++pageN;
            tempPageNumber = temp;
            setPageNumber(temp);
        }

        let filterItem = null;
        let pageSize = 10;

        if ((tempPageNumber > 0 || init) && moreData) {

            let type = checked ? 'right' : 'left';


            if (filterJobAdvertisementId == id) {
                pageSize = 9999;
            }

            for (let i = 1; i < tempPageNumber + 1; i++) {
                if (filterItem) break;
                let url = ENV.REACT_APP_API_URL + '/matchings?filter%5Bid%5D%5Beq%5D=' + id + '&page%5Bsize%5D=' + pageSize + '&page%5Bnumber%5D=' + i + '&filter%5Btype%5D%5Beq%5D=' + type + '&hidden=' + filterHidden + '&favourite=' + filterFavourite + '&unread=' + filterUnread + '&read=' + filterRead + '&isActiveAdvert=false';
                // alert(filterTarget)
                // if (filterTarget == 'hidden') {
                //     url += '&hidden=true'
                // } else if (filterTarget == 'favourite') {
                //     url += '&favourite=true'
                // } else if (filterTarget == 'unread') {
                //     url += '&unread=true'
                // } else if (filterTarget == 'read') {
                //     url += '&unread=false'
                // }
                let { response, data } = await api(url)

                if (!response || response.status == 401) {
                    ReactSession.set('token', undefined);
                    ReactSession.set('refreshToken', undefined);
                    history.push('/arbeitgeber');
                    return;
                }


                let dat = data.data;

                dat.map((item, index) => {
                    let status = '';
                    if (item.attributes.matchingArbeitgeber && item.attributes.matchingArbeitnehmer) {
                        status = 'bestätigt';
                    } else if (item.attributes.declineArbeitnehmer) {
                        status = 'abgelehnt...';
                    } else if (item.attributes.matchingArbeitgeber) {
                        status = 'angefragt...';
                    }
                    item.status = status;
                })

                dat.map((item, index) => {
                    if (!t.includes(item)) {
                        if (filterJobAdvertisementId == id && filterId && item.id == filterId) {
                            filterItem = item;
                        }
                        t.push(item);
                    }
                })

                let tempMatches = dat.filter(i => i.attributes.matchingArbeitnehmer && i.attributes.matchingArbeitgeber && !i.attributes.isBought)


                // let tempConfirmedMatches = [...confirmedMatches];
                //
                // tempMatches.map((item, index) => {
                //     if (!tempConfirmedMatches.includes(item)) {
                //         tempConfirmedMatches.push(item);
                //     }
                // })
                //
                // setConfirmedMatches(tempConfirmedMatches);


                if (data.meta.pagination.total === t.length) {
                    moreData = false;
                } else {
                }
            }

            tt = t;
            temp.matchingsScoreForJobtitle = [];
            if (!checked) {
                temp.matchingsScoreForJobtitle.push(...tt.filter(i => i.status == 'bestätigt').sort((a, b) => (a.attributes.scoreForJobtitle > b.attributes.scoreForJobtitle ? -1 : 1)))
                temp.matchingsScoreForJobtitle.push(...tt.filter(i => i.status == 'angefragt...').sort((a, b) => (a.attributes.scoreForJobtitle > b.attributes.scoreForJobtitle ? -1 : 1)))
                temp.matchingsScoreForJobtitle.push(...tt.filter(i => i.status == 'abgelehnt...').sort((a, b) => (a.attributes.declineArbeitnehmer ? -1 : 1)))
                temp.matchingsScoreForJobtitle.push(...tt.filter(i => (i.status == '')).sort((a, b) => (a.attributes.scoreForJobtitle > b.attributes.scoreForJobtitle ? -1 : 1)));
            }

            tt = t;
            temp.matchingsScoreGeneral = [];
            if (checked) {
                temp.matchingsScoreGeneral.push(...tt.filter(i => i.status == 'bestätigt').sort((a, b) => (a.attributes.scoreGeneral > b.attributes.scoreGeneral ? -1 : 1)))
                temp.matchingsScoreGeneral.push(...tt.filter(i => i.status == 'angefragt...').sort((a, b) => (a.attributes.scoreGeneral > b.attributes.scoreGeneral ? -1 : 1)))
                temp.matchingsScoreGeneral.push(...tt.filter(i => i.status == 'abgelehnt...').sort((a, b) => (a.attributes.declineArbeitnehmer ? -1 : 1)))
                temp.matchingsScoreGeneral.push(...tt.filter(i => (i.status == '')).sort((a, b) => (a.attributes.scoreGeneral > b.attributes.scoreGeneral ? -1 : 1)));
            }

            let x = [];

            temp.matchingsScoreForJobtitle.map((item, index) => {
                if (x.filter(i => i.id == item.id).length == 0) x.push(item)
            })

            temp.matchingsScoreForJobtitle = x;

            let y = [];

            temp.matchingsScoreGeneral.map((item, index) => {
                if (y.filter(i => i.id == item.id).length == 0) y.push(item)
            })

            temp.matchingsScoreGeneral = y;


            let te = hasMoreData;
            te[id] = moreData;
            setHasMoreData(te);

            if (rows.length) {
                let tempRows = [...rows];
                // console.log("comp2", t[index].matchingsScoreGeneral, temp.matchingsScoreGeneral);
                if(tempRows[index]) {
                    tempRows[index].matchingsScoreForJobtitle.splice(0);
                    tempRows[index].matchingsScoreForJobtitle = temp.matchingsScoreForJobtitle;

                    tempRows[index].matchingsScoreGeneral.splice(0);
                    tempRows[index].matchingsScoreGeneral = temp.matchingsScoreGeneral;
                }


                const returnValue = tempRows[index]

                // tempRows.sort((a, b) => (b.jobAdvertisement.id > a.jobAdvertisement.id ? -1 : 1))
                setRows(tempRows);
                setActiveMatchItem(tempActiveMatchItem)
                // setTimeout(() => {
                //     window.scrollTo({ top: pageYOffset });
                // }, 3000)
                return returnValue
            } else {
                let index2 = null;
                return temp;
            }
        }
    }

    const updateMatch = async (item, descriptionOnly = false) => {
        let url = ENV.REACT_APP_API_URL + '/matching/' + item.id;


        const { response, data } = await api(url, 'PATCH', JSON.stringify({
            data: {
                attributes: {
                    matchingArbeitgeber: 1,
                    matchingArbeitgeberUnread: 0,
                    noteArbeitnehmer: noteArbeitnehmer
                }
            }
        }));

        if (response && response.status == 200) {


            let t = rows.filter(i => i.jobAdvertisement.id == item.attributes.job_advertisement_id)[0];
            let index = rows.indexOf(t);

            getMatchings(item.attributes.job_advertisement_id, isChecked[index], index, false)
            //getData();
            setNoteArbeitnehmer(null);
            setPageOne(true);
            setEditDescription(false);
            setOnContact(false);
            if (!descriptionOnly) notify('Deine Anfrage wurde erfolgreich versendet');
            else notify('Deine Nachricht wurde erfolgreich aktualisiert');
        } else {
            notify('API Fehler: ' + response.status, true);
        }
    }

    const getAnsweredQuestions = async () => {
        let answeredQuestionsTemp = [];
        let temp = '';
        let items = [];

        if (ReactSession.get('user') == 'Arbeitgeber') {
            let url = ENV.REACT_APP_API_URL + '/answered-questions/for/matching';


            let postData = {
                userId: activeMatchItem.attributes.arbeitnehmer_id,
                jobAdvertisementId: activeMatchItem.attributes.job_advertisement_id
            }


            let tempData = await api(url, 'POST', JSON.stringify(postData))
            let response = tempData.response;
            let data = tempData.data;

            if (!response || response.status == 401) {
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
            }
            if (data && data.data) {
                data.data.map((item, index) => {
                    answeredQuestionsTemp.push(item);
                });
                setDescription(data.description);

            } else if (response.error) {
                notify('API Fehler: ' + response.status, true);
            }
        }

        answeredQuestionsTemp.sort((a, b) => (a.question_id == 1) ? -1 : 1);

        let answersAN2 = [];
        let rightAnswers2 = [];
        let answersAN3 = [];
        let rightAnswers3 = [];
        answeredQuestionsTemp.filter(i => i.question_id == 2).map((item, index) => {
            item.answers.rightAnswers.map((item, index) => {
                if (item != 'Nein' && !rightAnswers2.includes(item) && !rightAnswers3.includes(item)) rightAnswers2.push(item);
            })
            item.answers.answerAN.map((item, index) => {
                if (item != 'Nein' && !answersAN2.includes(item) && !answersAN3.includes(item)) answersAN2.push(item);
            })

        })

        answeredQuestionsTemp.filter(i => i.question_id != 2 && (i.question_id == 3)).map((item, index) => {
            item.answers.rightAnswers.map((item, index) => {
                if (item != 'Nein' && !rightAnswers2.includes(item) && !rightAnswers3.includes(item)) rightAnswers3.push(item);
            })
            item.answers.answerAN.map((item, index) => {
                if (item != 'Nein' && !answersAN2.includes(item) && !answersAN3.includes(item)) answersAN3.push(item == 'Ich bin derzeit nicht berufstätig' ? 'derzeit nicht berufstätig' : item);
            })
        })

        // answersAN2.push(...answersAN3);
        // rightAnswers2.push(...rightAnswers3);
        let newItem = {
            text: 'Aktueller Status:',
            answersAN: answersAN2,
            answersAN3: answersAN3,
            rightAnswers: rightAnswers2,
            rightAnswers3: rightAnswers3,
            question_id: 2
        };

        items.push(newItem);

        let answersAN = [];
        let rightAnswers = [];

        answeredQuestionsTemp.filter(i => i.question_id == 36 || i.question_id == 48).map((item, index) => {
            item.answers.rightAnswers.map((item, index) => {
                if (item != 'Nein' && !rightAnswers.includes(item) && !rightAnswers.includes(item)) rightAnswers.push(item);
            })
            item.answers.answerAN.map((item, index) => {
                if (item != 'Nein' && !answersAN.includes(item) && !answersAN.includes(item)) answersAN.push(item);
            })
        })

        newItem = {};
        newItem = {
            text: 'Konkreter Berufswunsch:',
            answersAN,
            rightAnswers,
            question_id: 36
        };

        items.push(newItem);

        answersAN = [];
        newItem = {
            text: 'Branche / Bereich:',
            answersAN,
            question_id: 3
        };

        answeredQuestionsTemp.filter(i => i.question_id == 3).map((item, index) => {
            answersAN = item.answers.subJobType;
        })
        newItem.answersAN = answersAN;
        if (answersAN && answersAN.length > 0) items.push(newItem);

        newItem = {};
        answersAN = [];
        rightAnswers = [];
        let helperText = null;
        let icon = null;


        answeredQuestionsTemp.filter(i => i.question_id == 61).map((item, index) => {
            newItem = {};
            newItem.text = 'Beruf / Berufsfeld';
            newItem.question_id = item.question_id;
            answersAN = [];
            rightAnswers = [];

            item.answers.rightAnswers.map((item, index) => {
                rightAnswers.push(item);
            })
            item.answers.answerAN.map((item, index) => {
                answersAN.push(item);
            })

            if (helperText && icon) {
                setHelperText(helperText);
                setIcon(icon);
            }

            newItem.rightAnswers = rightAnswers;
            newItem.answersAN = answersAN;
            items.push(newItem);
        })

        answeredQuestionsTemp.filter(i => i.question_id == 11).map((item, index) => {
            newItem = {};
            newItem.text = 'Beschäftigungsgrad:';
            newItem.question_id = item.question_id;
            answersAN = [];
            rightAnswers = [];

            item.answers.rightAnswers.map((item, index) => {
                rightAnswers.push(item);
            })
            item.answers.answerAN.map((item, index) => {
                answersAN.push(item);
            })

            if (helperText && icon) {
                setHelperText(helperText);
                setIcon(icon);
            }

            newItem.rightAnswers = rightAnswers;
            newItem.answersAN = answersAN;
            items.push(newItem);
        })


        answeredQuestionsTemp.filter(i => i.question_id != 2 && i.question_id != 3 && i.question_id != 11 && i.question_id != 36 && i.question_id != 48).map((item, index) => {
            newItem = {};
            newItem.text = item.question;
            newItem.question_id = item.question_id;
            newItem.helperText = item.helperText;
            answersAN = [];
            rightAnswers = [];

            item.answers.rightAnswers.map((item, index) => {
                rightAnswers.push(item);
            })
            item.answers.answerAN.map((item, index) => {
                answersAN.push(item);
                switch (item) {
                    case 'Denker':
                        helperText = 'Er/Sie hat viel Fantasie und kombiniert seinen/ihren enormen Wissensdurst mit ausgefeilter strategischer Planung. Intellektuelle Herausforderungen lassen sein/ihr Licht heller strahlen und er/sie findet immer einen Weg zum Ziel.';
                        icon = item;
                        break;
                    case 'Entdecker':
                        helperText = 'Ausprobieren, neu kombinieren und in unbekannte Welten vordringen: Mit ihm/ihr an der Seite werden überraschende Entdeckungen möglich. Er/Sie hat innovative Ideen und spannende Visionen und begeistert die Menschen um sich mit Forscherdrang und seinem/ihrem verspieltem Charme.';
                        icon = item;
                        break;
                    case 'Helfer':
                        helperText = 'Auf ihn/sie ist einfach immer Verlass, denn er/sie kümmert sich jederzeit um andere und hilft, wo er/sie kann. Dabei ist er/sie praktisch veranlagt und organisiert problemlos das alltägliche Chaos um sich herum.';
                        icon = item;
                        break;
                    case 'Diplomat':
                        helperText = 'Er/Sie glaubt fest an das Gute und verbreitet Zuversicht auf Schritt und Tritt. Mit seinem/ihrem freien und kreativen Geist inspiriert er/sie die Menschen um sich herum zu kühnen Taten. Er/Sie steht zu seinen/ihren Idealen und sprüht vor positiver Energie.';
                        icon = item;
                        break;
                }
            })

            if (helperText && icon) {
                setHelperText(helperText);
                setIcon(icon);
            }

            newItem.rightAnswers = rightAnswers;
            newItem.answersAN = answersAN;
            items.push(newItem);
        })
        setTimeout(() => {
            setQuestions(items);
        }, 500)
    }

    const notify = (message, error) => {
        // console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: { marginTop: 30 }
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: { marginTop: 30 }
            });
        }
    }

    useEffect(() => {
        getData();
    }, [filterUnread, filterRead, filterFavourite, filterHidden]);

    useEffect(() => {
        if (activeMatchItem) {
            setQuestions([]);
            if (scrollRef.current !== activeMatchItem && (matchingGeneralRef.current[activeMatchItem.attributes.job_advertisement_id][activeMatchItem.index2] || matchingJobtitleRef.current[activeMatchItem.attributes.job_advertisement_id][activeMatchItem.index2])) {
                scrollRef.current = activeMatchItem;

                let element = null;
                if (isChecked[activeMatchItem.index]) {
                    element = matchingGeneralRef.current[activeMatchItem.attributes.job_advertisement_id][activeMatchItem.index2];
                } else {
                    element = matchingJobtitleRef.current[activeMatchItem.attributes.job_advertisement_id][activeMatchItem.index2];
                }

                const yOffset = -75;
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });


                if (filterJobAdvertisementId) {
                    setFilterJobAdvertisementId(null);
                    setFilterType(null);
                    setFilterId(null);
                    setFilterIndex1(null);
                    setFilterIndex2(null);
                }
            }

            // setUnread();
            getAnsweredQuestions();
        } else {
            setQuestions([]);
            scrollRef.current = null;
            setAnsweredQuestions(null);
            setHelperText(null);
            setIcon(null);
        }
    }, [activeMatchItem])

    const setUnread = async () => {
        if (activeMatchItem && activeMatchItem.attributes.matchingArbeitgeberUnread) {
            let url = ENV.REACT_APP_API_URL + '/matching/' + activeMatchItem.id;


            const { response, data } = await api(url, 'PATCH', JSON.stringify({
                data: {
                    attributes: {
                        matchingArbeitgeberUnread: 0
                    }
                }
            }));

            if (response && response.status == 200) {


                if (activeMatchItem) {
                    let t = [...rows];

                    if (t[activeMatchItem.index].matchingsScoreGeneral[activeMatchItem.index2]) t[activeMatchItem.index].matchingsScoreGeneral[activeMatchItem.index2].attributes.matchingArbeitgeberUnread = 0;
                    if (t[activeMatchItem.index].matchingsScoreForJobtitle[activeMatchItem.index2]) t[activeMatchItem.index].matchingsScoreForJobtitle[activeMatchItem.index2].attributes.matchingArbeitgeberUnread = 0;
                    setRows(t);
                }


                //getData();
            } else {
                notify('API Fehler: ' + response.status, true);
            }
        }
    }
    let führerscheinLogos = {
        B: LogoSvgB,
        C: LogoSvgC,
        D: LogoSvgD,
        L: LogoSvgL,
        FL: LogoSvgFL
    };

    const [anchorElFilter, setAnchorElFilter] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handlePopoverOpenFilter = (event) => {
        setAnchorElFilter(event.currentTarget);
    };

    const handlePopoverCloseFilter = () => {
        setAnchorElFilter(null);
    };

    const open = Boolean(anchorEl);
    const openFilter = Boolean(anchorElFilter);


    return (
        <Grid container style={{
            padding: 0,
        }}>
            <Grid id={'top-container'} item xs={12} style={{
                display: 'block',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 64,
                marginBottom: activeMatchItem !== null ? 50 : 0,
                padding: '30px 50px 50px',
                width: '100%',
                minHeight: '800px',
            }}>
                <Grid container>
                    <Grid item xs={2}>
                        <p className={'headerName'} style={{ position: 'relative' }}>
                            Matches
                        </p>
                    </Grid>
                    <Grid item xs={10} style={{ textAlign: 'right' }}>
                        {/*<Button*/}
                        {/*    className={'normal-button'}*/}
                        {/*    style={{*/}
                        {/*        marginRight: 15,*/}
                        {/*        height: 40,*/}
                        {/*        fontSize: 20,*/}
                        {/*        padding: '15px 5px 15px 15px',*/}
                        {/*        marginLeft: 5,*/}
                        {/*        transition: 'all 0.05s ease-in-out',*/}
                        {/*        background: '#00374E',*/}
                        {/*        position: 'relative',*/}
                        {/*        textTransform: 'none',*/}
                        {/*        borderRadius: 25*/}
                        {/*    }}*/}
                        {/*    onClick={handlePopoverOpenFilter}*/}
                        {/*>*/}
                        {/*    <div>*/}
                        {/*        <p style={{ color: 'white', fontSize: 18, margin: 0, lineHeight: 1 }}>Filter</p>*/}
                        {/*        {(!filterFavourite || !filterUnread || !filterRead || filterHidden) ? <p style={{*/}
                        {/*            color: '#c8d400',*/}
                        {/*            fontSize: 12,*/}
                        {/*            margin: 0,*/}
                        {/*            lineHeight: 1,*/}
                        {/*            textAlign: 'left'*/}
                        {/*        }}>aktiv</p> : <></>}*/}
                        {/*    </div>*/}
                        {/*    <KeyboardArrowDownOutlinedIcon*/}
                        {/*        style={{*/}
                        {/*            paddingLeft: 5,*/}
                        {/*            height: 30,*/}
                        {/*            width: 30,*/}
                        {/*            transition: 'all 0.2s ease-in-out',*/}
                        {/*            textAlign: 'center',*/}
                        {/*            cursor: 'pointer',*/}
                        {/*            fill: 'white',*/}
                        {/*            borderRadius: ENV.REACT_APP_BORDER_RADIUS,*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</Button>*/}
                        <Popover
                            id="mouse-over-popover-filter"
                            open={openFilter}
                            anchorEl={anchorElFilter}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClose={handlePopoverCloseFilter}
                        >
                            <div style={{ padding: 10, fontSize: 14, position: 'relative' }}>
                                <div style={{ position: 'absolute', top: 10, right: 10 }}>
                                    <CloseOutlinedIcon
                                        style={{ cursor: 'pointer', height: 20 }}
                                        onClick={handlePopoverCloseFilter}/>
                                </div>
                                <FormControl style={{ paddingLeft: 20, marginRight: 20 }}>
                                    <FormLabel id="demo-radio-buttons-group-label" style={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                        paddingBottom: 10,
                                        textAlign: 'left'
                                    }}>Status</FormLabel>
                                    {/*<RadioGroup*/}
                                    {/*    aria-labelledby="demo-radio-buttons-group-label"*/}
                                    {/*    value={filter}*/}
                                    {/*    onChange={handleRadioChange}*/}
                                    {/*    name="radio-buttons-group"*/}
                                    {/*>*/}
                                    {/*    <div style={{ paddingBottom: 5 }}>*/}
                                    {/*        <FormControlLabel value="" control={<Radio sx={{*/}
                                    {/*            marginRight: 1,*/}
                                    {/*            color: 'white',*/}
                                    {/*            '&.Mui-checked': {*/}
                                    {/*                color: '#c8d400',*/}
                                    {/*            },*/}
                                    {/*        }}/>} label="Kein Filter"/>*/}
                                    {/*    </div>*/}
                                    {/*    <div style={{ height: 1, width: '100%', marginTop: 5, marginBottom: 10 }}/>*/}
                                        <div style={{ paddingBottom: 5 }}>
                                            <FormControlLabel value="favourite" control={<Radio checked={filterFavourite} onClick={(e) => {
                                                setFilterFavourite(!filterFavourite)
                                            }} sx={{
                                                marginRight: 1,
                                                color: 'white',
                                                '&.Mui-checked': {
                                                    color: '#c8d400',
                                                },
                                            }}/>} label="Favorisiert"/>
                                        </div>
                                        <div style={{ paddingBottom: 5 }}>
                                            <FormControlLabel value="unread" control={<Radio checked={filterUnread} onClick={(e) => {
                                                setFilterUnread(!filterUnread)
                                            }} sx={{
                                                marginRight: 1,
                                                color: 'white',
                                                '&.Mui-checked': {
                                                    color: '#c8d400',
                                                },
                                            }}/>} label="Nicht angesehen"/>
                                        </div>
                                        <div style={{ paddingBottom: 5 }}>
                                            <FormControlLabel value="read" control={<Radio checked={filterRead} onClick={(e) => {
                                                setFilterRead(!filterRead)
                                            }} sx={{
                                                marginRight: 1,
                                                color: 'white',
                                                '&.Mui-checked': {
                                                    color: '#c8d400',
                                                },
                                            }}/>} label="Angesehen"/>
                                        </div>
                                        <div style={{ height: 1, width: '100%', marginTop: 5, marginBottom: 10 }}/>
                                        <div style={{ paddingBottom: 5 }}>
                                            <FormControlLabel value="hidden" control={<Radio checked={filterHidden} onClick={(e) => {
                                                setFilterHidden(!filterHidden)
                                            }} sx={{
                                                marginRight: 1,
                                                color: 'white',
                                                '&.Mui-checked': {
                                                    color: '#c8d400',
                                                },
                                            }}/>} label="Ausgeblendet"/>
                                        </div>
                                    {/*</RadioGroup>*/}
                                </FormControl>
                            </div>

                        </Popover>
                        {/*<IconButton*/}
                        {/*    onMouseEnter={handlePopoverOpen}*/}
                        {/*    onMouseLeave={handlePopoverClose}*/}
                        {/*    className={'icon-button'}*/}
                        {/*    style={{*/}
                        {/*        height: 40,*/}
                        {/*        width: 40,*/}
                        {/*        fontSize: 20,*/}
                        {/*        padding: 15,*/}
                        {/*        marginLeft: 5,*/}
                        {/*        transition: 'all 0.05s ease-in-out',*/}
                        {/*        background: '#00374E',*/}
                        {/*        position: 'relative'*/}
                        {/*    }}*/}
                        {/*    onClick={() => {*/}
                        {/*        setisViewModeEdit(!isViewModeEdit)*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {(isViewModeEdit) ? <VisibilityOffOutlinedIcon*/}
                        {/*        style={{*/}
                        {/*            position: 'absolute',*/}
                        {/*            height: 30,*/}
                        {/*            width: 30,*/}
                        {/*            transition: 'all 0.2s ease-in-out',*/}
                        {/*            textAlign: 'center',*/}
                        {/*            cursor: 'pointer',*/}
                        {/*            fill: 'white',*/}
                        {/*            borderRadius: ENV.REACT_APP_BORDER_RADIUS,*/}
                        {/*        }}*/}
                        {/*    /> : <VisibilityOutlinedIcon*/}
                        {/*        style={{*/}
                        {/*            position: 'absolute',*/}
                        {/*            height: 30,*/}
                        {/*            width: 30,*/}
                        {/*            transition: 'all 0.2s ease-in-out',*/}
                        {/*            textAlign: 'center',*/}
                        {/*            cursor: 'pointer',*/}
                        {/*            fill: 'white',*/}
                        {/*            borderRadius: ENV.REACT_APP_BORDER_RADIUS,*/}
                        {/*        }}*/}
                        {/*    />}*/}
                        {/*</IconButton>*/}
                        <Popover
                            id="mouse-over-popover"
                            sx={{
                                pointerEvents: 'none',
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <div style={{ padding: 10, maxWidth: 230, fontSize: 14 }}>
                                <h3 style={{ textAlign: 'center', }}>Modus: Aus- und Einblenden</h3>
                                <p style={{ textAlign: 'center', }}>In diesem Modus können Matches ein- und ausgeblendet
                                    werden.</p>
                                <p style={{ textAlign: 'center', }}>Unter "Filter" > "Status" oder in diesem Modus hast
                                    du jederzeit Zugriff auf diese Matches</p>
                            </div>

                        </Popover>
                    </Grid>
                </Grid>

                <Grid container style={{ margin: '20px 0 0 0' }}>
                    <Grid item xs={5} style={{ paddingLeft: 0, paddingRight: 10 }}>

                    </Grid>


                    <Grid item xs={6} style={{ paddingRight: 0 }}>

                    </Grid>
                    <Grid item xs={1} style={{ textAlign: 'right' }}>
                        {(confirmedMatchesCount && confirmedMatchesCount > 0) ? <CustomButton
                            id={'einkaufswagen'}
                            buttontype={'gray'}
                            style={{
                                position: 'relative',
                                borderRadius: 50,
                                minWidth: 'unset',
                                padding: 0
                            }}
                            onClick={() => {
                                if (confirmedMatchesCount && confirmedMatchesCount > 0) history.push('/matchkorb')
                            }}
                        >
                            <Einkaufswagen
                                style={{
                                    width: 40,
                                    color: 'white',
                                    cursor: 'pointer',
                                }}
                            />
                            {(confirmedMatchesCount && confirmedMatchesCount > 0) && <div className={'count'} style={{
                                background: 'white',
                                filter: 'unset',
                                width: 6,
                                minWidth: 6,
                                height: 6,
                                fontSize: 10,
                                top: -2,
                                right: -7,
                                color: '#C7D300',
                                padding: '4px',
                                fontFamily: 'VagRoundedBold'
                            }}>{confirmedMatchesCount}
                            </div>}
                        </CustomButton> : null}

                    </Grid>
                </Grid>

                {(!rows || !rows.length) && <div>
                    {(isLoading) ? <div ref={loadingSpinnerRef}
                                        style={{
                                            //display: 'flex',
                                            //justifyContent: 'center',
                                            //alignItems: 'center',
                                            color: 'white',
                                            paddingTop: 50,
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            paddingBottom: 100,
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexDirection: 'column'
                                        }}>
                        <div id={'testLogo'} style={{
                            borderRadius: 50,
                            width: 60,
                            height: 60,
                            minWidth: 60,
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            border: '4px solid transparent',
                            // cursor: 'pointer',
                            transitionDuration: '0.2s',
                            transitionProperty: 'transform',
                            margin: '0 auto'
                        }}
                        >
                            <div style={{
                                padding: 7.5,
                                background: 'rgba(0, 55, 78, 1)',
                                borderRadius: 50,
                                width: 57,
                                minWidth: 27,
                                height: 35,
                                minHeight: 35,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                border: '5px solid rgba(199, 211, 0, 1)'
                            }}>
                                <TestLogo
                                    style={{
                                        width: 30,
                                    }}
                                />
                            </div>
                        </div>
                    </div> : <p>Keine Matches vorhanden</p>}
                </div>}

                <div>
                    {rows.map((item, index) => {
                        if (item.jobAdvertisement.attributes.isActiveAdvert && !item.jobAdvertisement.attributes.draft) {
                            let labelLeft = <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                <div className={'count2'}>{item.left ?? 0}</div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}>
                                    <div style={{
                                        paddingLeft: 5,
                                        paddingTop: isChecked[index] ? 2 : 1.5,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '250px'
                                    }}>{item.jobAdvertisement.attributes.title} </div>
                                    <div style={{
                                        paddingTop: isChecked[index] ? 2 : 1.5,
                                        paddingRight: 15,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '165px'
                                    }} >{item.jobAdvertisement.attributes.subTitle}</div>
                                </div>
                                 </div>
                            let labelRight = <div
                                style={{ float: 'right', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <span className={'count2'} style={{ lineHeight: '12px' }}>{item.right ?? 0}</span>
                                <div style={{ display: 'inline-block', paddingLeft: 5, paddingTop: 1 }}>alternativ geeignete
                                    Mitarbeiter*innen
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <p className={'color-blue'}
                                                   style={{ textAlign: 'center', fontSize: 14 }}>Querdenken!</p>
                                                <p className={'color-blue'}
                                                   style={{ textAlign: 'center', fontSize: 12 }}>Hier zeigen wir dir auch
                                                    branchenübergreifend potenzielle Mitarbeiter*innen, die sich für diesen
                                                    Job eignen.</p>
                                            </React.Fragment>
                                        }>
                                        <InfoIcon style={{ marginLeft: 4 }}
                                                  fontSize={'14px'}
                                                  id={'infoIcon'}/>
                                    </HtmlTooltip></div>
                            </div>

                            return (
                                <div key={index}>
                                    <div>
                                        <div style={{
                                            position: 'relative',
                                            marginTop: 2,
                                            paddingTop: 2,
                                            marginBottom: 10,
                                            // background: '#ddd',
                                            // border: '2px solid #C7D300',
                                            borderRadius: 100,
                                            padding: 10,
                                            paddingRight: 60,
                                        }}>
                                            <ToggleSwitchMatchesArbeitgeber optionLabels={[labelLeft, labelRight]}
                                                                            id={'isChecked' + index}
                                                                            checked={isChecked[index]}
                                                                            onChange={() => {
                                                                                let temp = [...isChecked];
                                                                                temp[index] = !temp[index];
                                                                                scrollRef.current = null;
                                                                                setActiveMatchItem(false);
                                                                                setEditDescription(false);
                                                                                setNoteArbeitnehmer('');
                                                                                setIsChecked(temp);
                                                                                setPageNumber(1);
                                                                                // console.log("GET MATCHING TEMP", temp, index)
                                                                                getMatchings(item.jobAdvertisement.id, temp[index], index, false, false, true);


                                                                                if (activeMatchItem && (item.matchingsScoreGeneral.includes(activeMatchItem) || item.matchingsScoreForJobtitle.includes(activeMatchItem))) {
                                                                                    setActiveMatchItem(false);
                                                                                }


                                                                            }}/>
                                        </div>
                                    </div>

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        // maxHeight: '66vh'
                                    }}>
                                        <div style={{
                                            display: 'block',
                                            maxHeight: !isChecked[index] ? 'unset' : 100,
                                            transform: !isChecked[index] ? 'translate(0)' : 'translateX(-130%)',
                                            flex: '0 0 100%'
                                        }}>
                                            <div style={{ marginBottom: 30 }}>
                                                {/*<InfiniteScroll*/}
                                                {/*    dataLength={item.matchingsScoreForJobtitle ? item.matchingsScoreForJobtitle.length : 0} //This is important field to render the next data*/}
                                                {/*    next={() => {*/}
                                                {/*        getMatchings(item.jobAdvertisement.id, false, index, false)*/}
                                                {/*    }}*/}
                                                {/*    hasMore={hasMoreData[item.jobAdvertisement.id] ?? false}*/}
                                                {/*    loader={<h4>Matches werden geladen...</h4>}*/}
                                                {/*    endMessage={*/}
                                                {/*        <p style={{textAlign: 'center'}}>*/}
                                                {/*        </p>*/}
                                                {/*    }*/}
                                                {/*    style={{overflow: 'unset', width: '100%'}}*/}
                                                {/*>*/}
                                                {item.matchingsScoreForJobtitle.map((item2, index2) => {
                                                    return (
                                                        <div
                                                            ref={el => matchingJobtitleRef.current[item.jobAdvertisement.id] ? matchingJobtitleRef.current[item.jobAdvertisement.id][parseInt(index2)] = el : false}
                                                            key={index + '' + index2}
                                                            style={{ position: 'relative', height: (activeMatchItem && activeMatchItem.id == item2.id) ? '100%' : '52px', width: '100%' }}>
                                                            {
                                                                (item2.attributes.matchingArbeitgeberHidden) ?
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        background: 'white',
                                                                        height: (activeMatchItem && activeMatchItem.id == item2.id) ? '100%' : '52px',
                                                                        width: 'calc(100% - 50px)',
                                                                        zIndex: '500',
                                                                        opacity: '0.4'
                                                                    }}></div> : <></>
                                                            }
                                                            {
                                                                (isViewModeEdit) ? <div style={{
                                                                    width: 50,
                                                                    height: '100%',
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    right: 0,
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                }}>
                                                                    <Switch
                                                                        checked={!item2.attributes.matchingArbeitgeberHidden}
                                                                        onChange={(e) => {
                                                                            item2.attributes.matchingArbeitgeberHidden = !e.target.checked
                                                                            saveHidden(item2, !e.target.checked)
                                                                        }}
                                                                    />

                                                                </div> : <></>
                                                            }

                                                            <div style={{
                                                                position: 'relative',
                                                                height: 25,
                                                                marginTop: 2,
                                                                paddingTop: 2,
                                                                background: item2.attributes.matchingArbeitgeberUnread == 0 ? '#F2F2F2' : '#C7D300',
                                                                border: item2.attributes.matchingArbeitgeberUnread == 0 ? '2px solid #F2F2F2' : '2px solid #C7D300',
                                                                borderRadius: 100,
                                                                padding: 10,
                                                                color: 'white',
                                                                marginRight: 50,
                                                                zIndex: 2
                                                            }}>
                                                                <Grid container>
                                                                    <Grid item xs={1}>
                                                                        <p style={{
                                                                            textAlign: 'left',
                                                                            fontFamily: 'VagRoundedBold',
                                                                            fontSize: 16,
                                                                            margin: '2px 0 0',
                                                                            paddingLeft: 10
                                                                        }} className={'color-blue'}>
                                                                            {item2.attributes.scoreForJobtitle + ' %'}
                                                                        </p>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <p style={{
                                                                            lineHeight: '27px',
                                                                            textAlign: 'left',
                                                                            fontFamily: 'VagRoundedBold',
                                                                            fontSize: 16,
                                                                            margin: 0,
                                                                            color: item2.attributes.matchingArbeitgeberUnread == 0 ? '#00374e' : 'white',
                                                                        }}>
                                                                            swiitcher {item2.attributes.arbeitnehmer_id}
                                                                        </p>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <p style={{
                                                                            lineHeight: '30px',
                                                                            textAlign: 'left',
                                                                            fontSize: 16,
                                                                            margin: 0,
                                                                            color: item2.attributes.matchingArbeitgeberUnread == 0 ? '#00374e' : 'white',
                                                                        }}>
                                                                            {item2.status}
                                                                        </p>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <p style={{
                                                                            lineHeight: '30px',
                                                                            textAlign: 'left',
                                                                            fontSize: 16,
                                                                            margin: 0,
                                                                            color: item2.attributes.matchingArbeitgeberUnread == 0 ? '#00374e' : 'white',
                                                                        }}>
                                                                            {/*{item2.attributes.matchingTimeArbeitnehmer ? moment.utc(item2.attributes.matchingTimeArbeitnehmer).local().fromNow() : moment(item2.attributes.createdAt).fromNow()}*/}
                                                                            {item2.attributes.matchingTimeArbeitnehmer ? moment(item2.attributes.matchingTimeArbeitnehmer).fromNow() : moment(item2.attributes.createdAt).fromNow()}
                                                                        </p>
                                                                    </Grid>
                                                                </Grid>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    position: 'absolute',
                                                                    top: 9,
                                                                    right: 100,
                                                                    margin: '2px 0 0',
                                                                    paddingRight: 10,
                                                                    zIndex: 1
                                                                }}>
                                                                    {(item2.attributes.matchingArbeitgeberFavourite) ?
                                                                        <StarIcon
                                                                            style={{
                                                                                fontSize: 22,
                                                                                marginRight: 2,
                                                                                transition: 'all 0.2s ease-in-out',
                                                                                textAlign: 'center',
                                                                                color: item2.attributes.matchingArbeitgeberUnread == 0 ? '#00374e' : 'white',
                                                                                cursor: 'pointer',
                                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                            }}
                                                                            onClick={async () => {
                                                                                item2.attributes.matchingArbeitgeberFavourite = false
                                                                                await saveFavourite(item2, false)
                                                                                // setActiveMatchItem(item2);
                                                                            }}
                                                                        /> : <StarOutlineIcon
                                                                            style={{
                                                                                transition: 'all 0.2s ease-in-out',
                                                                                textAlign: 'center',
                                                                                color: item2.attributes.matchingArbeitgeberUnread == 0 ? '#00374e' : 'white',
                                                                                cursor: 'pointer',
                                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                            }}
                                                                            onClick={async () => {
                                                                                item2.attributes.matchingArbeitgeberFavourite = true
                                                                                await saveFavourite(item2, true)
                                                                                // setActiveMatchItem(item2);
                                                                            }}
                                                                        />}
                                                                </div>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    position: 'absolute',
                                                                    top: 9,
                                                                    right: 0,
                                                                    margin: '2px 0 0',
                                                                    paddingRight: 10,
                                                                    zIndex: 1
                                                                }}>
                                                                    {activeMatchItem !== item2 && <AddIcon
                                                                        style={{
                                                                            transition: 'all 0.2s ease-in-out',
                                                                            textAlign: 'center',
                                                                            color: item2.attributes.matchingArbeitgeberUnread == 0 ? '#00374e' : 'white',
                                                                            cursor: 'pointer',
                                                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                        }}
                                                                        onClick={async () => {
                                                                            item2.index = parseInt(index);
                                                                            item2.index2 = parseInt(index2);
                                                                            setEditDescription(false);
                                                                            setNoteArbeitnehmer('');
                                                                            if (activeMatchItem) await setUnread()
                                                                            setActiveMatchItem(item2);
                                                                        }}
                                                                    />}
                                                                    {(activeMatchItem === item2 || (activeMatchItem && activeMatchItem.id == item2.id)) &&
                                                                        <ClearIcon
                                                                            style={{
                                                                                fontSize: 22,
                                                                                marginRight: 2,
                                                                                transition: 'all 0.2s ease-in-out',
                                                                                textAlign: 'center',
                                                                                color: item2.attributes.matchingArbeitgeberUnread == 0 ? '#00374e' : 'white',
                                                                                cursor: 'pointer',
                                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                            }}
                                                                            onClick={async () => {
                                                                                let temp = item2;
                                                                                if ((activeMatchItem === item2 || (activeMatchItem && activeMatchItem.id == item2.id))) {
                                                                                    temp = null;
                                                                                }
                                                                                if (temp) {
                                                                                    temp.index = parseInt(index);
                                                                                    temp.index2 = parseInt(index2);
                                                                                }
                                                                                setEditDescription(false);
                                                                                setNoteArbeitnehmer('');
                                                                                await setUnread()
                                                                                setActiveMatchItem(temp);
                                                                            }}
                                                                        />}
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                position: 'absolute',
                                                                zIndex: 1,
                                                                transition: 'all 0.05s ease-in-out',
                                                                width: 'calc(100% - 54px)',
                                                                transform: (activeMatchItem === item2 || (activeMatchItem && activeMatchItem.id == item2.id)) ? 'scaleY(1)' : 'scaleY(0)',
                                                                visibility: (activeMatchItem === item2 || (activeMatchItem && activeMatchItem.id == item2.id)) ? 'visible' : 'hidden',
                                                                // height: (activeMatchItem === item2 || (activeMatchItem && activeMatchItem.id == item2.id)).text ? (150 + 20) : 40,
                                                                // height: (activeMatchItem === item2 || (activeMatchItem && activeMatchItem.id == item2.id)).text ? itemsRef.current[parseInt(index + '' + index2)].offsetHeight : 40,
                                                                height: 'calc(100% - 50px)',
                                                                top: 25,
                                                                right: 50,
                                                                borderBottomLeftRadius: 25,
                                                                borderBottomRightRadius: 25,
                                                                borderLeft: (activeMatchItem === item2 || (activeMatchItem && activeMatchItem.id == item2.id)) ? '2px solid #C7D300' : 'none',
                                                                borderRight: (activeMatchItem === item2 || (activeMatchItem && activeMatchItem.id == item2.id)) ? '2px solid #C7D300' : 'none',
                                                                borderBottom: (activeMatchItem === item2 || (activeMatchItem && activeMatchItem.id == item2.id)) ? '2px solid #C7D300' : 'none',
                                                            }}>
                                                            </div>
                                                            <div style={{
                                                                display: (activeMatchItem && activeMatchItem.id == item2.id) ? 'block' : 'none',
                                                                transition: 'all 0.1s ease-in-out',
                                                                height: '100%',
                                                                zIndex: 2,
                                                                position: 'relative',
                                                                contentVisibility: (activeMatchItem === item2 || (activeMatchItem && activeMatchItem.id == item2.id)) ? 'visible' : 'hidden',
                                                                // display: activeMatchItem === item.text ? 'block' : 'none',
                                                                opacity: (activeMatchItem === item2 || (activeMatchItem && activeMatchItem.id == item2.id)) ? 1 : 0,
                                                                marginTop: 15,
                                                                marginRight: 50,
                                                            }}
                                                                 ref={el => itemsRef.current[parseInt(index + '' + index2)] = el}>
                                                                {(questions && questions.length > 0) ? <>
                                                                    <div className={icon}
                                                                         style={{ padding: '10px 50px 50px' }}>
                                                                        <div style={{
                                                                            textAlign: 'left',
                                                                            position: 'relative',
                                                                        }}>
                                                                            <Grid container style={{
                                                                                position: 'relative',
                                                                                zIndex: 2
                                                                            }}>
                                                                                {questions.map((item, index3) => {
                                                                                    let items = [];
                                                                                    let test = [];

                                                                                    if (item.rightAnswers) {
                                                                                        item.rightAnswers.map((answer, index) => {
                                                                                            const Icon = (führerscheinLogos.hasOwnProperty(answer)) ? führerscheinLogos[answer] : null
                                                                                            items.push(
                                                                                                <span key={index + '-0'}
                                                                                                      style={{ padding: (answer == icon && helperText) ? '10px 20px' : '10px 20px 7px 20px' }}
                                                                                                      className={'answer rightAnswer center-vert'}>
                                                                                               {
                                                                                                   (Icon && item.question_id === "9" && (answer === "FL" || answer === "L")) ?
                                                                                                       <Icon style={{
                                                                                                           fill: "black",
                                                                                                           marginRight: 10,
                                                                                                           maxHeight: 18,
                                                                                                           width: 35
                                                                                                       }}/> : ""
                                                                                               }
                                                                                                    {
                                                                                                        (Icon && item.question_id === "9" && answer !== "FL" && answer !== "L") ?
                                                                                                            <Icon style={{
                                                                                                                fill: "black",
                                                                                                                marginRight: 10,
                                                                                                                maxHeight: 15,
                                                                                                                width: 35
                                                                                                            }}/> : ""
                                                                                                    }
                                                                                                    {(answer == icon && helperText) ? <>{answer}
                                                                                                        <Tooltip
                                                                                                            enterTouchDelay={0}
                                                                                                            title={helperText}>
                                                                                                            <InfoIcon
                                                                                                                style={{ marginLeft: 4 }}
                                                                                                                fontSize={'14px'}
                                                                                                                id={'infoIcon'}
                                                                                                            />
                                                                                                        </Tooltip></> : answer}
                                                                                </span>
                                                                                            )
                                                                                            test.push(answer);
                                                                                        })
                                                                                    }

                                                                                    if (item.answersAN) {
                                                                                        item.answersAN.map((answer, index) => {

                                                                                            const Icon = (führerscheinLogos.hasOwnProperty(answer)) ? führerscheinLogos[answer] : null
                                                                                            if (!test.includes(answer)) {
                                                                                                items.push(
                                                                                                    <span key={index + '-1'}
                                                                                                          style={{ padding: (answer == icon && helperText) ? '10px 20px' : '10px 20px 7px 20px' }}
                                                                                                          className={'answer center-vert'}>
                                                                                               {
                                                                                                   (Icon && item.question_id === "9" && (answer === "FL" || answer === "L")) ?
                                                                                                       <Icon style={{
                                                                                                           marginRight: 10,
                                                                                                           maxHeight: 18,
                                                                                                           width: 35
                                                                                                       }}/> : ""
                                                                                               }
                                                                                                        {
                                                                                                            (Icon && item.question_id === "9" && answer !== "FL" && answer !== "L") ?
                                                                                                                <Icon
                                                                                                                    style={{
                                                                                                                        marginRight: 10,
                                                                                                                        maxHeight: 15,
                                                                                                                        width: 35
                                                                                                                    }}/> : ""
                                                                                                        }
                                                                                                        {(answer == icon && helperText) ? <>{answer}
                                                                                                            <Tooltip
                                                                                                                enterTouchDelay={0}
                                                                                                                title={helperText}>
                                                                                                                <InfoIcon
                                                                                                                    style={{ marginLeft: 4 }}
                                                                                                                    fontSize={'14px'}
                                                                                                                    id={'infoIcon'}
                                                                                                                />
                                                                                                            </Tooltip></> : answer}
                                                                                </span>
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                    if (item.rightAnswers3) {
                                                                                        item.rightAnswers3.map((item, index) => {

                                                                                            items.push(
                                                                                                <span key={index + '-2'}
                                                                                                      style={{ padding: (item == icon && helperText) ? '8px 20px' : '10px 20px 7px 20px' }}
                                                                                                      className={'answer rightAnswer'}>
                                                                                    {(item == icon && helperText) ? <>{item}<Tooltip
                                                                                        enterTouchDelay={0}
                                                                                        title={helperText}>
                                                                                        <InfoIcon
                                                                                            style={{ marginLeft: 4 }}
                                                                                            fontSize={'14px'}
                                                                                            id={'infoIcon-white'}
                                                                                        />
                                                                                    </Tooltip></> : item}
                                                                                </span>
                                                                                            )
                                                                                            test.push(item);
                                                                                        })
                                                                                    }

                                                                                    if (item.answersAN3) {
                                                                                        item.answersAN3.map((item, index) => {

                                                                                            if (!test.includes(item)) {
                                                                                                items.push(
                                                                                                    <span key={index + '-3'}
                                                                                                          style={{ padding: (item == icon && helperText) ? '10px 20px' : '10px 20px 7px 20px' }}
                                                                                                          className={'answer'}>
                                                                                    {(item == icon && helperText) ? <>{item}<Tooltip
                                                                                        enterTouchDelay={0}
                                                                                        title={helperText}>
                                                                                        <InfoIcon
                                                                                            style={{ marginLeft: 4 }}
                                                                                            fontSize={'14px'}
                                                                                            id={'infoIcon'}
                                                                                        />
                                                                                    </Tooltip></> : item}
                                                                                </span>
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                    }

                                                                                    if (item.text) {
                                                                                        return <>
                                                                                            {index3 == 0 ?
                                                                                                <Grid item xs={12} style={{
                                                                                                    textAlign: 'left',
                                                                                                    fontFamily: 'VagRoundedBold',
                                                                                                    fontSize: 16,
                                                                                                    margin: 0,
                                                                                                    marginBottom: 10
                                                                                                }} className={'color-blue'}>
                                                                                                    Übersicht
                                                                                                </Grid> : null}
                                                                                            {(items && items.length > 0) &&
                                                                                                <div
                                                                                                    style={{
                                                                                                        minWidth: (item.question_id == 1 || item.question_id == 2 || item.question_id == 12 || width < 1100) ? '100%' : '100%',
                                                                                                        maxWidth: '50%',
                                                                                                        display: 'flex',
                                                                                                        flexWrap: 'wrap',
                                                                                                    }}
                                                                                                    key={index3}>
                                                                                                    <div style={{
                                                                                                        minWidth: 'fit-content',
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'center'
                                                                                                    }}>
                                                                                                        {item.text}
                                                                                                    </div>
                                                                                                    <div style={{
                                                                                                        display: 'flex',
                                                                                                        flexWrap: 'wrap',
                                                                                                        marginBottom: 'auto',
                                                                                                        lineHeight: 18,
                                                                                                        paddingLeft: 5
                                                                                                    }}>
                                                                                                        {items}
                                                                                                    </div>
                                                                                                </div>}
                                                                                            {item.question_id == 3 ?
                                                                                                <Grid item xs={12} style={{
                                                                                                    textAlign: 'left',
                                                                                                    fontFamily: 'VagRoundedBold',
                                                                                                    fontSize: 16,
                                                                                                    margin: 0,
                                                                                                    marginBottom: 10
                                                                                                }} className={'color-blue'}>
                                                                                                    Berufliche Interessen
                                                                                                </Grid> : null}
                                                                                            {item.question_id == 11 ?
                                                                                                <Grid item xs={12} style={{
                                                                                                    textAlign: 'left',
                                                                                                    fontFamily: 'VagRoundedBold',
                                                                                                    fontSize: 16,
                                                                                                    margin: 0,
                                                                                                    marginBottom: 10
                                                                                                }} className={'color-blue'}>
                                                                                                    Persönlichkeit
                                                                                                </Grid> : null}
                                                                                            {item.question_id == 1 ?
                                                                                                <Grid item xs={12} style={{
                                                                                                    textAlign: 'left',
                                                                                                    fontFamily: 'VagRoundedBold',
                                                                                                    fontSize: 16,
                                                                                                    margin: 0,
                                                                                                    marginBottom: 10
                                                                                                }} className={'color-blue'}>
                                                                                                    Qualifikationen
                                                                                                </Grid> : null}
                                                                                        </>
                                                                                    }

                                                                                })}
                                                                            </Grid>
                                                                        </div>
                                                                        {((description && description.length > 0)) ?
                                                                            <><p style={{
                                                                                textAlign: 'left',
                                                                                fontFamily: 'VagRoundedBold',
                                                                                maxWidth: '60%',
                                                                                whiteSpace: 'pre-wrap'
                                                                            }} className={'color-blue'}>
                                                                                Beschreibung
                                                                            </p>
                                                                                <Grid container>
                                                                                    <Grid item xs={12} style={{
                                                                                        marginTop: 20,
                                                                                        textAlign: 'left',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                    }}>
                                                                                        <LogoSvgQouselduselmark style={{
                                                                                            height: 55,
                                                                                            width: '75px',
                                                                                            fill: '#C7D300',
                                                                                            margin: '0 20px',
                                                                                            fontStyle: 'italic'
                                                                                        }}/>
                                                                                        <p style={{
                                                                                            textAlign: 'left',
                                                                                            fontFamily: 'VagRoundedBold',
                                                                                            maxWidth: '60%',
                                                                                            whiteSpace: 'pre-wrap'
                                                                                        }} className={'color-blue'}>
                                                                                            {description}
                                                                                        </p>
                                                                                    </Grid>
                                                                                </Grid></> : null}
                                                                        {(item2 && !item2.attributes.matchingArbeitgeber) ?
                                                                            <Grid item xs={12} style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'flex-end',
                                                                                lineHeight: '14px'
                                                                            }}>
                                                                                <p style={{
                                                                                    color: '#C7D300',
                                                                                    fontSize: 12,
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center'
                                                                                }}
                                                                                >für 99€ zzgl. MwSt.</p>
                                                                                <CustomButton
                                                                                    buttontype={'blueYellow'}
                                                                                    style={{
                                                                                        color: '#C7D300',
                                                                                        fontSize: 24,
                                                                                        width: 'auto',
                                                                                        margin: '0 10px'
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setNoteArbeitnehmer(item2.attributes.noteArbeitnehmer);
                                                                                        setOnContact(item2);
                                                                                    }}
                                                                                >Kontaktieren</CustomButton>
                                                                            </Grid> : null}
                                                                        {(item2 && item2.attributes.isBought && !item2.attributes.declineArbeitnehmer) ?
                                                                            <Grid item xs={12} style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'flex-end',
                                                                                lineHeight: '14px'
                                                                            }}>
                                                                                <CustomButton
                                                                                    buttontype={'blueYellow'}
                                                                                    style={{
                                                                                        color: '#C7D300',
                                                                                        fontSize: 24,
                                                                                        width: 'auto',
                                                                                        margin: '0 10px'
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        history.push('/chat?id=' + item2.id);
                                                                                    }}
                                                                                >Zum Chat</CustomButton>
                                                                            </Grid> : null}
                                                                        {(item2 && item2.attributes.noteArbeitnehmer && !item2.attributes.matchingArbeitnehmer && item2.attributes.matchingArbeitgeber) ?
                                                                            <div id={"beschreibungJobtitle"} style={{
                                                                                display: 'flex',
                                                                                flexDirection: isMobile ? 'column' : 'row',
                                                                                alignItems: 'end',
                                                                                marginLeft: 5,
                                                                                marginTop: 20,
                                                                                textAlign: 'left',
                                                                                width: '100%',
                                                                                maxWidth: 'calc(100% - 40px)',
                                                                                borderRadius: 15,
                                                                                background: !editDescription ? 'unset' : '#00374e',
                                                                                padding: 10,
                                                                                color: 'white',
                                                                                position: 'relative'
                                                                            }}>
                                                                                {(editDescription) ? <>

                                                                                    <TextareaAutosize
                                                                                        value={noteArbeitnehmer}
                                                                                        onChange={(e) => setNoteArbeitnehmer(e.target.value)}
                                                                                        className={'descriptionAnonymSwiitchen'}
                                                                                        style={{
                                                                                            width: isMobile ? 'calc(100% - 20px)' : 'calc(100% - 70px)',
                                                                                            maxWidth: isMobile ? 'calc(100% - 20px)' : 'calc(100% - 70px)',
                                                                                            borderColor: 'transparent !important',
                                                                                            borderRadius: 15,
                                                                                            padding: isMobile ? 10 : 35
                                                                                        }} minRows={5}>
                                                                                    </TextareaAutosize>

                                                                                    <div>
                                                                                        <CustomButton
                                                                                            buttontype={'white'}
                                                                                            onClick={() => {
                                                                                                // history.push('/anonym-swiitchen')
                                                                                                updateMatch(item2, true);
                                                                                            }}
                                                                                            style={{
                                                                                                float: 'right',
                                                                                                margin: '0 0 0 10px',
                                                                                                height: 40.13
                                                                                            }}
                                                                                        >Speichern</CustomButton>
                                                                                    </div>
                                                                                </> : null}

                                                                                {(!editDescription) ? <div style={{
                                                                                    background: '#00374e',
                                                                                    borderRadius: 25,
                                                                                    padding: 25,
                                                                                    width: '100%'
                                                                                }}>
                                                                                    <p style={{
                                                                                        fontFamily: 'VagRoundedBold',
                                                                                        paddingLeft: 25
                                                                                    }}>Deine Nachricht zur Anfrage</p>
                                                                                    <p style={{
                                                                                        paddingLeft: 24,
                                                                                        paddingRight: 50,
                                                                                        color: 'white'
                                                                                    }}>{item2.attributes.noteArbeitnehmer}</p>
                                                                                    <EditIcon
                                                                                        className={'not-yellow'}
                                                                                        style={{
                                                                                            position: 'absolute',
                                                                                            right: 25,
                                                                                            top: 25,
                                                                                            height: 30,
                                                                                            width: 30,
                                                                                            marginRight: 2,
                                                                                            transition: 'all 0.2s ease-in-out',
                                                                                            textAlign: 'right',
                                                                                            fill: 'white',
                                                                                            cursor: 'pointer',
                                                                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            // console.log('EDIT')
                                                                                            setNoteArbeitnehmer(item2.attributes.noteArbeitnehmer);
                                                                                            setEditDescription(true);
                                                                                        }}
                                                                                    />
                                                                                </div> : null}
                                                                            </div> : null}
                                                                    </div>
                                                                    <div style={{ height: 50 }}></div>
                                                                </> : <>
                                                                    <div ref={loadingSpinnerRef}
                                                                         style={{
                                                                             //display: 'flex',
                                                                             //justifyContent: 'center',
                                                                             //alignItems: 'center',
                                                                             color: 'white',
                                                                             paddingTop: 50,
                                                                             paddingLeft: 0,
                                                                             paddingRight: 0,
                                                                             paddingBottom: 100,
                                                                             width: '100%',
                                                                             display: 'flex',
                                                                             justifyContent: 'center',
                                                                             flexDirection: 'column'
                                                                         }}>
                                                                        <div id={'testLogo'} style={{
                                                                            borderRadius: 50,
                                                                            width: 60,
                                                                            height: 60,
                                                                            minWidth: 60,
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            display: 'flex',
                                                                            border: '4px solid transparent',
                                                                            // cursor: 'pointer',
                                                                            transitionDuration: '0.2s',
                                                                            transitionProperty: 'transform',
                                                                            margin: '0 auto'
                                                                        }}
                                                                        >
                                                                            <div style={{
                                                                                padding: 7.5,
                                                                                background: 'rgba(0, 55, 78, 1)',
                                                                                borderRadius: 50,
                                                                                width: 57,
                                                                                minWidth: 27,
                                                                                height: 35,
                                                                                minHeight: 35,
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                display: 'flex',
                                                                                border: '5px solid rgba(199, 211, 0, 1)'
                                                                            }}>
                                                                                <TestLogo
                                                                                    style={{
                                                                                        width: 30,
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                {hasMoreData[item.jobAdvertisement.id] ? <div style={{
                                                    width: '100%',
                                                    height: 30,
                                                    textAlign: 'right',
                                                    color: '#00374e',
                                                    cursor: 'pointer',
                                                }} onClick={() => {
                                                    getMatchings(item.jobAdvertisement.id, false, index, false)
                                                }}>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'end',
                                                        alignItems: 'center',
                                                        marginRight: 60
                                                    }}>
                                                    <span style={{
                                                        marginTop: 5,
                                                        fontFamily: 'VagRoundedBold',
                                                        textDecoration: 'underline'
                                                    }}>{item.left ? (item.left - item.matchingsScoreForJobtitle.length) : ''} weitere Matches laden</span>
                                                        <PlayArrow style={{ transform: 'rotate(90deg)' }}/>
                                                    </div>
                                                </div> : null}
                                                {/*</InfiniteScroll>*/}
                                                {(!item.matchingsScoreForJobtitle || !item.matchingsScoreForJobtitle.length) ?
                                                    <div>
                                                        {(isLoading) ? <div ref={loadingSpinnerRef}
                                                                            style={{
                                                                                //display: 'flex',
                                                                                //justifyContent: 'center',
                                                                                //alignItems: 'center',
                                                                                color: 'white',
                                                                                paddingTop: 50,
                                                                                paddingLeft: 0,
                                                                                paddingRight: 0,
                                                                                paddingBottom: 100,
                                                                                width: '100%',
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                flexDirection: 'column'
                                                                            }}>
                                                            <div id={'testLogo'} style={{
                                                                borderRadius: 50,
                                                                width: 60,
                                                                height: 60,
                                                                minWidth: 60,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                display: 'flex',
                                                                border: '4px solid transparent',
                                                                // cursor: 'pointer',
                                                                transitionDuration: '0.2s',
                                                                transitionProperty: 'transform',
                                                                margin: '0 auto'
                                                            }}
                                                            >
                                                                <div style={{
                                                                    padding: 7.5,
                                                                    background: 'rgba(0, 55, 78, 1)',
                                                                    borderRadius: 50,
                                                                    width: 57,
                                                                    minWidth: 27,
                                                                    height: 35,
                                                                    minHeight: 35,
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                    border: '5px solid rgba(199, 211, 0, 1)'
                                                                }}>
                                                                    <TestLogo
                                                                        style={{
                                                                            width: 30,
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> : <p>Keine Matches vorhanden</p>}
                                                    </div> : null}
                                            </div>
                                        </div>
                                        <div style={{
                                            display: 'block',
                                            maxHeight: isChecked[index] ? 'unset' : 100,
                                            transform: !isChecked[index] ? 'translate(20%)' : 'translateX(-100%)',
                                            overflow: 'hidden',
                                            flex: '0 0 100%'
                                        }}>
                                            <div style={{ marginBottom: 30 }}>
                                                {/*<InfiniteScroll*/}
                                                {/*    dataLength={item.matchingsScoreGeneral ? item.matchingsScoreGeneral.length : 0} //This is important field to render the next data*/}
                                                {/*    next={() => {*/}
                                                {/*        getMatchings(item.jobAdvertisement.id, true, index, false)*/}
                                                {/*    }}*/}
                                                {/*    hasMore={hasMoreData[item.jobAdvertisement.id] ?? false}*/}
                                                {/*    loader={<h4>Matches werden geladen...</h4>}*/}
                                                {/*    endMessage={*/}
                                                {/*        <p style={{textAlign: 'center'}}>*/}
                                                {/*        </p>*/}
                                                {/*    }*/}
                                                {/*    style={{overflow: 'unset', width: '100%'}}*/}
                                                {/*>*/}
                                                {item.matchingsScoreGeneral.map((item2, index2) => {
                                                    return (
                                                        <div
                                                            ref={el => matchingGeneralRef.current[item.jobAdvertisement.id] ? matchingGeneralRef.current[item.jobAdvertisement.id][parseInt(index2)] = el : false}
                                                            key={index + '' + index2}
                                                            style={{
                                                                position: 'relative',
                                                                height: (activeMatchItem && activeMatchItem.id == item2.id) ? '100%' : '52px'
                                                            }}>
                                                            {
                                                                (item2.attributes.matchingArbeitgeberHidden) ?
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        background: 'white',
                                                                        height: (activeMatchItem && activeMatchItem.id == item2.id) ? '100%' : '52px',
                                                                        width: 'calc(100% - 50px)',
                                                                        zIndex: '500',
                                                                        opacity: '0.4'
                                                                    }}></div> : <></>
                                                            }
                                                            {
                                                                (isViewModeEdit) ? <div style={{
                                                                    width: 50,
                                                                    height: '100%',
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    right: 0,
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                }}>
                                                                    <Switch
                                                                        checked={!item2.attributes.matchingArbeitgeberHidden}
                                                                        onChange={(e) => {
                                                                            item2.attributes.matchingArbeitgeberHidden = !e.target.checked
                                                                            saveHidden(item2, !e.target.checked)
                                                                        }}
                                                                    />

                                                                </div> : <></>
                                                            }
                                                            <div style={{
                                                                position: 'relative',
                                                                height: 25,
                                                                marginTop: 2,
                                                                paddingTop: 2,
                                                                background: item2.attributes.matchingArbeitgeberUnread == 0 ? '#F2F2F2' : '#C7D300',
                                                                border: item2.attributes.matchingArbeitgeberUnread == 0 ? '2px solid #F2F2F2' : '2px solid #C7D300',
                                                                borderRadius: 100,
                                                                padding: 10,
                                                                color: 'white',
                                                                marginRight: 50,
                                                                zIndex: 2
                                                            }}>
                                                                <Grid container>
                                                                    <Grid item xs={1}>
                                                                        <p style={{
                                                                            textAlign: 'left',
                                                                            fontFamily: 'VagRoundedBold',
                                                                            fontSize: 16,
                                                                            margin: '2px 0 0',
                                                                            paddingLeft: 10
                                                                        }} className={'color-blue'}>
                                                                            {item2.attributes.scoreGeneral + ' %'}
                                                                        </p>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <p style={{
                                                                            lineHeight: '27px',
                                                                            textAlign: 'left',
                                                                            fontFamily: 'VagRoundedBold',
                                                                            fontSize: 16,
                                                                            margin: 0,
                                                                            color: item2.attributes.matchingArbeitgeberUnread == 0 ? '#00374e' : 'white',
                                                                        }}>
                                                                            swiitcher {item2.attributes.arbeitnehmer_id}
                                                                        </p>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <p style={{
                                                                            lineHeight: '30px',
                                                                            textAlign: 'left',
                                                                            fontSize: 16,
                                                                            margin: 0,
                                                                            color: item2.attributes.matchingArbeitgeberUnread == 0 ? '#00374e' : 'white',
                                                                        }}>
                                                                            {item2.status}
                                                                        </p>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <p style={{
                                                                            lineHeight: '30px',
                                                                            textAlign: 'left',
                                                                            fontSize: 16,
                                                                            margin: 0,
                                                                            color: item2.attributes.matchingArbeitgeberUnread == 0 ? '#00374e' : 'white',
                                                                        }}>
                                                                            {/*{item2.attributes.matchingTimeArbeitnehmer ? moment.utc(item2.attributes.matchingTimeArbeitnehmer).local().fromNow() : moment(item2.attributes.createdAt).fromNow()}*/}
                                                                            {item2.attributes.matchingTimeArbeitnehmer ? moment(item2.attributes.matchingTimeArbeitnehmer).fromNow() : moment(item2.attributes.createdAt).fromNow()}
                                                                        </p>
                                                                    </Grid>
                                                                </Grid>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    position: 'absolute',
                                                                    top: 9,
                                                                    right: 100,
                                                                    margin: '2px 0 0',
                                                                    paddingRight: 10,
                                                                    zIndex: 1
                                                                }}>
                                                                    {(item2.attributes.matchingArbeitgeberFavourite) ?
                                                                        <StarIcon
                                                                            style={{
                                                                                fontSize: 22,
                                                                                marginRight: 2,
                                                                                transition: 'all 0.2s ease-in-out',
                                                                                textAlign: 'center',
                                                                                color: item2.attributes.matchingArbeitgeberUnread == 0 ? '#00374e' : 'white',
                                                                                cursor: 'pointer',
                                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                            }}
                                                                            onClick={async () => {
                                                                                item2.attributes.matchingArbeitgeberFavourite = false
                                                                                await saveFavourite(item2, false)
                                                                                // setActiveMatchItem(item2);
                                                                            }}
                                                                        /> : <StarOutlineIcon
                                                                            style={{
                                                                                transition: 'all 0.2s ease-in-out',
                                                                                textAlign: 'center',
                                                                                color: item2.attributes.matchingArbeitgeberUnread == 0 ? '#00374e' : 'white',
                                                                                cursor: 'pointer',
                                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                            }}
                                                                            onClick={async () => {
                                                                                item2.attributes.matchingArbeitgeberFavourite = true
                                                                                await saveFavourite(item2, true)
                                                                                // setActiveMatchItem(item2);
                                                                            }}
                                                                        />}
                                                                </div>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    position: 'absolute',
                                                                    top: 9,
                                                                    right: 0,
                                                                    margin: '2px 0 0',
                                                                    paddingRight: 10,
                                                                    zIndex: 1
                                                                }}>
                                                                    {activeMatchItem !== item2 && <AddIcon
                                                                        style={{
                                                                            transition: 'all 0.2s ease-in-out',
                                                                            textAlign: 'center',
                                                                            color: item2.attributes.matchingArbeitgeberUnread == 0 ? '#00374e' : 'white',
                                                                            cursor: 'pointer',
                                                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                        }}
                                                                        onClick={async () => {
                                                                            item2.index = parseInt(index);
                                                                            item2.index2 = parseInt(index2);
                                                                            if (activeMatchItem) await setUnread()
                                                                            setActiveMatchItem(item2);
                                                                        }}
                                                                    />}
                                                                    {(activeMatchItem === item2 || (activeMatchItem && activeMatchItem.id == item2.id)) &&
                                                                        <ClearIcon
                                                                            style={{
                                                                                fontSize: 22,
                                                                                marginRight: 2,
                                                                                transition: 'all 0.2s ease-in-out',
                                                                                textAlign: 'center',
                                                                                color: item2.attributes.matchingArbeitgeberUnread == 0 ? '#00374e' : 'white',
                                                                                cursor: 'pointer',
                                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                            }}
                                                                            onClick={async () => {
                                                                                let temp = item2;
                                                                                if ((activeMatchItem === item2 || (activeMatchItem && activeMatchItem.id == item2.id))) {
                                                                                    temp = null;
                                                                                }
                                                                                if (temp) {
                                                                                    temp.index = parseInt(index);
                                                                                    temp.index2 = parseInt(index2);
                                                                                }
                                                                                await setUnread()
                                                                                setActiveMatchItem(temp);
                                                                            }}
                                                                        />}
                                                                </div>
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    right: -40,
                                                                    top: 12,
                                                                    color: 'black'
                                                                }}>

                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                position: 'absolute',
                                                                zIndex: 1,
                                                                transition: 'all 0.05s ease-in-out',
                                                                width: 'calc(100% - 54px)',
                                                                transform: (activeMatchItem === item2 || (activeMatchItem && activeMatchItem.id == item2.id)) ? 'scaleY(1)' : 'scaleY(0)',
                                                                visibility: (activeMatchItem === item2 || (activeMatchItem && activeMatchItem.id == item2.id)) ? 'visible' : 'hidden',
                                                                height: 'calc(100% - 50px)',
                                                                top: 25,
                                                                right: 50,
                                                                borderBottomLeftRadius: 25,
                                                                borderBottomRightRadius: 25,
                                                                borderLeft: item2.attributes.matchingArbeitgeberUnread == 0 ? '2px solid #F2F2F2' : '2px solid #C7D300',
                                                                borderRight: item2.attributes.matchingArbeitgeberUnread == 0 ? '2px solid #F2F2F2' : '2px solid #C7D300',
                                                                borderBottom: item2.attributes.matchingArbeitgeberUnread == 0 ? '2px solid #F2F2F2' : '2px solid #C7D300',
                                                            }}>
                                                            </div>
                                                            <div style={{
                                                                display: (activeMatchItem && activeMatchItem.id == item2.id) ? 'block' : 'none',
                                                                transition: 'all 0.1s ease-in-out',
                                                                height: '100%',
                                                                zIndex: 2,
                                                                position: 'relative',
                                                                contentVisibility: (activeMatchItem === item2 || (activeMatchItem && activeMatchItem.id == item2.id)) ? 'visible' : 'hidden',
                                                                // display: activeMatchItem === item.text ? 'block' : 'none',
                                                                opacity: (activeMatchItem === item2 || (activeMatchItem && activeMatchItem.id == item2.id)) ? 1 : 0,
                                                                marginTop: 15,
                                                                marginRight: 50,
                                                            }}
                                                                 ref={el => itemsRef.current[parseInt(index + '' + index2)] = el}>
                                                                {(questions && questions.length > 0) ? <>
                                                                    <div className={icon}
                                                                         style={{ padding: '10px 50px 50px' }}>
                                                                        <div style={{
                                                                            textAlign: 'left',
                                                                            position: 'relative',
                                                                        }}>
                                                                            <Grid container style={{
                                                                                position: 'relative',
                                                                                zIndex: 2
                                                                            }}>
                                                                                {questions.map((item, index3) => {
                                                                                    let items = [];
                                                                                    let test = [];

                                                                                    if (item.rightAnswers) {
                                                                                        item.rightAnswers.map((answer, index) => {
                                                                                            const Icon = (führerscheinLogos.hasOwnProperty(answer)) ? führerscheinLogos[answer] : null
                                                                                            items.push(
                                                                                                <span key={index + '-0'}
                                                                                                      style={{ padding: (answer == icon && helperText) ? '10px 20px' : '10px 20px 7px 20px' }}
                                                                                                      className={'answer rightAnswer center-vert'}>
                                                                                               {
                                                                                                   (Icon && item.question_id === "9" && (answer === "FL" || answer === "L")) ?
                                                                                                       <Icon style={{
                                                                                                           fill: "black",
                                                                                                           marginRight: 10,
                                                                                                           maxHeight: 18,
                                                                                                           width: 35
                                                                                                       }}/> : ""
                                                                                               }
                                                                                                    {
                                                                                                        (Icon && item.question_id === "9" && answer !== "FL" && answer !== "L") ?
                                                                                                            <Icon style={{
                                                                                                                fill: "black",
                                                                                                                marginRight: 10,
                                                                                                                maxHeight: 15,
                                                                                                                width: 35
                                                                                                            }}/> : ""
                                                                                                    }
                                                                                                    {(answer == icon && helperText) ? <>{answer}
                                                                                                        <Tooltip
                                                                                                            enterTouchDelay={0}
                                                                                                            title={helperText}>
                                                                                                            <InfoIcon
                                                                                                                style={{ marginLeft: 4 }}
                                                                                                                fontSize={'14px'}
                                                                                                                id={'infoIcon'}
                                                                                                            />
                                                                                                        </Tooltip></> : answer}
                                                                                </span>
                                                                                            )
                                                                                            test.push(answer);
                                                                                        })
                                                                                    }

                                                                                    if (item.answersAN) {
                                                                                        item.answersAN.map((answer, index) => {

                                                                                            const Icon = (führerscheinLogos.hasOwnProperty(answer)) ? führerscheinLogos[answer] : null
                                                                                            if (!test.includes(answer)) {
                                                                                                items.push(
                                                                                                    <span key={index + '-1'}
                                                                                                          style={{ padding: (answer == icon && helperText) ? '10px 20px' : '10px 20px 7px 20px' }}
                                                                                                          className={'answer center-vert'}>
                                                                                               {
                                                                                                   (Icon && item.question_id === "9" && (answer === "FL" || answer === "L")) ?
                                                                                                       <Icon style={{
                                                                                                           marginRight: 10,
                                                                                                           maxHeight: 18,
                                                                                                           width: 35
                                                                                                       }}/> : ""
                                                                                               }
                                                                                                        {
                                                                                                            (Icon && item.question_id === "9" && answer !== "FL" && answer !== "L") ?
                                                                                                                <Icon
                                                                                                                    style={{
                                                                                                                        marginRight: 10,
                                                                                                                        maxHeight: 15,
                                                                                                                        width: 35
                                                                                                                    }}/> : ""
                                                                                                        }
                                                                                                        {(answer == icon && helperText) ? <>{answer}
                                                                                                            <Tooltip
                                                                                                                enterTouchDelay={0}
                                                                                                                title={helperText}>
                                                                                                                <InfoIcon
                                                                                                                    style={{ marginLeft: 4 }}
                                                                                                                    fontSize={'14px'}
                                                                                                                    id={'infoIcon'}
                                                                                                                />
                                                                                                            </Tooltip></> : answer}
                                                                                </span>
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                    }

                                                                                    if (item.rightAnswers3) {
                                                                                        item.rightAnswers3.map((item, index) => {

                                                                                            items.push(
                                                                                                <span key={index + '-2'}
                                                                                                      style={{ padding: (item == icon && helperText) ? '8px 20px' : '10px 20px 7px 20px' }}
                                                                                                      className={'answer rightAnswer'}>
                                                                                    {(item == icon && helperText) ? <>{item}<Tooltip
                                                                                        enterTouchDelay={0}
                                                                                        title={helperText}>
                                                                                        <InfoIcon
                                                                                            style={{ marginLeft: 4 }}
                                                                                            fontSize={'14px'}
                                                                                            id={'infoIcon-white'}
                                                                                        />
                                                                                    </Tooltip></> : item}
                                                                                </span>
                                                                                            )
                                                                                            test.push(item);
                                                                                        })
                                                                                    }

                                                                                    if (item.answersAN3) {

                                                                                        item.answersAN3.map((item, index) => {

                                                                                            if (!test.includes(item)) {
                                                                                                items.push(
                                                                                                    <span key={index + '-3'}
                                                                                                          style={{ padding: (item == icon && helperText) ? '10px 20px' : '10px 20px 7px 20px' }}
                                                                                                          className={'answer'}>
                                                                                    {(item == icon && helperText) ? <>{item}<Tooltip
                                                                                        enterTouchDelay={0}
                                                                                        title={helperText}>
                                                                                        <InfoIcon
                                                                                            style={{ marginLeft: 4 }}
                                                                                            fontSize={'14px'}
                                                                                            id={'infoIcon'}
                                                                                        />
                                                                                    </Tooltip></> : item}
                                                                                </span>
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                    }


                                                                                    if (item.text) {
                                                                                        return <>
                                                                                            {index3 == 0 ?
                                                                                                <Grid item xs={12} style={{
                                                                                                    textAlign: 'left',
                                                                                                    fontFamily: 'VagRoundedBold',
                                                                                                    fontSize: 16,
                                                                                                    margin: 0,
                                                                                                    marginBottom: 10
                                                                                                }} className={'color-blue'}>
                                                                                                    Übersicht
                                                                                                </Grid> : null}
                                                                                            {(items && items.length > 0) &&
                                                                                                <div
                                                                                                    style={{
                                                                                                        minWidth: (item.question_id == 1 || item.question_id == 2 || item.question_id == 12 || width < 1100) ? '100%' : '100%',
                                                                                                        maxWidth: '50%',
                                                                                                        display: 'flex',
                                                                                                        flexWrap: 'wrap',
                                                                                                    }}
                                                                                                    key={index3}>
                                                                                                    <div style={{
                                                                                                        minWidth: 'fit-content',
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'center'
                                                                                                    }}>
                                                                                                        {item.text}
                                                                                                    </div>
                                                                                                    <div style={{
                                                                                                        display: 'flex',
                                                                                                        flexWrap: 'wrap',
                                                                                                        marginBottom: 'auto',
                                                                                                        lineHeight: 18,
                                                                                                        paddingLeft: 5
                                                                                                    }}>
                                                                                                        {items}
                                                                                                    </div>
                                                                                                </div>}
                                                                                            {item.question_id == 3 ?
                                                                                                <Grid item xs={12} style={{
                                                                                                    textAlign: 'left',
                                                                                                    fontFamily: 'VagRoundedBold',
                                                                                                    fontSize: 16,
                                                                                                    margin: 0,
                                                                                                    marginBottom: 10
                                                                                                }} className={'color-blue'}>
                                                                                                    Berufliche Interessen
                                                                                                </Grid> : null}
                                                                                            {item.question_id == 11 ?
                                                                                                <Grid item xs={12} style={{
                                                                                                    textAlign: 'left',
                                                                                                    fontFamily: 'VagRoundedBold',
                                                                                                    fontSize: 16,
                                                                                                    margin: 0,
                                                                                                    marginBottom: 10
                                                                                                }} className={'color-blue'}>
                                                                                                    Persönlichkeit
                                                                                                </Grid> : null}
                                                                                            {item.question_id == 1 ?
                                                                                                <Grid item xs={12} style={{
                                                                                                    textAlign: 'left',
                                                                                                    fontFamily: 'VagRoundedBold',
                                                                                                    fontSize: 16,
                                                                                                    margin: 0,
                                                                                                    marginBottom: 10
                                                                                                }} className={'color-blue'}>
                                                                                                    Qualifikationen
                                                                                                </Grid> : null}
                                                                                        </>
                                                                                    }

                                                                                })}
                                                                            </Grid>
                                                                        </div>
                                                                        {((description && description.length > 0)) ?
                                                                            <><p style={{
                                                                                textAlign: 'left',
                                                                                fontFamily: 'VagRoundedBold',
                                                                                maxWidth: '60%',
                                                                                whiteSpace: 'pre-wrap'
                                                                            }} className={'color-blue'}>
                                                                                Beschreibung
                                                                            </p>
                                                                                <Grid container>
                                                                                    <Grid item xs={12} style={{
                                                                                        marginTop: 20,
                                                                                        textAlign: 'left',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                    }}>
                                                                                        <LogoSvgQouselduselmark style={{
                                                                                            height: 55,
                                                                                            width: '75px',
                                                                                            fill: '#C7D300',
                                                                                            margin: '0 20px',
                                                                                            fontStyle: 'italic'
                                                                                        }}/>
                                                                                        <p style={{
                                                                                            textAlign: 'left',
                                                                                            fontFamily: 'VagRoundedBold',
                                                                                            maxWidth: '60%',
                                                                                            whiteSpace: 'pre-wrap'
                                                                                        }} className={'color-blue'}>
                                                                                            {description}
                                                                                        </p>
                                                                                    </Grid>
                                                                                </Grid></> : null}
                                                                        {(item2 && !item2.attributes.matchingArbeitgeber) ?
                                                                            <Grid item xs={12} style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'flex-end',
                                                                                lineHeight: '14px'
                                                                            }}>
                                                                                <p style={{
                                                                                    color: '#C7D300',
                                                                                    fontSize: 12,
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center'
                                                                                }}
                                                                                >für 99€ zzgl. MwSt.</p>
                                                                                <CustomButton
                                                                                    buttontype={'blueYellow'}
                                                                                    style={{
                                                                                        color: '#C7D300',
                                                                                        fontSize: 24,
                                                                                        width: 'auto',
                                                                                        margin: '0 10px'
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setNoteArbeitnehmer(item2.attributes.noteArbeitnehmer);
                                                                                        setOnContact(item2);
                                                                                    }}
                                                                                >Kontaktieren</CustomButton>
                                                                            </Grid> : null}
                                                                        {(item2 && item2.attributes.isBought && !item2.attributes.declineArbeitnehmer) ?
                                                                            <Grid item xs={12} style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'flex-end',
                                                                                lineHeight: '14px'
                                                                            }}>
                                                                                <CustomButton
                                                                                    buttontype={'blueYellow'}
                                                                                    style={{
                                                                                        color: '#C7D300',
                                                                                        fontSize: 24,
                                                                                        width: 'auto',
                                                                                        margin: '0 10px'
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        history.push('/chat?id=' + item2.id);
                                                                                    }}
                                                                                >zum Chat</CustomButton>
                                                                            </Grid> : null}
                                                                        {(item2 && item2.attributes.noteArbeitnehmer && !item2.attributes.matchingArbeitnehmer) ?
                                                                            <div id={"beschreibungGeneral"} style={{
                                                                                display: 'flex',
                                                                                flexDirection: isMobile ? 'column' : 'row',
                                                                                alignItems: 'end',
                                                                                marginLeft: 5,
                                                                                marginTop: 20,
                                                                                textAlign: 'left',
                                                                                width: '100%',
                                                                                maxWidth: 'calc(100% - 40px)',
                                                                                borderRadius: 15,
                                                                                background: !editDescription ? 'unset' : '#00374e',
                                                                                padding: 10,
                                                                                color: 'white',
                                                                                position: 'relative'
                                                                            }}>
                                                                                {(editDescription) ? <>

                                                                                    <TextareaAutosize
                                                                                        value={noteArbeitnehmer}
                                                                                        onChange={(e) => setNoteArbeitnehmer(e.target.value)}
                                                                                        className={'descriptionAnonymSwiitchen'}
                                                                                        style={{
                                                                                            width: isMobile ? 'calc(100% - 20px)' : 'calc(100% - 70px)',
                                                                                            maxWidth: isMobile ? 'calc(100% - 20px)' : 'calc(100% - 70px)',
                                                                                            borderColor: 'transparent !important',
                                                                                            borderRadius: 15,
                                                                                            padding: isMobile ? 10 : 35
                                                                                        }} minRows={5}>
                                                                                    </TextareaAutosize>

                                                                                    <div>
                                                                                        <CustomButton
                                                                                            buttontype={'white'}
                                                                                            onClick={() => {
                                                                                                // history.push('/anonym-swiitchen')
                                                                                                updateMatch(item2, true);
                                                                                            }}
                                                                                            style={{
                                                                                                float: 'right',
                                                                                                margin: '0 0 0 10px',
                                                                                                height: 40.13
                                                                                            }}
                                                                                        >Speichern</CustomButton>
                                                                                    </div>
                                                                                </> : null}

                                                                                {(!editDescription) ? <div style={{
                                                                                    background: '#00374e',
                                                                                    borderRadius: 25,
                                                                                    padding: 25,
                                                                                    width: '100%'
                                                                                }}>
                                                                                    <p style={{
                                                                                        fontFamily: 'VagRoundedBold',
                                                                                        paddingLeft: 25
                                                                                    }}>Deine Nachricht zur Anfrage</p>
                                                                                    <p style={{
                                                                                        paddingLeft: 24,
                                                                                        paddingRight: 50,
                                                                                        color: 'white'
                                                                                    }}>{item2.attributes.noteArbeitnehmer}</p>
                                                                                    <EditIcon
                                                                                        className={'not-yellow'}
                                                                                        style={{
                                                                                            position: 'absolute',
                                                                                            right: 25,
                                                                                            top: 25,
                                                                                            height: 30,
                                                                                            width: 30,
                                                                                            marginRight: 2,
                                                                                            transition: 'all 0.2s ease-in-out',
                                                                                            textAlign: 'right',
                                                                                            fill: 'white',
                                                                                            cursor: 'pointer',
                                                                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            // console.log('EDIT')
                                                                                            setNoteArbeitnehmer(item2.attributes.noteArbeitnehmer);
                                                                                            setEditDescription(true);
                                                                                        }}
                                                                                    />
                                                                                </div> : null}
                                                                            </div> : null}
                                                                    </div>
                                                                    <div style={{ height: 50 }}></div>
                                                                </> : <>
                                                                    <div ref={loadingSpinnerRef}
                                                                         style={{
                                                                             color: 'white',
                                                                             paddingTop: 50,
                                                                             paddingLeft: 0,
                                                                             paddingRight: 0,
                                                                             paddingBottom: 100,
                                                                             width: '100%',
                                                                             display: 'flex',
                                                                             justifyContent: 'center',
                                                                             flexDirection: 'column'
                                                                         }}>
                                                                        <div id={'testLogo'} style={{
                                                                            borderRadius: 50,
                                                                            width: 60,
                                                                            height: 60,
                                                                            minWidth: 60,
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            display: 'flex',
                                                                            border: '4px solid transparent',
                                                                            // cursor: 'pointer',
                                                                            transitionDuration: '0.2s',
                                                                            transitionProperty: 'transform',
                                                                            margin: '0 auto'
                                                                        }}
                                                                        >
                                                                            <div style={{
                                                                                padding: 7.5,
                                                                                background: 'rgba(0, 55, 78, 1)',
                                                                                borderRadius: 50,
                                                                                width: 57,
                                                                                minWidth: 27,
                                                                                height: 35,
                                                                                minHeight: 35,
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                display: 'flex',
                                                                                border: '5px solid rgba(199, 211, 0, 1)'
                                                                            }}>
                                                                                <TestLogo
                                                                                    style={{
                                                                                        width: 30,
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                {hasMoreData[item.jobAdvertisement.id] ? <div style={{
                                                    width: '100%',
                                                    textAlign: 'right',
                                                    color: '#00374e',
                                                    cursor: 'pointer',
                                                    zIndex: 999999,
                                                }} onClick={() => {
                                                    getMatchings(item.jobAdvertisement.id, true, index, false)
                                                }}>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'end',
                                                        alignItems: 'center',
                                                        marginRight: 60
                                                    }}>
                                                    <span style={{
                                                        marginTop: 5,
                                                        fontFamily: 'VagRoundedBold',
                                                        textDecoration: 'underline'
                                                    }}>{item.right ? (item.right - item.matchingsScoreGeneral.length) : ''} weitere Matches laden</span>
                                                        <PlayArrow style={{ transform: 'rotate(90deg)' }}/>
                                                    </div>
                                                </div> : null}
                                                {/*</InfiniteScroll>*/}
                                                {(!item.matchingsScoreGeneral || !item.matchingsScoreGeneral.length) ? <div>
                                                    {(isLoading) ? <div ref={loadingSpinnerRef}
                                                                        style={{
                                                                            //display: 'flex',
                                                                            //justifyContent: 'center',
                                                                            //alignItems: 'center',
                                                                            color: 'white',
                                                                            paddingTop: 50,
                                                                            paddingLeft: 0,
                                                                            paddingRight: 0,
                                                                            paddingBottom: 100,
                                                                            width: '100%',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            flexDirection: 'column'
                                                                        }}>
                                                        <div id={'testLogo'} style={{
                                                            borderRadius: 50,
                                                            width: 60,
                                                            height: 60,
                                                            minWidth: 60,
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            border: '4px solid transparent',
                                                            // cursor: 'pointer',
                                                            transitionDuration: '0.2s',
                                                            transitionProperty: 'transform',
                                                            margin: '0 auto'
                                                        }}
                                                        >
                                                            <div style={{
                                                                padding: 7.5,
                                                                background: 'rgba(0, 55, 78, 1)',
                                                                borderRadius: 50,
                                                                width: 57,
                                                                minWidth: 27,
                                                                height: 35,
                                                                minHeight: 35,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                display: 'flex',
                                                                border: '5px solid rgba(199, 211, 0, 1)'
                                                            }}>
                                                                <TestLogo
                                                                    style={{
                                                                        width: 30,
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> : <p>Keine Matches vorhanden</p>}
                                                </div> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })}
                    <Modal
                        open={onContact}
                        onClose={() => {
                            setNoteArbeitnehmer(null);
                            setPageOne(true);
                            setOnContact(false);
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={onContactStyle}>
                            {pageOne ? <>
                                <div style={{
                                    display: 'flex',
                                    position: 'absolute',
                                    top: 9,
                                    right: 0,
                                    paddingRight: 15,
                                    zIndex: 1
                                }}>
                                    <ClearIcon
                                        className={'not-yellow'}
                                        onClick={() => {
                                            setNoteArbeitnehmer(null);
                                            setOnContact(false);
                                            setPageOne(true);
                                        }}
                                        style={{
                                            height: 35,
                                            width: 35,
                                            marginRight: 10,
                                            transition: 'all 0.2s ease-in-out',
                                            textAlign: 'center',
                                            fill: 'white',
                                            cursor: 'pointer',
                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                        }}
                                    />
                                </div>
                                <h3 style={{
                                    color: 'white',
                                    fontSize: 14,
                                    lineHeight: '18px',
                                    fontFamily: 'VagRoundedBold'
                                }}>Du möchtest swiitcher {onContact?.attributes?.arbeitnehmer_id} kontaktieren?
                                </h3>

                                <p style={{ color: 'white', fontSize: 11, textAlign: 'center', margin: '25px 0' }}>Dann
                                    stelle eine Anfrage für nur 99,- Euro* und tritt nach Bestätigung via Chat in
                                    Kontakt.</p>
                                <p style={{
                                    color: 'white',
                                    fontSize: 11,
                                    marginTop: 20,
                                    textAlign: 'center'
                                }}>Dein Vorteil</p>

                                <p style={{
                                    color: 'white',
                                    fontSize: 11,
                                    marginBottom: 30,
                                    fontFamily: 'VagRoundedBold',
                                    textAlign: 'center'
                                }}>Du bezahlst
                                    erst, wenn swiitcher {onContact?.attributes?.arbeitnehmer_id} deine Anfrage
                                    bestätigt.</p>

                                <div style={{ textAlign: 'center' }}>
                                    <CustomButton
                                        buttontype={'yellowWhite2'}
                                        style={{ width: 'auto', padding: '5px 25px', marginTop: 5 }}
                                        onClick={() => {
                                            setPageOne(false);
                                        }}
                                    >Anfragen</CustomButton>
                                </div>
                            </> : <>
                                <div style={{
                                    display: 'flex',
                                    position: 'absolute',
                                    top: 9,
                                    right: 0,
                                    paddingRight: 15,
                                    zIndex: 1
                                }}>
                                    <ClearIcon
                                        className={'not-yellow'}
                                        onClick={() => {
                                            setNoteArbeitnehmer(null);
                                            setOnContact(false);
                                            setPageOne(true);
                                        }}
                                        style={{
                                            height: 35,
                                            width: 35,
                                            marginRight: 10,
                                            transition: 'all 0.2s ease-in-out',
                                            textAlign: 'center',
                                            fill: 'white',
                                            cursor: 'pointer',
                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                        }}
                                    />
                                </div>
                                <h3 style={{
                                    color: 'white',
                                    fontSize: 14,
                                    lineHeight: '18px',
                                    fontFamily: 'VagRoundedBold'
                                }}>Möchtest du swiitcher {onContact?.attributes?.arbeitnehmer_id}<br/>etwas mitteilen?
                                </h3>

                                <TextareaAutosize value={noteArbeitnehmer}
                                                  onChange={(e) => setNoteArbeitnehmer(e.target.value)}
                                                  className={'noteArbeitnehmerAnonymSwiitchen'} style={{
                                    width: 'calc(100% - 40px)',
                                    padding: '10px 20px',
                                    borderColor: 'transparent !important',
                                    borderRadius: 30
                                }} minRows={5}>
                                </TextareaAutosize>

                                <div style={{ paddingLeft: 10, marginTop: 0, color: 'white', fontSize: 10 }}>
                                    (optional)
                                </div>

                                <div style={{ textAlign: 'center' }}>
                                    <CustomButton
                                        buttontype={'yellowWhite2'}
                                        style={{ width: 'auto', padding: '5px 25px', marginTop: 5 }}
                                        onClick={() => {
                                            updateMatch(onContact);
                                        }}
                                    >Anfrage absenden</CustomButton>
                                </div>
                            </>}
                        </Box>
                    </Modal>
                    <Modal
                        open={onMatchkorb}
                        onClose={() => setOnMatchkorb(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={onMatchkorbStyle} style={{ color: '#00374e' }}>
                            <h3 style={{
                                fontSize: 20,
                                lineHeight: '18px',
                                fontFamily: 'VagRoundedBold',
                                position: 'relative'
                            }}>
                                Matchkorb
                                <Tooltip placement="bottom"
                                         enterTouchDelay={0}
                                         title={'Diese Jobsuchenden haben deine Anfrage bestätigt! Schließe jetzt den Bestellvorgang ab und trete mit ihnen in Kontakt'}>
                                    <InfoIcon
                                        style={{ marginLeft: 0, color: '#00374e' }}
                                        fontSize={'14px'}
                                        id={'infoIcon'}
                                    />
                                </Tooltip>
                                <div
                                    id={'matchkorb'}
                                    style={{
                                        position: 'absolute',
                                        right: 0,
                                        top: -10
                                    }}
                                >
                                    <Einkaufswagen
                                        style={{
                                            width: 40,
                                            color: 'white',
                                        }}
                                    />
                                    {(confirmedMatchesCount && confirmedMatchesCount > 0) &&
                                        <div className={'count'} style={{
                                            background: '#C7D300',
                                            filter: 'unset',
                                            width: 6,
                                            height: 6,
                                            fontSize: 10,
                                            top: -2,
                                            right: -7,
                                            color: 'white',
                                            padding: '4px',
                                            fontFamily: 'VagRoundedBold'
                                        }}>{confirmedMatchesCount}
                                        </div>}
                                </div>
                            </h3>
                            <div style={{ margin: '40px 0 30px', fontSize: 14 }}>
                                {(confirmedMatches && confirmedMatches.length > 0) && confirmedMatches.map((item, index) => {
                                    return (
                                        <Grid container key={index}>
                                            <Grid item xs={8} style={{ textAlign: 'left' }}>
                                                swiitcher {item.attributes.arbeitnehmer_id}
                                            </Grid>
                                            <Grid item xs={4} style={{ textAlign: 'right' }}>
                                                99,00
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </div>
                            <Grid container style={{ margin: '10px 0', fontSize: 12 }}>
                                <Grid item xs={8} style={{ textAlign: 'left' }}>
                                    Summe Netto
                                </Grid>
                                <Grid item xs={4} style={{ textAlign: 'right' }}>
                                    {currencyFormat(confirmedMatches * 99.00)}
                                </Grid>
                            </Grid>

                            <Grid container style={{
                                margin: '10px 0',
                                paddingBottom: 2,
                                borderBottom: '1px solid #C7D300',
                                fontSize: 12
                            }}>
                                <Grid item xs={8} style={{ textAlign: 'left' }}>
                                    zzgl. Mwst.
                                </Grid>
                                <Grid item xs={4} style={{ textAlign: 'right' }}>
                                    {currencyFormat((confirmedMatches * 99.00) * 0.19)}
                                </Grid>
                            </Grid>

                            <Grid container style={{
                                margin: '10px 0',
                                paddingBottom: 2,
                                borderBottom: '2px solid #C7D300',
                                fontFamily: 'VagRoundedBold',
                                fontSize: 12
                            }}>
                                <Grid item xs={8} style={{ textAlign: 'left' }}>
                                    Gesamt Brutto in Euro
                                </Grid>
                                <Grid item xs={4} style={{ textAlign: 'right' }}>
                                    {currencyFormat((confirmedMatches * 99.00) * 1.19)}
                                </Grid>
                            </Grid>
                            <p style={{ textAlign: 'right', marginBottom: 0 }}>
                                <CustomButton
                                    buttontype={'blueYellow2'}
                                    style={{ width: 'auto', textAlign: 'right' }}
                                    onClick={() => {
                                        // console.log();
                                        // setOnContact(item.id);
                                        history.push('/matchkorb')
                                    }}
                                >Weiter</CustomButton>
                            </p>
                        </Box>
                    </Modal>
                </div>
            </Grid>
        </Grid>
    );
}
