import React, {useEffect, useRef} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Grid} from "@mui/material";
import {useState} from "react";
import {ReactComponent as LogoSvgGefunden} from "../images/svg/auge.svg";
import {ReactComponent as LogoSvgPuzzle} from "../images/svg/puzzle.svg";
import {ReactComponent as LogoSvgHeart} from "../images/svg/heart.svg";
import {ReactSession} from "react-client-session";
import useFetch from "./UseFetch";
import {useHistory} from "react-router";
import moment from "moment";
import {isMobile} from "react-device-detect";
import {ReactComponent as LogoSvgSwiitcherIcon} from "../images/svg/swiitcher_icon.svg";
import useWindowDimensions from "./useWindowDimensions";

export default function ÜbersichtArbeitgeber(props) {

    const {unreadChats} = props;
    const [activeMatchItem, setActiveMatchItem] = useState(null);
    const [matchesLoading, setMatchesLoading] = useState(true);
    const [chatLoading, setChatLoading] = useState(true);
    const [advertisementsLoading, setAdvertisementsLoading] = useState(true);
    const [userLoading, setUserLoading] = useState(true);
    const [matchesAlternative, setMatchesAlternative] = useState(-1);
    const [newChatModels, setNewChatModels] = useState([]);
    const [chatModels, setChatModels] = useState([]);
    const [newChatMessages, setNewChatMessages] = useState([]);
    const [advertisements, setAdvertisements] = useState([]);
    const [user, setUser] = useState(false);
    const [matchesJobTitle, setMatchesJobTitle] = useState(-1);
    const [progress, setProgress] = useState(-1);
    const [onHover, setOnHover] = useState(null);
    const api = useFetch();
    const history = useHistory();

    useEffect(() => {
        getMatchingOverview();
        getChat();
        getAdvertisements();
        getUser();
    }, [])

    const getMatchingOverview = async () => {
        let url = ENV.REACT_APP_API_URL + '/matchings/count/matching-overview';
        let {response, data} = await api(url)


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            return;
        }
        console.log(data);
        if (data && data.data) {
            setMatchesAlternative(data.data.matching_alternative);
            setMatchesJobTitle(data.data.matching_jobTitle);
            setMatchesLoading(false);
        }
    }

    const getChat = async () => {
        let url = ENV.REACT_APP_API_URL + '/matchings/count/chat-overview';
        let {response, data} = await api(url)


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            return;
        }
        if (data && data.data) {
            setNewChatModels(data.data.new_chat_models)
            setNewChatMessages(data.data.new_chat_messages)
            setChatModels(data.data.chat_models)
            setChatLoading(false);
        }
    }

    const getAdvertisements = async () => {

        let rows = [];
        let moreData = true;

        let url = ENV.REACT_APP_API_URL + '/arbeitgeber/1/job-advertisements?page%5Bsize%5D=3';

        let {response, data} = await api(url)


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/arbeitgeber');
            return;
        }
        if (data && data.data) {
            data.data.map((item, index) => {

                item.attributes.badges = JSON.parse(item.attributes.badges);
                if (rows.filter(i => i.id === item.id).length === 0) {
                    rows.push(item);
                } else {
                    rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])] = item;
                }
            })

            if (data.meta.pagination.total === rows.length) {
                moreData = false;
            }

        } else if (response.error) {
            //notify('API Fehler: ' + response.status, true);
        }
        setAdvertisements(rows);
        setAdvertisementsLoading(false);
    }

    const getUser = async () => {
        let url = ENV.REACT_APP_API_URL + '/user/' + ReactSession.get('userId');

        let {response, data} = await api(url)


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/arbeitgeber');
            return;
        }
        if (data && data.data) {

            getProgress(data.data);


            setUser(data.data);
        } else if (response.error) {
            //notify('API Fehler: ' + response.status, true);
        }
    }

    const getProgress = (user) => {
        console.log(user);
        let count = 0;
        Object.keys(user.attributes).map(function(key) {
            if ((key == 'email' ||
                key == 'companyName' ||
                key == 'street' ||
                key == 'houseNumber' ||
                key == 'zipcode' ||
                key == 'city' ||
                key == 'telephoneNumber' ||
                key == 'website')
                && user.attributes[key] !== '') {
                count++;
                console.log(user.attributes[key])
            }
        });

        if (count > 0) {
            setProgress(count * 100 / 8);
        } else {
            setProgress(0);
        }
    }

    const {width} = useWindowDimensions()

    const truncate = (str, n) => {
        return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
    };

    return (
        <Grid item xs={12} style={{
            display: 'block',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 64,
            marginBottom: activeMatchItem !== null ? 50 : 0,
            padding: '10px 30px 30px',
            height: 'calc(100vh - 20px)',
            width: '100%',
            minHeight: '800px',
            // background: '',
            //boxShadow: 'inset 0px 2px 4px -1px rgb(0 0 0 / 20%), inset 0px 4px 5px 0px rgb(0 0 0 / 14%), inset 0px 1px 10px 0px rgb(0 0 0 / 12%)'
        }}>
            <Grid container id={'top-container'} spacing={2}>
                <Grid item xs={6}>
                    {matchesLoading ? <></> :
                        <div style={{height: 'calc(100vh/2 - 100px)', position: 'relative', padding: 20,}}>
                            <p className={'headerName'}
                               style={{position: 'relative', width: 'fit-content', marginBottom: 10}}>
                                Matches
                                {/*<span className={'count'} style={{top: -12, right: -41, height: 16, width: 16}}>3</span>*/}
                            </p>
                            <Grid container style={{paddingTop: 0}}>
                                <Grid item xs={6}>
                                    <div style={{padding: 0, position: 'relative'}}>
                                        <div style={{margin: '20px auto 10px', maxWidth: '100%', height: isMobile ? width / 4 - 50 : 'auto', width: 100, padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                                            <LogoSvgSwiitcherIcon style={{height: 50}} />
                                        </div>
                                        <div style={{fontSize: 36, fontFamily: 'VagRoundedBold', color: '#00374e'}}>{matchesJobTitle}</div>

                                        <p style={{fontSize: 16, marginTop: 5, fontFamily: 'VagRoundedBold', color: '#00374e'}}>Potenzielle Matches</p>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={{padding: 0, position: 'relative'}}>
                                        <div style={{margin: '20px auto 10px', maxWidth: '100%', height: isMobile ? width / 4 - 50 : 'auto', width: 100, padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <LogoSvgGefunden style={{height: 50}} className={'eyeIcon'}/>
                                        </div>
                                        <div style={{fontSize: 36, fontFamily: 'VagRoundedBold', color: '#00374e'}}>{matchesAlternative}</div>
                                        <p style={{fontSize: 16, marginTop: 5, fontFamily: 'VagRoundedBold', color: '#00374e'}}>Alternative Matches</p>
                                    </div>
                                </Grid>
                                {/*<Grid item xs={6}>*/}
                                {/*    <div style={{padding: '10px 10px', position: 'relative'}}>*/}
                                {/*        <div style={{*/}
                                {/*            margin: 'auto',*/}
                                {/*            height: 35,*/}
                                {/*            width: 35,*/}
                                {/*            border: '2px solid #C7D300',*/}
                                {/*            borderRadius: 200,*/}
                                {/*            padding: 10,*/}
                                {/*            display: 'flex',*/}
                                {/*            alignItems: 'center',*/}
                                {/*            justifyContent: 'center'*/}
                                {/*        }}>*/}
                                {/*            <LogoSvgGefunden style={{height: 40}}/>*/}
                                {/*            <div style={{*/}
                                {/*                position: 'absolute',*/}
                                {/*                fontSize: 24,*/}
                                {/*                fontFamily: 'VagRoundedBold',*/}
                                {/*                color: '#00374e'*/}
                                {/*            }}>{matchesAlternative}</div>*/}
                                {/*        </div>*/}
                                {/*        <p style={{*/}
                                {/*            fontSize: 16,*/}
                                {/*            fontFamily: 'VagRoundedBold',*/}
                                {/*            color: '#00374e'*/}
                                {/*        }}>Gefunden</p>*/}
                                {/*    </div>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={6}>*/}
                                {/*    <div style={{padding: '10px 10px', position: 'relative'}}>*/}
                                {/*        <div style={{*/}
                                {/*            margin: 'auto',*/}
                                {/*            height: 35,*/}
                                {/*            width: 35,*/}
                                {/*            border: '2px solid #C7D300',*/}
                                {/*            borderRadius: 200,*/}
                                {/*            padding: 10,*/}
                                {/*            display: 'flex',*/}
                                {/*            alignItems: 'center',*/}
                                {/*            justifyContent: 'center'*/}
                                {/*        }}>*/}
                                {/*            <LogoSvgPuzzle style={{height: 40}}/>*/}
                                {/*            <div style={{*/}
                                {/*                position: 'absolute',*/}
                                {/*                fontSize: 24,*/}
                                {/*                fontFamily: 'VagRoundedBold',*/}
                                {/*                color: '#00374e'*/}
                                {/*            }}>{matches}</div>*/}
                                {/*        </div>*/}
                                {/*        <p style={{fontSize: 16, fontFamily: 'VagRoundedBold', color: '#00374e'}}>Deine*/}
                                {/*            Matches</p>*/}
                                {/*    </div>*/}
                                {/*</Grid>*/}
                            </Grid>
                            <p className={'buttonLinkYellow'} onClick={() => history.push('/matches-arbeitgeber')}
                               style={{
                                   position: 'absolute',
                                   textAlign: 'left',
                                   bottom: 0,
                                   marginLeft: 15,
                                   fontSize: 12,
                                   textDecoration: 'underline',
                                   color: '#C7D300',
                                   cursor: 'pointer',
                                   transition: 'all 0.1s ease-in-out'
                               }}>alle Matches</p>
                        </div>}
                </Grid>
                <Grid item xs={6}>
                    <div style={{height: 'calc(100vh/2 - 100px)', position: 'relative', padding: 20,}}>
                        <p className={'headerName'}
                           style={{position: 'relative', width: 'fit-content', marginBottom: 10}}>
                            Chats & Nachrichten
                            {unreadChats ? <span className={'count'} style={{top: -12, right: -41, height: 12, width: 12}}>{unreadChats}</span> : null}
                        </p>
                        {chatLoading ? <></> : <>
                            {newChatMessages && newChatMessages.map((item, index) => {
                                return (
                                    <div onClick={() => {
                                        history.push('/chat');
                                    }} style={{
                                        position: 'relative',
                                        height: 25,
                                        margin: '5px 15px 5px 15px',
                                        background: 'rgba(199, 211, 0, 0.2)',
                                        cursor: 'pointer',
                                        borderRadius: 25,
                                        border: '1px solid rgba(221, 221, 221, 0.5)',
                                        overflow: 'hidden',
                                        padding: '10px 10px 10px 15px',
                                        color: 'white',
                                    }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p className={'headerName'} style={{
                                                    lineHeight: '26px',
                                                    fontSize: 16,
                                                    position: 'relative',
                                                    width: '100%'
                                                }}>
                                                    {truncate(item.title, 30)}
                                                    <span style={{
                                                        position: 'absolute',
                                                        right: 10,
                                                        textAlign: 'right',
                                                        fontFamily: 'VagRoundedBold',
                                                        fontWeight: 'bold',
                                                        marginLeft: 10,
                                                        lineHeight: '28px',
                                                        color: '#C7D300'
                                                    }}>neue ungelesene Nachricht</span>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </div>
                                );
                            })}
                            {newChatModels && newChatModels.map((item, index) => {
                                return (
                                    <div onClick={() => {
                                        history.push('/chat');
                                    }}
                                         onMouseOver={() => {
                                             setOnHover('newChatModels-' + index)
                                         }}
                                         onMouseOut={() => {
                                             setOnHover(null)
                                         }}
                                         style={{
                                        position: 'relative',
                                        height: 25,
                                        margin: '5px 15px 5px 15px',
                                        background: onHover === ('newChatModels-' + index) ? 'rgba(199, 211, 0, 0.2)' : 'rgba(211, 211, 211, 0.5)',
                                        cursor: 'pointer',
                                        borderRadius: 25,
                                        border: '1px solid rgba(221, 221, 221, 0.5)',
                                        overflow: 'hidden',
                                        padding: '10px 10px 10px 15px',
                                        color: 'white',
                                    }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p className={'headerName'} style={{
                                                    lineHeight: '26px',
                                                    fontSize: 16,
                                                    position: 'relative',
                                                    width: '100%'
                                                }}>
                                                    {truncate(item.title, 30)}
                                                    <span style={{
                                                        position: 'absolute',
                                                        right: 10,
                                                        textAlign: 'right',
                                                        fontFamily: 'VagRoundedBold',
                                                        fontWeight: 'bold',
                                                        marginLeft: 10,
                                                        lineHeight: '28px',
                                                        color: '#C7D300'
                                                    }}>neuer Chat</span>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </div>
                                );
                            })}
                            {chatModels && chatModels.map((item, index) => {
                                return (
                                    <div onClick={() => {
                                        history.push('/chat');
                                    }}
                                         onMouseOver={() => {
                                             setOnHover('chatModels-' + index)
                                         }}
                                         onMouseOut={() => {
                                             setOnHover(null)
                                         }}
                                    style={{
                                        position: 'relative',
                                        height: 25,
                                        margin: '5px 15px 5px 15px',
                                        background: onHover === ('chatModels-' + index) ? 'rgba(199, 211, 0, 0.2)' : 'rgba(211, 211, 211, 0.5)',
                                        cursor: 'pointer',
                                        borderRadius: 25,
                                        border: '1px solid rgba(221, 221, 221, 0.5)',
                                        overflow: 'hidden',
                                        padding: '10px 10px 10px 15px',
                                        color: 'white',
                                    }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p className={'headerName'} style={{
                                                    lineHeight: '26px',
                                                    fontSize: 16,
                                                    position: 'relative',
                                                    width: '100%'
                                                }}>
                                                    {truncate(item.title, 30)}
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </div>
                                );
                            })}
                        </>}
                        <p className={'buttonLinkYellow'} onClick={() => history.push('/chat')} style={{
                            position: 'absolute',
                            textAlign: 'left',
                            bottom: 0,
                            marginLeft: 15,
                            fontSize: 12,
                            textDecoration: 'underline',
                            color: '#C7D300',
                            cursor: 'pointer',
                            transition: 'all 0.1s ease-in-out'
                        }}>alle Nachrichten</p>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{height: 'calc(100vh/2 - 100px)', position: 'relative', padding: 20,}}>
                        <p className={'headerName'}
                           style={{position: 'relative', width: 'fit-content', marginBottom: 10}}>
                            Aktuelle Stellenanzeigen
                        </p>

                        {advertisementsLoading ? <></> : <>
                            {advertisements && advertisements.map((item, index) => {
                                return (<>
                                        <div key={index}
                                             onMouseOver={() => {
                                                 setOnHover('advertisements-' + index)
                                             }}
                                             onMouseOut={() => {
                                                 setOnHover(null)
                                             }}
                                             style={{
                                            position: 'relative',
                                            height: 25,
                                            margin: '5px 15px 5px 15px',
                                            background: onHover === ('advertisements-' + index) ? 'rgba(199, 211, 0, 0.2)' : 'rgba(211, 211, 211, 0.5)',
                                            cursor: 'pointer',
                                            borderRadius: 25,
                                            border: '1px solid rgba(221, 221, 221, 0.5)',
                                            overflow: 'hidden',
                                            padding: '10px 10px 10px 15px',
                                            color: 'white',
                                        }}>
                                            <Grid container>
                                                <Grid item xs={9}>
                                                    <p className={'headerName'} style={{
                                                        lineHeight: '26px',
                                                        fontSize: 16,
                                                        position: 'relative',
                                                        width: 'fit-content'
                                                    }}>
                                                        {item.attributes.title}
                                                    </p>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <p className={'buttonLinkBlue'}
                                                       onClick={() => history.push('/stellenanzeige/' + item.id + '/update')}
                                                       style={{
                                                           textDecoration: 'underline',
                                                           textAlign: 'left',
                                                           fontWeight: 'bold',
                                                           margin: 0,
                                                           lineHeight: '28px',
                                                           color: '#00374e',
                                                           cursor: 'pointer',
                                                           transition: 'all 0.1s ease-in-out'
                                                       }}>bearbeiten</p>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>
                                );
                            })}
                        </>}


                        <p className={'buttonLinkYellow'} onClick={() => history.push('/stellenanzeigen')} style={{
                            position: 'absolute',
                            textAlign: 'left',
                            bottom: 0,
                            marginLeft: 15,
                            fontSize: 12,
                            textDecoration: 'underline',
                            color: '#C7D300',
                            cursor: 'pointer',
                            transition: 'all 0.1s ease-in-out'
                        }}>alle Anzeigen</p>
                        <p className={'buttonLinkYellow'} onClick={() => history.push('/neue-stellenanzeige')} style={{
                            position: 'absolute',
                            textAlign: 'left',
                            fontFamily: 'VagRoundedBold',
                            bottom: 0,
                            right: 35,
                            marginLeft: 15,
                            fontSize: 12,
                            textDecoration: 'underline',
                            color: '#C7D300',
                            cursor: 'pointer',
                            transition: 'all 0.1s ease-in-out'
                        }}>Neue Anzeige erstellen</p>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{
                        height: 'calc(100vh/2 - 100px)',
                        position: 'relative',
                        background: 'rgba(221, 221, 221, 0.5)',
                        padding: 20,
                        borderRadius: 40,
                    }}>
                        <p className={'headerName'}
                           style={{position: 'relative', width: 'fit-content', marginBottom: 10}}>
                            Unternehmensdaten
                        </p>
                        {user && <>
                            <Grid container style={{paddingLeft: 15, marginTop: 10}}>
                                <Grid item xs={3}>
                                    <p style={{textAlign: 'left', lineHeight: '26px', margin: 0}}
                                       className={'color-blue'}>Firmenname</p>
                                </Grid>
                                <Grid item xs={9}>
                                    <p style={{textAlign: 'left', margin: 0, fontFamily: 'VagRoundedBold'}}
                                       className={'color-blue'}>{user.attributes.companyName}</p>
                                </Grid>
                            </Grid>
                            <Grid container style={{paddingLeft: 15}}>
                                <Grid item xs={3}>
                                    <p style={{textAlign: 'left', lineHeight: '26px', margin: 0}}
                                       className={'color-blue'}>Anschrift</p>
                                </Grid>
                                <Grid item xs={9}>
                                    <p style={{textAlign: 'left', margin: 0, fontFamily: 'VagRoundedBold'}}
                                       className={'color-blue'}>{user.attributes.street} {user.attributes.houseNumber}</p>
                                </Grid>
                            </Grid>
                            <Grid container style={{paddingLeft: 15}}>
                                <Grid item xs={3}>
                                </Grid>
                                <Grid item xs={9}>
                                    <p style={{textAlign: 'left', margin: 0, fontFamily: 'VagRoundedBold'}}
                                       className={'color-blue'}>{user.attributes.zipcode} {user.attributes.city}</p>
                                </Grid>
                            </Grid>
                            <Grid container style={{paddingLeft: 15}}>
                                <Grid item xs={3}>
                                    <p style={{textAlign: 'left', lineHeight: '26px', margin: 0}}
                                       className={'color-blue'}>Telefon</p>
                                </Grid>
                                <Grid item xs={9}>
                                    <p style={{textAlign: 'left', margin: 0, fontFamily: 'VagRoundedBold'}}
                                       className={'color-blue'}>{user.attributes.telephoneNumber}</p>
                                </Grid>
                            </Grid>
                            <Grid container style={{paddingLeft: 15}}>
                                <Grid item xs={3}>
                                    <p style={{textAlign: 'left', lineHeight: '26px', margin: 0}}
                                       className={'color-blue'}>E-Mail</p>
                                </Grid>
                                <Grid item xs={9}>
                                    <p style={{textAlign: 'left', margin: 0, fontFamily: 'VagRoundedBold'}}
                                       className={'color-blue'}>{user.attributes.email}</p>
                                </Grid>
                            </Grid>
                            <Grid container style={{paddingLeft: 15}}>
                                <Grid item xs={3}>
                                    <p style={{textAlign: 'left', lineHeight: '26px', margin: 0}}
                                       className={'color-blue'}>Website</p>
                                </Grid>
                                <Grid item xs={9}>
                                    <p style={{textAlign: 'left', margin: 0, fontFamily: 'VagRoundedBold'}}
                                       className={'color-blue'}>{user.attributes.website ? user.attributes.website.replace('https://', '').replace('http://') : ''}</p>
                                </Grid>
                            </Grid>
                            <p style={{
                                position: 'absolute',
                                textAlign: 'left',
                                bottom: 25,
                                marginLeft: 15,
                                fontSize: 12,
                                color: '#C7D300'
                            }}>Dein Unternehmensprofil ist zu {progress}% fertiggestellt</p>
                            <p className={'buttonLinkYellow'} onClick={() => history.push('/einstellungen')} style={{
                                position: 'absolute',
                                textAlign: 'right',
                                bottom: 25,
                                right: 35,
                                marginLeft: 15,
                                fontSize: 12,
                                textDecoration: 'underline',
                                color: '#C7D300',
                                cursor: 'pointer',
                                transition: 'all 0.1s ease-in-out'
                            }}>Jetzt vervollständigen</p>
                            <div style={{
                                position: 'absolute',
                                bottom: 15,
                                width: 'calc(100% - 70px)',
                                height: 10,
                                marginLeft: 15,
                                fontSize: 12,
                                textDecoration: 'underline',
                                border: '3px solid #C7D300',
                                borderRadius: 10,
                            }}>
                                <div style={{
                                    marginTop: -1,
                                    background: '#C7D300',
                                    width: (progress.toString() + '%'),
                                    height: 'calc(100% + 2px)',
                                    borderBottomLeftRadius: 10,
                                    borderTopLeftRadius: 10
                                }}></div>
                            </div>
                        </>}
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}

