import React from "react";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import {Grid, Tooltip, tooltipClasses} from "@mui/material";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/styles";



const ToggleSwitchMatchesArbeitgeber = ({ id, name, checked, onChange, optionLabels, small, disabled }) => {

    return (
        <div className={"toggle-switch" + (small ? " small-switch" : "")} style={{maxWidth: '100%', position: 'relative'}}>
            <Grid container style={{position: 'absolute', left: 0, width: '100%', height: '100%', zIndex: 3}}>
                <Grid item xs={6} className={!checked ? 'toggle-switch-checked' : 'toggle-switch-not-checked'} style={{cursor: 'pointer', display: 'flex', alignItems: 'center', paddingLeft: 10}} onClick={e => {
                    onChange(false)
                }}>{optionLabels[0]}</Grid>
                <Grid item xs={6} className={checked ? 'toggle-switch-checked' : 'toggle-switch-not-checked'} style={{cursor: 'pointer', display: 'flex', alignItems: 'center', paddingRight: 10, justifyContent: 'end'}} onClick={e => {
                    onChange(true)
                }}>{optionLabels[1]}</Grid>
            </Grid>
            <input
                type="checkbox"
                name={name}
                className={"toggle-switch-checkbox" + (checked ? '-checked' : '')}
                checked={checked}
                id={id}
                onChange={() => {
                }}
            />
            {id ? (
                <label className="toggle-switch-label" htmlFor={id}>
            <span
                className={
                    disabled
                        ? "toggle-switch-inner toggle-switch-disabled"
                        : "toggle-switch-inner"
                }
                // data-yes={optionLabels[0]}
                // data-no={optionLabels[1]}
            />
                    <span
                        className={
                            disabled
                                ? "toggle-switch-switch toggle-switch-disabled"
                                : "toggle-switch-switch"
                        }
                    />
                </label>
            ) : null}
        </div>
    );
}

// Set optionLabels for rendering.
ToggleSwitchMatchesArbeitgeber.defaultProps = {
    // optionLabels: ["Matches", "Anonymes Profil"],
};

ToggleSwitchMatchesArbeitgeber.propTypes = {
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    optionLabels: PropTypes.array,
    small: PropTypes.bool,
    disabled: PropTypes.bool
};

export default ToggleSwitchMatchesArbeitgeber;
