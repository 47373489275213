import React, {useEffect} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Autocomplete, Grid, Link, Slider} from "@mui/material";
import {useHistory, useLocation} from "react-router";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {useState} from "react";
import {CssTextField3} from "./CssTextField3";
import {useMemo} from "react";
import debounce from "lodash.debounce";
import {styled} from "@mui/styles";
import {useParams} from 'react-router-dom';
import {ReactSession} from "react-client-session";
import LoremIpsum, {Avatar} from "react-lorem-ipsum";
import PhoneIcon from '@mui/icons-material/Phone';
import useFetch from "./UseFetch";
import {toast} from "react-toastify";
import renderHTML from "react-render-html";
import {CustomButton} from "./CustomButton";
import {Mail} from "@mui/icons-material";
import {ReactComponent as TestLogo} from "../images/svg/swiitcher_icon_blau.svg";
import {useRef} from "react";
import {isMobile} from "react-device-detect";
import LanguageIcon from '@mui/icons-material/Language';
import InfiniteScroll from "react-infinite-scroll-component";
import DOMPurify from 'dompurify';

const PrettoSlider = styled(Slider)({
    color: '#00374e',
    height: 3,
    width: 'calc(100% - 30px)',
    padding: 0,
    marginTop: 5,
    marginBottom: 5,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 14,
        width: 14,
        backgroundColor: '#C7D300',
        border: '2px solid #C7D300',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
        '&:after': {
            height: 16,
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': {display: 'none'},
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

export default function Jobsuche(props) {

    const {locationChange} = props;
    const location = useLocation();
    const history = useHistory();
    const params = useParams();
    const api = useFetch();
    const loadingSpinnerRef = useRef(null)

    const [favorite, setFavorite] = useState(null);
    const [onHover, setOnHover] = useState(-1);
    const [currentRange, setCurrentRange] = useState(30);
    const [scrollPosition, setScrollPosition] = useState(0);

    const [pageNumber, setPageNumber] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [isOpen, setIsOpen] = useState('');
    const [test, setTest] = useState(false);
    const [index, setIndex] = useState(0);
    const [item, setItem] = useState('0');
    const [id, setId] = useState(params?.id ?? null);
    const [avatarComp, setAvatarComp] = useState(null);


    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [subTitleError, setSubTitleError] = useState('');
    const [slogan, setSlogan] = useState('');
    const [sloganError, setSloganError] = useState('');
    const [m_w_d, setM_w_d] = useState('');
    const [employmentTypes, setEmploymentTypes] = useState('');
    const [description, setDescription] = useState('');
    const [imageDescription, setImageDescription] = useState('');
    const [editorText, setEditorText] = useState('');
    const [companyImage, setCompanyImage] = useState(false);
    const [teaserImage, setTeaserImage] = useState(false);

    const [companyAddresses, setCompanyAddresses] = React.useState([]);
    const [companyAddressInput, setCompanyAddressInput] = React.useState('');
    const [selectedCompanyAddresses, setSelectedCompanyAddresses] = React.useState([]);

    const [contacts, setContacts] = React.useState([]);
    const [contactInput, setContactInput] = React.useState('');
    const [selectedContacts, setSelectedContacts] = React.useState([]);

    const [badges, setBadges] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const [autocomplete, setAutocomplete] = useState([]);
    const [autocompleteLoading, setAutocompleteLoading] = useState(false);
    const [autocompleteInput, setAutocompleteInput] = useState('');
    const [selectedAutocomplete, setSelectedAutocomplete] = useState('');
    const [search, setSearch] = useState('');
    const [plz, setPlz] = useState('');
    const [results, setResults] = useState(false);
    const [onSearch, setOnSearch] = useState(true);
    const [searchActive, setSearchActive] = useState(false);
    const [itemsOverall, setItemsOverall] = useState(false);

    // ? typo3 params
    const urlParams = new URLSearchParams(window.location.search);
    const iFrameActive = urlParams.get('iframe') === 'true'

    const notify = (message, error) => {
        // NOTIFY
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    useEffect(() => {
        if (id) {
            getStellenanzeige();
        } else {
            setTitle('');
            setSubTitle('');
            setSlogan('');
            setM_w_d('');
            setEmploymentTypes('');
            setDescription('');
            setImageDescription('');
            setEditorText('');
            setCompanyImage(false);
            setTeaserImage(false);
            setBadges([]);
            setSelectedContacts([]);
            setSelectedCompanyAddresses([]);
        }
    }, [id])

    useEffect(() => {
        document.title = 'Jobsuche';
        locationChange(location);

        setAvatarComp(<Avatar
            style={{
                borderRadius: 100
            }}
            gender="male"
            className="avatar"
            width="160"
            height="160"
            alt="Avatar"
        />);

        window.addEventListener('scroll', listenToScroll);

        return () => {
            window.removeEventListener('scroll', listenToScroll);
            // ReactSession.set('scrollPosition', undefined);
        }
    }, [])

    const getStellenanzeige = async () => {

        let url = ENV.REACT_APP_API_URL + '/job-advertisement/' + id + '?include=contacts,companyAddresses';
        let {response, data} = await api(url)
        if (data && data.data) {
            setTitle(data.data.attributes.title);
            setSubTitle(data.data.attributes.subTitle);
            setSlogan(data.data.attributes.slogan);
            setM_w_d(data.data.attributes.m_w_d);
            setEmploymentTypes(data.data.attributes.employmentTypes);
            setDescription(data.data.attributes.description);
            setImageDescription(data.data.attributes.imageDescription);
            setEditorText(data.data.attributes.text);
            setCompanyImage(data.data.attributes.logo_id);
            setTeaserImage(data.data.attributes.file_id);


            if (data.data.attributes.badges) {
                let badge = [];
                let temp = JSON.parse(data.data.attributes.badges);
                temp.map((item, index) => {
                    badge.push(item);
                })
                setBadges(badge);
            }

            if (data.included) {
                setSelectedContacts(data.included.filter(i => i.type == 'contacts'));

                setSelectedCompanyAddresses(data.included.filter(i => i.type == 'company-addresses'));

            }
            setLoaded(true);

        } else if (response.status == '404') {
            //history.goBack();
            notify('Stellenanzeige wurde leider nicht gefunden', true);
        } else if (response.error) {
            notify('API Fehler: ' + response.status, true);
        }
    }

    const listenToScroll = () => {
        const temp = window.pageYOffset;

        if (id == undefined && temp > 0) {
            // setHeight
            ReactSession.set('scrollPosition', temp);
        }
    }

    useEffect(() => {
        setId(params?.id);
        getJobs();
    }, [location])

    const getJobs = async () => {

        let rows = [];
        let moreData = true;

        for (let i = 1; i < pageNumber + 1; i++) {
            let url = ENV.REACT_APP_API_URL + '/job-advertisements?page%5Bsize%5D=20&page%5Bnumber%5D=' + i + '&include=creator';

            //if (this.state.sort) url += '&sort=' + this.state.sort;

            let {response, data} = await api(url)


            if (!response || response.status == 401) {
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                history.push('/arbeitgeber');
                return;
            }
            if (data && data.data) {
                setItemsOverall(data.meta.pagination.total);
                await Promise.all(data.data.map(async (item, index) => {
                    // let temp = {
                    //     title: item.attributes.anrede + ' ' + item.attributes.vorname + ' ' + item.attributes.nachname,
                    //     value: item.id
                    // }
                    item.attributes.creator = data.included.filter(i => i.type == 'users' && i.id == item.attributes.creator_id)[0] ?? null;


                    let url = ENV.REACT_APP_API_URL + '/job-advertisement/' + item.id + '?include=companyAddresses,contacts,creator';

                    //if (this.state.sort) url += '&sort=' + this.state.sort;

                    let temp = await api(url)

                    let data2 = temp.data;

                    if (data2 && data2.included) item.attributes.companyAddress = data2.included.filter(i => i.type == 'company-addresses') ? data2.included.filter(i => i.type == 'company-addresses') : [];
                    if (data2 && data2.included) item.attributes.contacts = data2.included.filter(i => i.type == 'contacts') ? data2.included.filter(i => i.type == 'contacts') : [];
                    if (data2 && data2.included) item.attributes.m_w_d = data2.included.filter(i => i.type == 'users') ? data2.included.filter(i => i.type == 'users')[0].attributes.m_w_d : false;


                    item.attributes.badges = JSON.parse(item.attributes.badges);

                    if (rows.filter(i => i.id === item.id).length === 0) {
                        // rows.push(item);
                        rows.push(item);
                    } else {
                        rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])] = item;
                    }
                }));

                if (data.meta.pagination.total === rows.length) {
                    moreData = false;
                }

            } else if (response.error) {
            }
        }

        setJobs(rows.sort((a, b) => a.attributes.createdAt < b.attributes.createdAt ? 1 : -1));
        setHasMoreData(moreData);
        setTimeout(() => {
            setOnSearch(false);
        }, 300)
    }

    useEffect(() => {
        if (id === undefined) {
            let temp = ReactSession.get('scrollPosition');
        }
    }, [id])

    const getResults = async () => {
        setOnSearch(true);
        setSearchActive(true);


        let url = '';


        if (selectedAutocomplete && selectedAutocomplete.id && !search) {
            // zipcode only
            url = ENV.REACT_APP_API_URL + '/job-advertisements?filter[id]=' + selectedAutocomplete.id + '&filter[range]=' + currentRange + '&include=creator';
        } else if (search && !selectedAutocomplete) {
            // search only
            url = ENV.REACT_APP_API_URL + '/job-advertisements?filter[search][like]=' + search + '&include=creator';
        } else if (selectedAutocomplete && selectedAutocomplete.id && search) {
            // zipcode and search
            url = ENV.REACT_APP_API_URL + '/job-advertisements?filter[id]=' + selectedAutocomplete.id + '&filter[range]=' + currentRange + '&filter[search][like]=' + search + '&include=creator';
        } else {
            setSearchActive(false);
            getJobs();
            return;
            // url = ENV.REACT_APP_API_URL + '/job-advertisements' + '?include=creator';
        }
        let {response, data} = await api(url);
        let t = [];
        if (data && data.data) {
            setItemsOverall(data.meta.pagination.total);
            await Promise.all(data.data.map(async (item, index) => {
                item.attributes.badges = JSON.parse(item.attributes.badges);
                let url = ENV.REACT_APP_API_URL + '/job-advertisement/' + item.id + '?include=companyAddresses,contacts';

                //if (this.state.sort) url += '&sort=' + this.state.sort;

                let temp = await api(url)

                let data2 = temp.data;

                if (data2 && data2.included) item.attributes.companyAddress = data2.included.filter(i => i.type == 'company-addresses') ? data2.included.filter(i => i.type == 'company-addresses') : [];
                if (data2 && data2.included) item.attributes.contacts = data2.included.filter(i => i.type == 'contacts') ? data2.included.filter(i => i.type == 'contacts') : [];
                item.attributes.creator = data.included.filter(i => i.type == 'users' && i.id == item.attributes.creator_id)[0] ?? null;
                t.push(item)
            }));
            t.sort((a, b) => (parseInt(a.id) > parseInt(b.id)) ? 1 : -1)
            setJobs(t);
        }

        setTimeout(() => {
            setOnSearch(false);
        }, 300)

    }

    function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    const getZipcodes = async (url, newInputValue) => {
        let t = [];
        if (isNumeric(newInputValue)) {
            url += '?filter%5Bzipcode%5D%5Beq%5D=' + newInputValue;
        } else {
            url += '?filter%5Bname%5D%5Blike%5D=' + encodeURIComponent(newInputValue);
        }



        let {response, data} = await api(url);

        if (data && data.data) {
            data.data.map((item, index) => {
                if (t.filter(i => i.attributes.zipcode == item.attributes.zipcode && i.attributes.name == item.attributes.name).length == 0) t.push(item);
            })
            setAutocomplete([]);
            setAutocomplete(t);
            setAutocompleteLoading(false);
        }
    }

    const debouncedChangeHandler = useMemo(
        () => debounce(getZipcodes, 400)
        , []);

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            getResults();
        }
    };

    const newData = () => {
        if (hasMoreData === true) {
            let pageN = pageNumber;
            let temp = ++pageN;
            setPageNumber(temp);
        }
    }

    useEffect(() => {
        if (pageNumber > 1) {
            getJobs()
        }
    }, [pageNumber])

    return (
        <Grid container style={{
            padding: 0,
        }}>
            <Grid id={'top-container'} className={'asdf'} item xs={12} style={{
                display:  'block',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: iFrameActive? 0 : 64,
                padding: '20px 0 0',
                minHeight: 'calc(100vh - 20px)',
                width: '100%',
                overflow: 'hidden',
                // background: '',
                //boxShadow: 'inset 0px 2px 4px -1px rgb(0 0 0 / 20%), inset 0px 4px 5px 0px rgb(0 0 0 / 14%), inset 0px 1px 10px 0px rgb(0 0 0 / 12%)'
            }}>
                <Grid container style={{
                    margin: isMobile ? '0px 20px' : '0 120px',
                    width: isMobile ? 'calc(100% - 40px)' : 'calc(100% - 240px)',
                    maxHeight: 60
                }}>
                    {!iFrameActive && <Grid item xs={12}>
                        <p style={{marginBottom: 50, textAlign: 'left', fontSize: 12, color: 'black'}}
                           ><Link style={{cursor: 'pointer', color: 'black', textDecoration: 'unset'}}
                                                          onClick={() => history.push('')}>Startseite</Link><span> > </span> {id ?
                            <Link style={{cursor: 'pointer', color: 'rgb(199, 211, 0)', textDecoration: 'unset', fontFamily: 'VagRoundedBold'}}
                                  onClick={() => history.goBack()}>Jobsuche</Link> : <span style={{color: 'rgb(199, 211, 0)', fontFamily: 'VagRoundedBold'}}>Jobsuche</span>}{title ? (' > ' + title) : id ? (' > ' + id) : ''}
                        </p>
                    </Grid>}
                    { (iFrameActive && id) &&
                        <Grid item xs={12}>
                            <p style={{marginBottom: 50, textAlign: 'left', fontSize: 16, color: 'black'}}
                            ><Link style={{cursor: 'pointer',  color: 'rgba(0, 55, 78, 1)' }}
                                   onClick={() => history.goBack()}>Zurück</Link>
                            </p>
                        </Grid>
                    }
                </Grid>


                 <Grid container style={{width: '100%'}} key={id}>
                    {!id && <>
                    {!iFrameActive &&<Grid item xs={12} style={{margin: isMobile ? '0 20px 0' : '0 120px 0'}}>
                            <h1 className={'headerName color-yellow'} style={{position: 'relative', marginLeft: 0, marginBottom: isMobile ? 10 : 0}}>
                                Jobsuche
                            </h1>
                        </Grid>}
                        <Grid container style={{
                            margin: isMobile ? '0px 20px 20px' : '0 120px 20px',
                            maxWidth: 600,
                            minWidth: isMobile ? 'unset' : 400
                        }}>
                            <Grid item xs={isMobile ? 12 : 5} style={{paddingLeft: 0, paddingRight: 10}}>
                                <Grid container>
                                    <Grid item xs={12} style={{minHeight: isMobile ? 10 : 57.13}}>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <CssTextField3
                                            sx={{input: {color: 'black'}}}
                                            label="Jobs, Unternehmen, Keywords"
                                            className={'text-input-label-blue'}
                                            style={{
                                                transition: 'all 0.2s ease',
                                                margin: '0 0 8px 0',
                                                minHeight: 38,
                                                width: '100%'
                                            }}
                                            onKeyPress={handleKeypress}
                                            value={search}
                                            onChange={(e) => {
                                                setSearch(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={isMobile ? 12 : 5} style={{paddingLeft: 0, paddingRight: 10}}>
                                <Grid container>
                                    <Grid item xs={12} style={{minHeight: 57.13, maxHeight: 57.13, paddingBottom: 5}}>
                                        <p style={{
                                            transition: 'all 0.2s ease',
                                            textAlign: 'left',
                                            color: '#00374e',
                                            marginTop: (test || plz) ? 5 : 20,
                                            marginLeft: 19,
                                            marginBottom: 0,
                                            fontSize: 10
                                        }}>Umkreis</p>
                                        <PrettoSlider
                                            size={'small'}
                                            defaultValue={30}
                                            step={10}
                                            min={10}
                                            value={currentRange}
                                            onChange={(event, newValue) => {

                                                if (newValue !== currentRange) {
                                                    setCurrentRange(newValue);
                                                    //debouncedChangeHandler(jobs, plz, newValue);
                                                }

                                            }}
                                            onKeyPress={handleKeypress}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{position: 'relative'}}>
                                            <Autocomplete
                                                options={autocomplete}
                                                getOptionLabel={(option) => {
                                                    if (option && option.attributes) {
                                                        return option.attributes.zipcode + ' ' + option.attributes.name;
                                                    } else {
                                                        return '';
                                                    }
                                                }}
                                                dataSourceConfig={{text: props.title, value: props.input}}
                                                name={''}
                                                loading={autocompleteLoading === 'zipcode'}
                                                freeSolo
                                                openOnFocus={false}
                                                value={selectedAutocomplete}
                                                onChange={(event, newValue) => {
                                                    if (newValue && newValue.attributes || newValue == null) {
                                                        setSelectedAutocomplete(newValue);
                                                    }
                                                    if (newValue && newValue.length > 0 || autocompleteInput && autocompleteInput.length > 0) setTest(true);
                                                    else setTest(false)
                                                }}
                                                inputValue={autocompleteInput}
                                                onInputChange={async (event, newInputValue) => {
                                                    if (newInputValue == undefined || newInputValue == 'undefined') {
                                                        newInputValue = '';
                                                    }
                                                    setAutocompleteInput(newInputValue);
                                                    let t = [];
                                                    if (newInputValue && newInputValue.length > 0 || selectedAutocomplete && selectedAutocomplete.length > 0) setTest(true);
                                                    else setTest(false);

                                                    if (newInputValue && newInputValue.length > 1) {
                                                        let url = ENV.REACT_APP_API_URL + '/zipcodes';
                                                        setAutocompleteLoading('zipcode');
                                                        debouncedChangeHandler(url, newInputValue);

                                                    }
                                                }}
                                                // open={(autocompleteInput.length > 2 && autocomplete.length > 0)}
                                                open={(autocomplete.length > 0 && (!selectedAutocomplete && autocompleteInput || selectedAutocomplete && (selectedAutocomplete.attributes.zipcode + ' ' + selectedAutocomplete.attributes.name) != autocompleteInput))}
                                                className={'autocompleteSearchJobsuche'}
                                                onFocus={() => {
                                                    setTest(true);
                                                }}
                                                onBlur={() => {
                                                    if (!selectedAutocomplete || selectedAutocomplete.length === 0) {
                                                        setTest(false);
                                                        setAutocomplete([]);
                                                    }
                                                }}
                                                onKeyPress={handleKeypress}
                                                selectOnFocus
                                                clearOnBlur
                                                handleHomeEndKeys
                                                renderInput={(params) =>
                                                    <>
                                                        <CssTextField3
                                                            {...params}
                                                            sx={{input: {color: 'black'}}}
                                                            autoComplete="new-password"
                                                            label="PLZ oder Ort"
                                                            style={{
                                                                transition: 'all 0.2s ease',
                                                                margin: '0 0 8px 0',
                                                                width: '100%',
                                                                maxHeight: 41.13,
                                                            }}
                                                            className={'text-input-label-blue ASDF'}
                                                        />
                                                    </>
                                                }
                                            />
                                            <div style={{
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                                width: 40.13,
                                                height: 40.13,
                                                borderRadius: 25,
                                                background: '#C7D300',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                lineHeight: '12px'
                                            }}>
                                                <p style={{
                                                    position: 'absolute',
                                                    marginTop: 10,
                                                    color: 'white'
                                                }}>{currentRange}</p>
                                                <p style={{
                                                    position: 'absolute',
                                                    fontSize: 10,
                                                    marginTop: 25,
                                                    color: 'white'
                                                }}>km</p>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={2} style={{paddingRight: 0}}>
                                <Grid container>
                                    <Grid item xs={12} style={{minHeight: isMobile ? 10 : 57.13}}>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomButton
                                            buttontype={'yellowWhite'}
                                            onClick={() => {
                                                getResults();
                                            }}
                                            style={{
                                                height: 40.13
                                            }}
                                        >Suchen</CustomButton>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </>}
                    {!id ? onSearch ? <div ref={loadingSpinnerRef}
                                           style={{
                                               //display: 'flex',
                                               //justifyContent: 'center',
                                               //alignItems: 'center',
                                               color: 'white',
                                               paddingTop: 60,
                                               paddingLeft: 0,
                                               paddingRight: 0,
                                               paddingBottom: 0,
                                               width: '100%',
                                               display: 'flex',
                                               justifyContent: 'center',
                                               flexDirection: 'column'
                                           }}>
                        <div id={'testLogo'} style={{
                            borderRadius: 50,
                            width: 60,
                            height: 60,
                            minWidth: 60,
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            border: '4px solid transparent',
                            // cursor: 'pointer',
                            transitionDuration: '0.2s',
                            transitionProperty: 'transform',
                            margin: '0 auto'
                        }}
                        >
                            <div style={{
                                padding: 7.5,
                                background: 'rgba(0, 55, 78, 1)',
                                borderRadius: 50,
                                width: 57,
                                minWidth: 27,
                                height: 35,
                                minHeight: 35,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                border: '5px solid rgba(199, 211, 0, 1)'
                            }}>
                                <TestLogo
                                    style={{
                                        width: 30,
                                    }}
                                />
                            </div>
                        </div>
                    </div> : jobs.length === 0 ? <div style={{
                        flexShrink: 0,
                        width: '100%'
                    }}>
                        <p style={{
                            margin: isMobile ? '0px auto 0' : '0px auto 20px',
                            padding: '0 10px',
                            maxWidth: 600,
                        }}>Leider konnten wir zu deiner Suche keine Ergebnisse finden</p>
                    </div> : <>{(!id && jobs.length > 0 && itemsOverall) && <div style={{
                        margin: isMobile ? '0px 20px 0' : '0 120px 0',
                        width: '100%'
                    }}>
                        <Grid container style={{textAlign: 'left'}}>
                            <Grid item xs={8}>
                                {/*<p style={{*/}
                                {/*    marginTop: 0,*/}
                                {/*    marginLeft: isMobile ? 0 : 20,*/}
                                {/*    color: '#00374e',*/}
                                {/*    fontFamily: 'VagRoundedBold'*/}
                                {/*}}>{itemsOverall} {itemsOverall == 1 ? 'Job' : 'Jobs'}</p>*/}

                            </Grid>
                            <Grid item xs={4} style={{textAlign: 'right'}}>
                            </Grid>
                        </Grid>
                    </div>}
                    <InfiniteScroll
                        dataLength={jobs ? jobs.length : 0} //This is important field to render the next data
                        next={newData}
                        hasMore={searchActive ? false : hasMoreData}
                        loader={<h4>Jobs werden geladen...</h4>}
                        endMessage={
                            <p style={{textAlign: 'center'}}>
                            </p>
                        }
                        style={{overflow: 'unset', width: '100%'}}
                    >
                        {jobs.map((item, index) => {
                        return (
                            <>
                                <Grid
                                    item
                                    key={index}
                                    xs={12}
                                    style={{
                                        position: 'relative',
                                        margin: isMobile ? '10px 20px 10px' : '10px 120px 10px',
                                        padding: 20,
                                        transition: 'all 0.2s ease',
                                        cursor: 'pointer',
                                        minHeight: 215,
                                        maxWidth: 'calc(100% - 40px)',
                                        background: onHover == index ? 'rgba(199, 211, 0, 0.1)' : 'rgba(221, 221, 221, 0.4)',
                                        borderRadius: 25
                                    }}
                                    onMouseOver={() => setOnHover(index)}
                                    onMouseOut={() => setOnHover(-1)}
                                    onClick={() => {
                                        let temp = item.id;
                                        setTitle(item.attributes.title);
                                        let iframeParam = iFrameActive ? '?iframe=true' : ''
                                        history.push('/jobsuche/' + temp + iframeParam );
                                    }}
                                >
                                    <Grid container>
                                        <Grid item xs={isMobile ? 12 : 8} style={{position: 'relative', minHeight: 175, paddingBottom: 55}}>
                                            <div style={{
                                                display: isMobile ? 'block' : 'flex',
                                                textAlign: 'left',
                                                fontFamily: 'VagRoundedBold',
                                                fontSize: isMobile ? 22 : 32,
                                                margin: 0
                                            }} className={'color-blue'}>
                                                <p style={{ flexGrow: 1, width: '70%', margin: '0 10px 0 0', lineHeight: '32px', lineBreak: 'anywhere' }}>{item.attributes.title} {item.attributes.m_w_d ? <span style={{fontFamily: 'VagRoundedRegular', color: '#333', fontSize: 16}}>(m/w/d)</span> : ''}{item.attributes.subTitle ? <span style={{fontFamily: 'VagRoundedRegular', fontSize: 16}}><br/>{item.attributes.subTitle}</span> : null}</p>{(isMobile ? <br/> : ' ')}
                                                {item.attributes.employmentTypes ? <p style={{textAlign: 'left', width: isMobile ? 'unset' : 275, margin: 0, fontFamily: 'VagRoundedBold', color: 'black', fontSize: 16, paddingLeft: 10}}>{item.attributes.employmentTypes}</p> : ''}
                                            </div>
                                            <div style={{color: '#00374e', textAlign: 'left', position: 'absolute', left: 0, bottom: 0}}>
                                                {item.attributes.creator && <p style={{
                                                    margin: 0,
                                                    display: 'inline-flex',
                                                    alignItems: 'center'
                                                }}>
                                                    {/*<LocationOnIcon color={'secondary'} fontSize={'large'}/>&nbsp;<span*/}
                                                    {/*style={{marginTop: 5}}>Edelweis GmbH & Co.KG | 87435 Kempten</span>*/}
                                                    {/*<span style={{fontSize:10}}>&nbsp;</span>*/}
                                                    <LocationOnIcon color={'secondary'} fontSize={'large'}/>&nbsp;<span
                                                    style={{marginTop: 5}}>{(item.attributes.companyAddress?.length > 0) ? item.attributes.companyAddress[0].attributes.companyName : item.attributes.creator.attributes.companyName}
                                                    <span style={{fontSize: 6}}>&nbsp;</span>
                                                    {(item && item.attributes && item.attributes.companyAddress && item.attributes.companyAddress?.length > 0) ?
                                                        <>|<span
                                                            style={{fontSize: 6}}>
                                                            &nbsp;
                                                        </span>{item.attributes.companyAddress[0].attributes.zipcode ?? ''}
                                                            <span
                                                                style={{fontSize: 6}}>
                                                                &nbsp;
                                                            </span>{item.attributes.companyAddress[0].attributes.city ?? ''}
                                                            <span
                                                            >{item.attributes.companyAddress.length > 1 ? ` und ${(item.attributes.companyAddress.length - 1) === 1 ? "1 weiterer Standort" : (item.attributes.companyAddress.length - 1) + " weitere Standorte"}`: ''}</span>
                                                        </> :
                                                        null}
                                            </span>
                                                </p>}
                                            </div>
                                        </Grid>
                                        <Grid item xs={isMobile ? 12 : 4} style={{display: 'flex', margin: 'auto', paddingTop: isMobile ? 10 : 0}}>
                                            {item.attributes.logo_id && <img
                                                src={ENV.REACT_APP_API_URL + '/file/' + item.attributes.logo_id + '/unlogged/content'}
                                                style={{
                                                    width: 'auto',
                                                    margin: 'auto',
                                                    maxHeight: 175,
                                                    maxWidth: '100%'
                                                }}/>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        );
                    })}
                    </InfiniteScroll>
                    </> : <Grid
                        item
                        xs={12}
                        style={{
                            position: 'relative',
                            margin: isMobile ? '10px 20px 10px' : '10px 120px 10px',
                            padding: '20px 0',
                            transition: 'all 0.2s ease',
                            borderTop: '2px solid',
                            maxWidth: isMobile ? 'calc(100% - 40px)' : 'calc(100% - 240px)'
                            // background: onHover == index ? 'rgba(199, 211, 0, 0.1)' : 'rgba(221, 221, 221, 0.4)',
                            // borderRadius: 25
                        }}
                    >

                        {/*<p style={{*/}
                        {/*    display: 'flex',*/}
                        {/*    alignItems: 'center',*/}
                        {/*    justifyContent: 'center',*/}
                        {/*    fontSize: 32,*/}
                        {/*    fontFamily: 'VagRoundedBold'*/}
                        {/*}}>JOB {id} DETAILPAGE</p>*/}

                        <Grid container>
                            <Grid item xs={isMobile ? 12 : 8}>
                                <div style={{
                                    display: 'flex',
                                    textAlign: 'left',
                                    fontFamily: 'VagRoundedBold',
                                    fontSize: isMobile ? 22 : 32,
                                    margin: 0
                                }} className={'color-blue'}>
                                    <p style={{ flexGrow: 1, width: '70%', margin: '0 10px 0 0', lineHeight: '32px', lineBreak: 'anywhere' }}>{title} {m_w_d ? <span style={{fontFamily: 'VagRoundedRegular', color: '#333', fontSize: 16}}>(m/w/d)</span> : ''}{subTitle ? <span style={{fontFamily: 'VagRoundedRegular', fontSize: 16}}><br/>{subTitle}</span> : null}</p>{(isMobile ? <br/> : ' ')}
                                    {employmentTypes ? <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'left', width: isMobile ? 'unset' : 275, margin: 0, fontFamily: 'VagRoundedBold', color: 'black', fontSize: 16, paddingLeft: 10}}>{employmentTypes}</p> : ''}
                                </div>
                            </Grid>
                            {!isMobile ? <Grid item xs={4}>
                            </Grid> : null}
                        </Grid>

                        <Grid container>
                            <Grid item xs={isMobile ? 12 : 8} style={{
                                paddingRight: isMobile ? 0 : 40,
                                borderRight: isMobile ? 'none' : '1px solid #ddd',
                                marginTop: 20,
                            }}>
                                <Grid container style={{color: '#00374e', textAlign: 'left', maxWidth: '100%'}}>
                                    {badges.map((item, index) => {
                                        if (item.value) {
                                            return (
                                                <span key={index} style={{boxDecorationBreak: 'clone', color: 'white', padding: '6px 15px', margin: '5px 5px 0 0', background: '#00374e', borderRadius: ENV.REACT_APP_BORDER_RADIUS, }}>
                                                    {item.value}
                                                </span>
                                            );
                                        }
                                    })}
                                </Grid>
                                <p style={{color: '#00374e', textAlign: 'left', fontSize: 13, marginTop: 20}}>
                                    {description}
                                </p>

                                <div style={{position: 'relative'}}>
                                    {/*<img src={placeholder} style={{width: '100%'}}/>*/}
                                    {teaserImage &&
                                        <img
                                            src={ENV.REACT_APP_API_URL + '/file/' + teaserImage + '/unlogged/content'}
                                            style={{maxWidth: '100%', maxHeight: 400}}/>}
                                    {(teaserImage && imageDescription) && <div style={{
                                        position: 'absolute',
                                        bottom: -10,
                                        textAlign: 'center',
                                        width: '100%',
                                    }}>
                                        <div style={{
                                            background: '#00374e',
                                            borderRadius: 50,
                                            padding: '7px 20px 10px',
                                            fontFamily: 'VagRoundedBold',
                                            color: 'white',
                                            fontSize: 28,
                                            margin: 'auto',
                                            width: 'fit-content'
                                        }}>
                                            {imageDescription}
                                        </div>

                                    </div>}
                                </div>

                                <div className="text-wrapper"
                                     style={{textAlign: 'left', marginTop: 50, fontFamily: 'Arial'}}>
                                    {renderHTML(DOMPurify.sanitize(editorText))}
                                </div>
                                <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: 40}}>
                                </div>
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 4}
                                  style={{textAlign: 'left', width: 'calc(100% - 80px)', padding: isMobile ? 20 : 40}}
                                  className={'color-blue'}>
                                {companyImage &&
                                    <img
                                        src={ENV.REACT_APP_API_URL + '/file/' + companyImage + '/unlogged/content'}
                                        style={{maxWidth: '100%'}}/>}
                                {(selectedCompanyAddresses && selectedCompanyAddresses.length > 0) && <p style={{
                                    marginTop: 40,
                                    marginBottom: 10,
                                    fontFamily: 'VagRoundedBold',
                                    fontSize: 20
                                }}>ARBEITGEBER</p>}
                                {selectedCompanyAddresses && selectedCompanyAddresses.map((item, index) => {
                                    return (
                                        <p style={{marginTop: 0}}>
                                            {item.attributes.companyName}<br/>
                                            {item.attributes.street}&#8202; {item.attributes.houseNumber}<br/>
                                            {item.attributes.zipcode}&#8202; {item.attributes.city}<br/><br/>

                                            {item.attributes.telephoneNumber && <div
                                                style={{display: 'flex', alignItems: 'center', lineHeight: '16px'}}>
                                                <PhoneIcon style={{fontSize: 16}}/>
                                                <p style={{
                                                    margin: '2px 0 0 2px',
                                                    paddingTop: 2,
                                                    marginTop: 2
                                                }}><a className={'oldLinkStyle'}
                                                      href={'telto:' + item.attributes.telephoneNumber}>{item.attributes.telephoneNumber}</a>
                                                </p>
                                            </div>}
                                            {item.attributes.email && <div
                                                style={{display: 'flex', alignItems: 'center', lineHeight: '16px'}}>
                                                <Mail style={{fontSize: 16}}/>
                                                <p style={{
                                                    margin: '2px 0 0 2px',
                                                    paddingTop: 2,
                                                    marginTop: 2
                                                }}><a className={'oldLinkStyle'}
                                                      href={'mailto:' + item.attributes.email}>{item.attributes.email}</a>
                                                </p>
                                            </div>}
                                            {item.attributes.website && <div
                                                style={{display: 'flex', alignItems: 'center', lineHeight: '16px'}}>
                                                <LanguageIcon style={{fontSize: 16}}/>
                                                <p style={{
                                                    margin: '2px 0 0 2px',
                                                    paddingTop: 2,
                                                    marginTop: 2
                                                }}><a className={'oldLinkStyle'}
                                                      href={item.attributes.website} target={'_blank'}>{item.attributes.website}</a>
                                                </p>
                                            </div>}
                                        </p>
                                    );
                                })}

                                {(selectedContacts && selectedContacts.length > 0) && <p style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                    fontFamily: 'VagRoundedBold',
                                    fontSize: 20
                                }}>Ansprechpartner</p>}
                                {selectedContacts && selectedContacts.map((item, index) => {
                                    return (
                                        <div style={{marginBottom: 10}}>
                                            {(item.attributes.file_id) &&
                                                <img
                                                    src={ENV.REACT_APP_API_URL + '/file/' + item.attributes.file_id + '/unlogged/content'}
                                                    style={{
                                                        borderRadius: 10,
                                                        objectFit: 'cover',
                                                        padding: '10px 0',
                                                        maxWidth: '60%',
                                                        // aspectRatio: '4 / 3',
                                                    }}
                                                    className="avatar"
                                                    width="100%"
                                                    height="auto"
                                                    alt="Avatar"
                                                />}
                                            <p style={{margin: 0}}>
                                                {item.attributes.anrede} {item.attributes.vorname} {item.attributes.nachname}
                                            </p>
                                            <br/>
                                            {item.attributes.telefon && <div
                                                style={{display: 'flex', alignItems: 'center', lineHeight: '16px'}}>
                                                <PhoneIcon style={{fontSize: 16}}/>
                                                <p style={{
                                                    margin: '2px 0 0 2px',
                                                    paddingTop: 2,
                                                    marginTop: 2
                                                }}><a className={'oldLinkStyle'}
                                                      href={'telto:' + item.attributes.telefon}>{item.attributes.telefon}</a>
                                                </p>
                                            </div>}
                                            {item.attributes.email && <div
                                                style={{display: 'flex', alignItems: 'center', lineHeight: '16px'}}>
                                                <Mail style={{fontSize: 16}}/>
                                                <p style={{
                                                    margin: '2px 0 0 2px',
                                                    paddingTop: 2,
                                                    marginTop: 2
                                                }}><a className={'oldLinkStyle'}
                                                      href={'mailto:' + item.attributes.email}>{item.attributes.email}</a>
                                                </p>
                                            </div>}
                                        </div>

                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>}
                </Grid>
            </Grid>
        </Grid>
    );
}

