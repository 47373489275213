import React, {useEffect, useRef} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup, FormLabel,
    Grid, InputLabel,
    Link, MenuItem,
    Modal, Radio, RadioGroup,
    Tooltip
} from "@mui/material";
import {useState} from "react";
import {useHistory, useLocation} from "react-router";
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import {ReactComponent as LogoSvgArbeitgeber1} from "../images/svg/swiitcher_icon.svg";
import {ReactComponent as LogoSvgGefunden} from "../images/svg/gefunden.svg";
import {ReactComponent as LogoSvgHeart} from "../images/svg/heart.svg";
import {ReactComponent as LogoSvgHeartOutline} from "../images/svg/heart_outline.svg";
import {ReactComponent as LogoSvgPuzzle} from "../images/svg/puzzle.svg";
import {CssTextField3} from "./CssTextField3";
import {useMemo} from "react";
import debounce from "lodash.debounce";
import LoremIpsum from "react-lorem-ipsum";
import {ReactComponent as LogoSvgQouselduselmark} from "../images/svg/qouselduselmark.svg";
import {ReactComponent as Einkaufswagen} from '../images/svg/einkaufswagen.svg';
import useFetch from "./UseFetch";
import {ReactSession} from "react-client-session";
import htmlToDraft from "html-to-draftjs";
import ContentState from "draft-js/lib/ContentState";
import {EditorState} from "draft-js";
import {stateToHTML} from "draft-js-export-html";
import {toast} from "react-toastify";
import {CustomButton} from "./CustomButton";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import ReplyIcon from "@mui/icons-material/Reply";
import {CssTextField2} from "./CssTextField2";
import ImageUploading from "react-images-uploading";
import {ReactComponent as LogoUser} from "../images/svg/Login.svg";
import ErrorIcon from "@mui/icons-material/Error";
import Fragen from "./Fragen";
import Editor3 from "./Editor3";
import Vorschau from "./Vorschau";
import Button from "@mui/material/Button";
import {Select2} from "./Select2";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {ReactComponent as TrashIcon} from "../images/svg/mulleimer.svg";
import EditIcon from "@mui/icons-material/Edit";
import {CssTextField4} from "./CssTextField4";
import {confirmAlert} from "react-confirm-alert";
import {ReactComponent as TestLogo} from "../images/svg/swiitcher_icon_blau.svg";

const EditAddress = React.forwardRef((props, ref) => {
    const {
        type,
        index,
        id,
        createInvoiceAddress,
        updateInvoiceAddress,
        deleteInvoiceAddress,
        isDefault,
        setIsDefault,
        companyName,
        setCompanyName,
        companyNameError,
        setCompanyNameError,
        street,
        setStreet,
        streetError,
        setStreetError,
        nr,
        setNr,
        nrError,
        setNrError,
        zipcode,
        setZipcode,
        zipcodeError,
        setZipcodeError,
        city,
        setCity,
        cityError,
        setCityError,
        email,
        setEmail,
        emailError,
        setEmailError,
        ustId,
        setUstId,
        ustIdError,
        setUstIdError,
        newAddress,
        setNewAddress,
        setEditAddress
    } = props;

    const validateEmail = email => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
    };

    const validateInvoiceAddress = () => {
        let error = false;
        if (!companyName || companyName == '') {
            setCompanyNameError('Firmenname darf nicht leer sein');
            error = true;
        } else {
            setCompanyNameError(false);
        }
        if (!street || street == '') {
            setStreetError('Straße darf nicht leer sein');
            error = true;
        } else {
            setStreetError(false);
        }

        if (!nr || nr == '') {
            setNrError('Nr darf nicht leer sein');
            error = true;
        } else {
            setNrError(false);
        }
        if (!zipcode || zipcode == '') {
            setZipcodeError('PLZ darf nicht leer sein');
            error = true;
        } else {
            setZipcodeError(false);
        }
        if (!city || city == '') {
            setCityError('Ort darf nicht leer sein');
            error = true;
        } else {
            setCityError(false);
        }
        if (!ustId || ustId == '') {
            setUstIdError('UST-ID-Nummer darf nicht leer sein');
            error = true;
        } else {
            setUstIdError(false);
        }


        if (!email || email == '') {
            setEmailError('E-Mail darf nicht leer sein');
            error = true;
        } else {
            setEmailError(false);
        }

        if (email && !validateEmail(email)) {
            setEmailError('Keine gültige E-Mail');
            error = true;
        }
        return !error;
    }

    return (
        <div ref={ref} key={id} style={{
            border: '2px solid #C7D300',
            borderRadius: 25,
            // height: 200,
            marginTop: (index === 0 || id === -1) ? 0 : 15,
            marginBottom: 15,
            marginRight: 10,
            marginLeft: 25,
            // padding: '10px 15px 15px 15px',
            padding: '10px 5px 20px 15px',
            textAlign: 'left'
        }}>
            <Grid container>
                <Grid item xs={10} style={{paddingTop: 5}}>
                    <p style={{
                        margin: 0,
                        marginBottom: 5,
                        padding: 0,
                        textAlign: 'left',
                        fontFamily: 'VagRoundedBold',
                        fontSize: 14
                    }}
                    >Adresse {type === 'new' ? <>anlegen</> : <>bearbeiten</>}</p>
                    <FormGroup style={{padding: '0 10px'}}>
                        <FormControlLabel
                            onClick={(e) => {
                                e.preventDefault()
                                console.log('click')
                                setIsDefault(!isDefault)
                            }}
                            className={'defaultRadio'}
                            control={
                                <Radio
                                    checked={isDefault}
                                    sx={{
                                        p: '4px 9px',
                                        color: '#C7D300',
                                        '&.Mui-checked': {
                                            color: '#C7D300',
                                        },
                                    }}
                                />
                            }
                            label={'Als Standardadresse festlegen'}
                            sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={2} style={{textAlign: 'right', paddingRight: 4}}>
                    {(id && id !== -1) && <TrashIcon className={'not-yellow-white'}
                                                     onClick={() => {
                                                         deleteInvoiceAddress(id);
                                                     }}
                                                     style={{
                                                         height: 18,
                                                         width: 18,
                                                         marginRight: 2,
                                                         transition: 'all 0.2s ease-in-out',
                                                         textAlign: 'center',
                                                         cursor: 'pointer',
                                                         borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                     }}
                    />}
                    <ClearIcon
                        className={'not-yellow-white'}
                        onClick={() => {
                            setIsDefault(false);
                            setCompanyName('');
                            setCompanyNameError(false);
                            setStreet('');
                            setStreetError(false);
                            setNr('');
                            setNrError(false);
                            setZipcode('');
                            setZipcodeError(false);
                            setCity('');
                            setCityError(false);
                            setEmail('');
                            setEmailError(false);
                            setUstId('');
                            setUstIdError(false);
                            if (type === 'new') {
                                setNewAddress(false);
                                window.scrollTo(0, 0);
                            } else if (type === 'edit') {
                                setEditAddress(-1);
                            }
                        }}
                        style={{
                            height: 18,
                            width: 18,
                            marginRight: 2,
                            transition: 'all 0.2s ease-in-out',
                            textAlign: 'center',
                            cursor: 'pointer',
                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CssTextField4
                        label="Firmenname*"
                        fieldtype={'GrayTransparent'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setCompanyNameError(true);
                            } else {
                                setCompanyNameError(false);
                            }
                            setCompanyName(e.target.value);
                        }}
                        value={companyName}
                        error={!!companyNameError}
                        helperText={companyNameError}
                    />
                </Grid>
                <Grid item xs={10}>
                    <CssTextField4
                        label="Straße*"
                        fieldtype={'GrayTransparent'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setStreetError(true);
                            } else {
                                setStreetError(false);
                            }
                            setStreet(e.target.value);
                        }}
                        value={street}
                        error={!!streetError}
                        helperText={streetError}
                    />
                </Grid>
                <Grid item xs={2}>
                    <CssTextField4
                        label="Nr*"
                        fieldtype={'GrayTransparent'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setNrError(true);
                            } else {
                                setNrError(false);
                            }
                            setNr(e.target.value);
                        }}
                        value={nr}
                        error={!!nrError}
                        helperText={nrError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="PLZ*"
                        fieldtype={'GrayTransparent'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setZipcodeError(true);
                            } else {
                                setZipcodeError(false);
                            }
                            setZipcode(e.target.value);
                        }}
                        value={zipcode}
                        error={!!zipcodeError}
                        helperText={zipcodeError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="Ort / Sitz*"
                        fieldtype={'GrayTransparent'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setCityError(true);
                            } else {
                                setCityError(false);
                            }
                            setCity(e.target.value);
                        }}
                        value={city}
                        error={!!cityError}
                        helperText={cityError}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CssTextField4
                        label="UST-ID-Nummer*"
                        fieldtype={'GrayTransparent'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setUstIdError(true);
                            } else {
                                setUstIdError(false);
                            }
                            setUstId(e.target.value);
                        }}
                        value={ustId}
                        error={!!ustIdError}
                        helperText={ustIdError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="E-Mail*"
                        fieldtype={'GrayTransparent'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setEmailError(true);
                            } else {
                                setEmailError(false);
                            }
                            setEmail(e.target.value);
                        }}
                        value={email}
                        error={!!emailError}
                        helperText={emailError}
                    />
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right', marginTop: 7}}>
                    <CustomButton
                        buttontype={'yellowWhite'}
                        style={{padding: '1.7px 15px', marginRight: 10}}
                        onClick={() => {
                            if (type === 'new' && validateInvoiceAddress()) createInvoiceAddress();
                            else if (type === 'edit' && validateInvoiceAddress()) updateInvoiceAddress(id);
                        }}
                    >Speichern</CustomButton>
                </Grid>
            </Grid>
        </div>
    );
});

export default function Matchkorb(props) {

    const {locationChange, menuAppBarRef} = props;

    const location = useLocation();
    const itemsRef = useRef([]);
    const history = useHistory();
    const api = useFetch();

    const testRef = useRef(null);
    const steps = ['Matchkorb', 'Bezahlen', 'Kontaktieren'];
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [invoiceAddresses, setInvoiceAddresses] = React.useState([]);
    const newAddressRef = React.createRef();
    const loadingSpinnerRef = useRef(null)


    const [loading, setLoading] = useState(false);
    const [isDefault, setIsDefault] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [companyName, setCompanyName] = React.useState('');
    const [companyNameError, setCompanyNameError] = React.useState(false);
    const [street, setStreet] = React.useState('');
    const [streetError, setStreetError] = React.useState(false);
    const [nr, setNr] = React.useState('');
    const [nrError, setNrError] = React.useState(false);
    const [zipcode, setZipcode] = React.useState('');
    const [zipcodeError, setZipcodeError] = React.useState(false);
    const [city, setCity] = React.useState('');
    const [cityError, setCityError] = React.useState(false);
    const [telefonnummer, setTelefonnummer] = React.useState('');
    const [telefonnummerError, setTelefonnummerError] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [ustId, setUstId] = React.useState('');
    const [ustIdError, setUstIdError] = React.useState(false);


    const [editAddress, setEditAddress] = React.useState(-1);
    const [newAddress, setNewAddress] = React.useState(false);
    const [getRef, setGetRef] = React.useState(false);


    const [buttonHover, setButtonHover] = useState(null);
    const [activeMatchItem, setActiveMatchItem] = useState(null);
    const [favorite, setFavorite] = useState(null);


    const [onContact, setOnContact] = useState(false);
    const [onMatchkorb, setOnMatchkorb] = useState(false);
    const [confirmedMatches, setConfirmedMatches] = useState(false);
    const [currentRange, setCurrentRange] = useState(30);

    const [jobs, setJobs] = useState('');
    const [plz, setPlz] = useState('');
    const [test, setTest] = useState(false);
    const [rows, setRows] = useState([]);

    const getInvoiceAddresses = async () => {

        let rows = [];
        let moreData = true;
        let pageNumber = 1;

        for (let i = 1; i < pageNumber + 1; i++) {
            let url = ENV.REACT_APP_API_URL + '/invoice-addresses?page%5Bsize%5D=20&page%5Bnumber%5D=' + i;

            let {response, data} = await api(url)


            if (!response || response.status == 401) {
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                history.push('/arbeitgeber');
                return;
            }
            if (data && data.data) {
                data.data.map((item, index) => {
                    item.attributes.isDefault = (item.attributes.isDefault == 1) ? true : false;
                    if (rows.filter(i => i.id === item.id).length === 0) {
                        if (index === 0) setIsSelected(item.id);
                        rows.push(item);
                    } else {
                        rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])] = item;
                    }
                })

                if (data.meta.pagination.total === rows.length) {
                    moreData = false;
                }

            } else if (response.error) {
                notify('API Fehler: ' + response.status, true);
            }
        }

        rows.sort((a, b) => (a.attributes.isDefault == 1 ? -1 : 1));
        setInvoiceAddresses(rows);
    }

    const createInvoiceAddress = async () => {
        let updatedAttributes = {
            data: {
                attributes: {
                    isDefault: isDefault === true ? 1 : 0,
                    companyName: companyName,
                    street: street,
                    houseNumber: nr,
                    zipcode: zipcode,
                    city: city,
                    telephoneNumber: telefonnummer,
                    email,
                    ustId
                },
                relationships: {}
            }
        }

        let url = ENV.REACT_APP_API_URL + "/invoice-addresses";
        const {response, data} = await api(url, 'POST', JSON.stringify(updatedAttributes));

        if (response && response.status != '404' && data) {
            setIsSelected(data.data.id);
            setIsDefault(false);
            setCompanyName('');
            setCompanyNameError(false);
            setStreet('');
            setStreetError(false);
            setNr('');
            setNrError(false);
            setZipcode('');
            setZipcodeError(false);
            setCity('');
            setCityError(false);
            setTelefonnummer('');
            setTelefonnummerError(false);
            setEmail('');
            setEmailError(false);
            setUstId('');
            setUstIdError(false);
            setNewAddress(false);
            setEditAddress(-1);
            getInvoiceAddresses();
        } else if (response.status == '404') {

        }
    }

    const updateInvoiceAddress = async (id) => {
        let updatedAttributes = {
            data: {
                attributes: {
                    isDefault: isDefault === true ? 1 : 0,
                    companyName: companyName,
                    street: street,
                    houseNumber: nr,
                    zipcode: zipcode,
                    city: city,
                    telephoneNumber: telefonnummer,
                    email,
                    ustId
                },
                relationships: {}
            }
        }

        let url = ENV.REACT_APP_API_URL + "/invoice-address/" + id;
        const {response, data} = await api(url, 'PATCH', JSON.stringify(updatedAttributes));

        if (response && response.status != '404' && data) {
            setIsDefault(false);
            setCompanyName('');
            setCompanyNameError(false);
            setStreet('');
            setStreetError(false);
            setNr('');
            setNrError(false);
            setZipcode('');
            setZipcodeError(false);
            setCity('');
            setCityError(false);
            setTelefonnummer('');
            setTelefonnummerError(false);
            setEmail('');
            setEmailError(false);
            setUstId('');
            setUstIdError(false);
            setNewAddress(false);
            setEditAddress(-1);
            getInvoiceAddresses();
            notify('Deine Adresse wurde erfolgreich aktualisiert');
        } else if (response.status == '404') {

        }
    }

    const deleteInvoiceAddress = (id) => {
        console.log('DELETE')
        confirmAlert({
            title: 'Bestätigung',
            message: 'Möchtest Du diese Adresse wirklich löschen?',
            buttons: [
                {
                    label: 'Ja',
                    onClick: async () => {
                        console.log(id);
                        let url = ENV.REACT_APP_API_URL + '/invoice-address/' + id;
                        const {response, data} = await api(url, 'DELETE');
                        if (response && response.status == 204) {
                            setIsDefault(false);
                            setCompanyName('');
                            setCompanyNameError(false);
                            setStreet('');
                            setStreetError(false);
                            setNr('');
                            setNrError(false);
                            setZipcode('');
                            setZipcodeError(false);
                            setCity('');
                            setCityError(false);
                            setTelefonnummer('');
                            setTelefonnummerError(false);
                            setEmail('');
                            setEmailError(false);
                            setUstId('');
                            setUstIdError(false);
                            setNewAddress(false);
                            setEditAddress(-1);
                            getInvoiceAddresses();

                            notify('Deine Adresse wurde erfolgreich gelöscht');
                        } else if (response && response.status == 404) {

                        }
                    }
                },
                {
                    label: 'Nein',
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        });
    }

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        if (activeStep == 1 && isSelected) {
            completeOrder();
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        setSkipped(newSkipped);
    };

    const completeOrder = async () => {
        let attributes = {
            data: {
                attributes: {
                    matches: confirmedMatches,
                    selectedAddress: isSelected
                }
            }
        }
        let url = ENV.REACT_APP_API_URL + "/matchings/complete-order";
        const {response, data} = await api(url, 'POST', JSON.stringify(attributes));
        console.log(data);

        if (response && response.status != '404' && data && data.data && data.data.id) {
            setIsSelected(data.data.id);
        } else if (response.status == '404') {

        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const onContactStyle = {
        position: 'absolute',
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '20%',
        minWidth: 227,
        maxHeight: '70%',
        overflow: 'auto',
        backgroundColor: 'rgba(0, 55, 78, 0.95)',
        border: '2px solid #C7D300',
        borderRadius: 5,
        padding: '5px 32px 20px',
        boxShadow: 24,
        zIndex: 4,
        lineHeight: '13px',
    };

    const onMatchkorbStyle = {
        // width: '20%',
        textAlign: 'center',
        margin: 'auto',
        minWidth: 200,
        maxWidth: 200,
        maxHeight: '70%',
        overflow: 'auto',
        // backgroundColor: 'rgba(255, 255, 255, 0.95)',
        border: '2px solid #C7D300',
        borderRadius: 5,
        padding: '0 16px 10px',
        boxShadow: 24,
        zIndex: 4,
        lineHeight: '13px',
    };

    function currencyFormat(num) {
        if (num) {
            return num.toFixed(2).toString().replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        }
        return false;
    }

    const items = [
        {
            title: 'Produktmanager (m/w/d) in Vollzeit',
            content: [
                {text: 'Swiitcher 111', percent: 80, type: 'Student', timestamp: 'heute'},
                {text: 'Swiitcher 112', percent: 70, type: 'Arbeitnehmer', timestamp: 'vor 1 Tag'},
                {text: 'Swiitcher 113', percent: 60, type: 'Arbeitnehmer', timestamp: 'vor 5 Tag'}
            ]
        },
        {
            title: 'Job Nummer Zwei (m/w/d) in Teilzeit',
            content: [
                {text: 'Swiitcher 114', percent: 80, type: 'Arbeitnehmer', timestamp: 'heute'},
                {text: 'Swiitcher 115', percent: 70, type: 'Student', timestamp: 'vor 1 Tag'},
                {text: 'Swiitcher 116', percent: 60, type: 'Arbeitnehmer', timestamp: 'vor 5 Tag'}
            ]
        }, {
            title: 'Dritte Stellenanzeige (m/w/d) in Vollzeit',
            content: [
                {text: 'Swiitcher 117', percent: 80, type: 'Arbeitnehmer', timestamp: 'heute'},
                {text: 'Swiitcher 118', percent: 70, type: 'Arbeitnehmer', timestamp: 'vor 1 Tag'},
                {text: 'Swiitcher 119', percent: 60, type: 'Arbeitnehmer', timestamp: 'vor 5 Tag'}
            ]
        },
    ];

    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, items.length);
    }, [items]);

    useEffect(() => {
        if (ReactSession.get('user') != 'Arbeitgeber')  {
            menuAppBarRef.current.logNeeded('Arbeitgeber');
            history.push('/');
        }
        else {
            document.title = 'Matches';
            locationChange(location);
            getData();
            getInvoiceAddresses();
        }
    }, [])

    useEffect(() => {
        if (newAddressRef.current && getRef && invoiceAddresses && invoiceAddresses.length > 1) {
            setGetRef(false);
            const yOffset = 0;
            const y = (newAddressRef.current.getBoundingClientRect().bottom - newAddressRef.current.getBoundingClientRect().y) + yOffset;

            newAddressRef.current.scrollIntoView({top: y, block: 'nearest', behavior: 'smooth'});
        } else {
        }
    }, [newAddressRef])

    const getData = async () => {
        // let url = ENV.REACT_APP_API_URL + '/job-advertisements?include=matchings';
        let url = ENV.REACT_APP_API_URL + '/matchings?filter%5Btype%5D%5Beq%5D=confirmed';

        let {response, data} = await api(url)

        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/arbeitgeber');
            return;
        }

        let confirmedMatches = [];
        if (data && data.data) {
            data.data.map((item, index) => {
                confirmedMatches.push(item);
            })
            console.log(data);

            setConfirmedMatches(confirmedMatches);
        } else if (response.error) {
            //notify('API Fehler: ' + response.status, true);
        }
    }

    const updateMatch = async (item) => {
        console.log(item);
        let url = ENV.REACT_APP_API_URL + '/matching/' + item.id;


        const {response, data} = await api(url, 'PATCH', JSON.stringify({
            data: {
                attributes: {
                    matchingArbeitgeber: 1,
                    matchingArbeitgeberUnread: 0
                }
            }
        }));


        if (response && response.status == 200) {
            getData();
            setOnContact(false);
            notify('Deine Anfrage wurde erfolgreich versendet');
        } else {
            notify('API Fehler: ' + response.status, true);
        }
    }

    const notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    useEffect(() => {
        setUnread();
    }, [activeMatchItem])

    const setUnread = async () => {
        if (activeMatchItem && activeMatchItem.attributes.matchingArbeitgeberUnread) {
            let url = ENV.REACT_APP_API_URL + '/matching/' + activeMatchItem.id;


            const {response, data} = await api(url, 'PATCH', JSON.stringify({
                data: {
                    attributes: {
                        matchingArbeitgeberUnread: 0
                    }
                }
            }));

            console.log(data);

            if (response && response.status == 200) {
                //getData();
            } else {
                notify('API Fehler: ' + response.status, true);
            }
        }
    }

    return (
        <Box sx={{width: '100%', paddingTop: '100px', minHeight: '100vh', backgroundColor: '#00374e'}}>
            <h2 style={{
                fontSize: 20,
                color: 'white',
                textAlign: 'left',
                lineHeight: '18px',
                fontFamily: 'VagRoundedBold',
                position: 'relative',
                marginLeft: 100
            }}>
                Kaufübersicht
            </h2>
            <Stepper id={'stepperMatchkorb'} activeStep={activeStep} style={{maxWidth: 600, margin: 'auto'}}>
                {steps.map((label, index) => {
                    const stepProps = {color: 'white'};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps} className={activeStep === index ? 'activeStep' : ''}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <div style={{height: 405}}>
                        <Typography sx={{mt: 2, mb: 1}} style={{color: 'white', paddingTop: 200}}>
                            Stellenanzeige erstellen
                        </Typography>

                        <Typography sx={{mt: 2, mb: 1}} style={{color: 'white'}}>
                            Zurück zur Startseite
                        </Typography>
                    </div>
                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                        <Box sx={{flex: '1 1 auto'}}/>
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div style={{display: 'flex', alignItems: 'flex-start'}}>
                        {steps.map((item, index) => {

                            return (
                                <React.Fragment>
                                    <div style={{
                                        display: 'block',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        // overflow: 'hidden',
                                        transition: 'all 0.3s ease-in-out',
                                        opacity: (activeStep === index) ? 1 : 0,
                                        width: (activeStep === index) ? 'calc(100vw)' : 0,
                                        minHeight: (activeStep === index) ? 'calc(100vh - 350px)' : 0,
                                        height: (activeStep === index) ? 'unset' : 0,
                                        marginTop: 75,
                                        color: 'white'
                                    }}>
                                        {index == 0 && <>
                                            {!confirmedMatches ? <><div ref={loadingSpinnerRef}
                                                                        style={{
                                                                            color: 'white',
                                                                            paddingTop: 100,
                                                                            paddingLeft: 0,
                                                                            paddingRight: 100,
                                                                            paddingBottom: 100,
                                                                            width: '100%',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            flexDirection: 'column'
                                                                        }}>
                                                <div id={'testLogo'} style={{
                                                    borderRadius: 50,
                                                    width: 60,
                                                    height: 60,
                                                    minWidth: 60,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    border: '4px solid transparent',
                                                    transitionDuration: '0.2s',
                                                    transitionProperty: 'transform',
                                                    margin: '0 auto'
                                                }}
                                                >
                                                    <div style={{
                                                        padding: 7.5,
                                                        background: 'rgba(0, 55, 78, 1)',
                                                        borderRadius: 50,
                                                        width: 57,
                                                        minWidth: 27,
                                                        height: 35,
                                                        minHeight: 35,
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        border: '5px solid rgba(199, 211, 0, 1)'
                                                    }}>
                                                        <TestLogo
                                                            style={{
                                                                width: 30,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div></> :
                                            <Box sx={onMatchkorbStyle} style={{
                                                color: 'white',
                                                display: (activeStep === index) ? 'block' : 'none',
                                            }}>
                                                <h3 style={{
                                                    fontSize: 20,
                                                    textAlign: 'left',
                                                    lineHeight: '18px',
                                                    fontFamily: 'VagRoundedBold',
                                                    position: 'relative'
                                                }}>
                                                    Matchkorb
                                                    <Tooltip enterTouchDelay={0} placement="bottom"
                                                             title={'Diese Jobsuchenden haben deine Anfrage bestätigt! Schließe jetzt den Bestellvorgang ab und trete mit ihnen in Kontakt'}>
                                                        <InfoIcon
                                                            style={{marginLeft: 0, color: '#00374e'}}
                                                            fontSize={'14px'}
                                                            id={'infoIcon'}
                                                        />
                                                    </Tooltip>
                                                    <div
                                                        id={'matchkorb'}
                                                        style={{
                                                            position: 'absolute',
                                                            right: 0,
                                                            top: -10
                                                        }}
                                                    >
                                                        <Einkaufswagen
                                                            style={{
                                                                width: 40,
                                                                color: 'white',
                                                            }}
                                                        />
                                                        {(confirmedMatches && confirmedMatches.length > 0) &&
                                                            <div className={'count'} style={{
                                                                background: '#C7D300',
                                                                filter: 'unset',
                                                                width: 6,
                                                                minWidth: 6,
                                                                height: 6,
                                                                fontSize: 10,
                                                                top: -2,
                                                                right: -7,
                                                                color: 'white',
                                                                padding: '4px',
                                                                fontFamily: 'VagRoundedBold'
                                                            }}>{confirmedMatches.length}
                                                            </div>}
                                                    </div>
                                                </h3>
                                                <Grid container style={{margin: '40px 0 30px', fontSize: 14}}>
                                                    {confirmedMatches.map((item, index) => {
                                                        return (
                                                            <>
                                                                <Grid item xs={8} style={{textAlign: 'left'}}>
                                                                    swiitcher {item.attributes.arbeitnehmer_id}
                                                                </Grid>
                                                                <Grid item xs={4} style={{textAlign: 'right'}}>
                                                                    99,00
                                                                </Grid>
                                                            </>
                                                        );
                                                    })}
                                                </Grid>
                                                <Grid container style={{margin: '10px 0', fontSize: 12}}>
                                                    <Grid item xs={8} style={{textAlign: 'left'}}>
                                                        Summe Netto
                                                    </Grid>
                                                    <Grid item xs={4} style={{textAlign: 'right'}}>
                                                        {currencyFormat(confirmedMatches.length * 99.00)}
                                                    </Grid>
                                                </Grid>

                                                <Grid container style={{
                                                    margin: '10px 0',
                                                    paddingBottom: 2,
                                                    borderBottom: '1px solid #C7D300',
                                                    fontSize: 12
                                                }}>
                                                    <Grid item xs={8} style={{textAlign: 'left'}}>
                                                        zzgl. Mwst.
                                                    </Grid>
                                                    <Grid item xs={4} style={{textAlign: 'right'}}>
                                                        {currencyFormat((confirmedMatches.length * 99.00) * 0.19)}
                                                    </Grid>
                                                </Grid>

                                                <Grid container style={{
                                                    margin: '10px 0',
                                                    paddingBottom: 2,
                                                    borderBottom: '2px solid #C7D300',
                                                    fontFamily: 'VagRoundedBold',
                                                    fontSize: 12
                                                }}>
                                                    <Grid item xs={8} style={{textAlign: 'left'}}>
                                                        Gesamt Brutto in Euro
                                                    </Grid>
                                                    <Grid item xs={4} style={{textAlign: 'right'}}>
                                                        {currencyFormat((confirmedMatches.length * 99.00) * 1.19)}
                                                    </Grid>
                                                </Grid>
                                            </Box>}
                                        </>}
                                        {index == 1 && <>
                                            <Grid container
                                                  style={{maxWidth: '80%', textAlign: 'left', margin: 'auto', height: index == 1 ? 'unset' : 0}}
                                                  className={'newAddressDiv'}>
                                                <Grid item xs={4} style={{marginBottom: 20}}>Rechnung per</Grid>
                                                <Grid item xs={6}>
                                                    <FormGroup style={{padding: '0 10px'}}>
                                                        <FormControlLabel
                                                            className={'emailRadio'}
                                                            control={
                                                                <Radio
                                                                    sx={{
                                                                        p: '4px 9px',
                                                                        color: '#C7D300',
                                                                        '&.Mui-checked': {
                                                                            color: '#C7D300',
                                                                        },
                                                                    }}
                                                                    checked={true}
                                                                />
                                                            }
                                                            label={'E-Mail'}
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={2}></Grid>

                                                <Grid item xs={4}>Rechnung an</Grid>
                                                <Grid item xs={6} className={'overscroll-none'}
                                                      style={{
                                                          // maxHeight: 300,
                                                          // overflowY: 'auto',
                                                          paddingRight: 10,
                                                }}>
                                                    {invoiceAddresses && invoiceAddresses.map((item, index) => {
                                                        if (editAddress === index) {
                                                            return (
                                                                <EditAddress
                                                                    type={'edit'}
                                                                    index={index}
                                                                    id={item.id}
                                                                    isDefault={isDefault}
                                                                    setIsDefault={setIsDefault}
                                                                    createInvoiceAddress={createInvoiceAddress}
                                                                    updateInvoiceAddress={updateInvoiceAddress}
                                                                    deleteInvoiceAddress={deleteInvoiceAddress}
                                                                    companyName={companyName}
                                                                    setCompanyName={setCompanyName}
                                                                    companyNameError={companyNameError}
                                                                    setCompanyNameError={setCompanyNameError}
                                                                    street={street}
                                                                    setStreet={setStreet}
                                                                    streetError={streetError}
                                                                    setStreetError={setStreetError}
                                                                    nr={nr}
                                                                    setNr={setNr}
                                                                    nrError={nrError}
                                                                    setNrError={setNrError}
                                                                    zipcode={zipcode}
                                                                    setZipcode={setZipcode}
                                                                    zipcodeError={zipcodeError}
                                                                    setZipcodeError={setZipcodeError}
                                                                    city={city}
                                                                    setCity={setCity}
                                                                    cityError={cityError}
                                                                    setCityError={setCityError}
                                                                    email={email}
                                                                    setEmail={setEmail}
                                                                    emailError={emailError}
                                                                    setEmailError={setEmailError}
                                                                    ustId={ustId}
                                                                    setUstId={setUstId}
                                                                    ustIdError={ustIdError}
                                                                    setUstIdError={setUstIdError}
                                                                    newAddress={newAddress}
                                                                    setNewAddress={setNewAddress}
                                                                    setEditAddress={setEditAddress}
                                                                />
                                                            );
                                                        }


                                                        if (editAddress !== index) {
                                                            return (
                                                                <>
                                                                    <FormGroup className={'testC'}
                                                                               style={{padding: '0 10px'}}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Radio
                                                                                    sx={{
                                                                                        p: '4px 9px',
                                                                                        color: '#C7D300',
                                                                                        '&.Mui-checked': {
                                                                                            color: '#C7D300',
                                                                                        },
                                                                                    }}
                                                                                    checked={isSelected === item.id}
                                                                                />
                                                                            }
                                                                            onClick={() => {
                                                                                console.log('clicked')
                                                                                setIsSelected(item.id);
                                                                            }}
                                                                            label={<div key={index} style={{
                                                                                background: 'rgba(238,238,238,0.2)',
                                                                                border: '2px solid transparent',
                                                                                borderRadius: 25,
                                                                                // margin: index === 0 ? '35px 0 0' : '15px 0 0',
                                                                                margin: '0 0 0 15px',
                                                                                padding: '10px 15px 20px 25px',
                                                                                textAlign: 'left',
                                                                                fontSize: 12,
                                                                            }}>
                                                                                <Grid container>
                                                                                    <Grid item xs={8}>

                                                                                        <Grid container>
                                                                                            <Grid item xs={12}
                                                                                                  style={{paddingTop: 10, lineBreak: 'anywhere'}}>
                                                                                                <p style={{margin: 0}}>{item.attributes.companyName}</p>
                                                                                                <p style={{margin: 0}}>{item.attributes.street}&nbsp;{item.attributes.houseNumber}</p>
                                                                                                <p style={{margin: 0}}>{item.attributes.zipcode}&nbsp;{item.attributes.city}</p>
                                                                                                <p style={{margin: 0}}>{item.attributes.telephoneNumber}</p>
                                                                                                <p style={{margin: 0}}>{item.attributes.email}</p>
                                                                                                <p style={{margin: 0}}>{item.attributes.ustId}</p>
                                                                                            </Grid>
                                                                                        </Grid>


                                                                                    </Grid>
                                                                                    <Grid item xs={4}
                                                                                          style={{textAlign: 'right'}}>
                                                                                        <TrashIcon
                                                                                            className={'not-yellow-white'}
                                                                                            onClick={() => {
                                                                                                deleteInvoiceAddress(item.id);
                                                                                            }}
                                                                                            style={{
                                                                                                height: 18,
                                                                                                width: 18,
                                                                                                marginRight: 2,
                                                                                                transition: 'all 0.2s ease-in-out',
                                                                                                textAlign: 'center',
                                                                                                cursor: 'pointer',
                                                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                                            }}
                                                                                        />
                                                                                        <EditIcon
                                                                                            className={'not-yellow-white'}
                                                                                            style={{
                                                                                                height: 18,
                                                                                                width: 18,
                                                                                                // marginRight: 2,
                                                                                                transition: 'all 0.2s ease-in-out',
                                                                                                textAlign: 'center',
                                                                                                cursor: 'pointer',
                                                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                console.log('EDIT')
                                                                                                setIsDefault(item.attributes.isDefault);
                                                                                                setCompanyName(item.attributes.companyName);
                                                                                                setCompanyNameError(false);
                                                                                                setStreet(item.attributes.street);
                                                                                                setStreetError(false);
                                                                                                setNr(item.attributes.houseNumber);
                                                                                                setNrError(false);
                                                                                                setZipcode(item.attributes.zipcode);
                                                                                                setZipcodeError(false);
                                                                                                setCity(item.attributes.city);
                                                                                                setCityError(false);
                                                                                                setEmail(item.attributes.email);
                                                                                                setEmailError(false);
                                                                                                setUstId(item.attributes.ustId);
                                                                                                setUstIdError(false);
                                                                                                setNewAddress(false);
                                                                                                setEditAddress(index);
                                                                                            }}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </div>}
                                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}
                                                                        />
                                                                    </FormGroup>

                                                                </>
                                                            );
                                                        }
                                                    })}
                                                    <div style={{
                                                        margin: '10px 10px 20px 25px',
                                                        textAlign: 'left',
                                                        fontFamily: 'VagRoundedBold',
                                                        fontSize: 14,
                                                        position: 'relative',
                                                        paddingTop: 5,
                                                        paddingBottom: 5,
                                                        paddingLeft: 20,
                                                        width: 'auto',
                                                        background: 'rgba(238,238,238,0.2)',
                                                        // border: '2px solid #C7D300',
                                                        borderRadius: 100,
                                                        color: 'white',
                                                    }}
                                                         className={'color-blue'}>
                                                        Neue Adresse anlegen
                                                        <div style={{
                                                            display: 'flex',
                                                            position: 'absolute',
                                                            top: 6,
                                                            right: 0,
                                                            paddingRight: 15,
                                                            zIndex: 1
                                                        }}>
                                                            <ClearIcon
                                                                className={'not-yellow-white'}
                                                                onClick={() => {
                                                                    setGetRef(true);
                                                                    setNewAddress(true);
                                                                }}
                                                                style={{
                                                                    height: 18,
                                                                    width: 18,
                                                                    marginRight: 2,
                                                                    transition: 'all 0.2s ease-in-out',
                                                                    textAlign: 'center',
                                                                    fill: 'white',
                                                                    cursor: 'pointer',
                                                                    transform: 'rotate(45deg)',
                                                                    borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {newAddress && <EditAddress
                                                        ref={newAddressRef}
                                                        type={'new'}
                                                        id={-1}
                                                        isDefault={isDefault}
                                                        setIsDefault={setIsDefault}
                                                        createInvoiceAddress={createInvoiceAddress}
                                                        companyName={companyName}
                                                        setCompanyName={setCompanyName}
                                                        companyNameError={companyNameError}
                                                        setCompanyNameError={setCompanyNameError}
                                                        street={street}
                                                        setStreet={setStreet}
                                                        streetError={streetError}
                                                        setStreetError={setStreetError}
                                                        nr={nr}
                                                        setNr={setNr}
                                                        nrError={nrError}
                                                        setNrError={setNrError}
                                                        zipcode={zipcode}
                                                        setZipcode={setZipcode}
                                                        zipcodeError={zipcodeError}
                                                        setZipcodeError={setZipcodeError}
                                                        city={city}
                                                        setCity={setCity}
                                                        cityError={cityError}
                                                        setCityError={setCityError}
                                                        telefonnummer={telefonnummer}
                                                        setTelefonnummer={setTelefonnummer}
                                                        telefonnummerError={telefonnummerError}
                                                        setTelefonnummerError={setTelefonnummerError}
                                                        email={email}
                                                        setEmail={setEmail}
                                                        emailError={emailError}
                                                        setEmailError={setEmailError}
                                                        ustId={ustId}
                                                        setUstId={setUstId}
                                                        ustIdError={ustIdError}
                                                        setUstIdError={setUstIdError}
                                                        newAddress={newAddress}
                                                        setNewAddress={setNewAddress}
                                                    />}
                                                </Grid>

                                            </Grid>
                                        </>}
                                        {index == 2 && <>

                                            {loading ? <div ref={loadingSpinnerRef}

                                                         style={{
                                                             //display: 'flex',
                                                             //justifyContent: 'center',
                                                             //alignItems: 'center',
                                                             color: 'white',
                                                             paddingTop: 100,
                                                             paddingRight: 100,
                                                             paddingBottom: 100,
                                                             width: '100%',
                                                             display: 'flex',
                                                             justifyContent: 'center',
                                                             flexDirection: 'column'
                                                         }}>
                                                <p>Kauf wird abgeschlossen ...</p>
                                                <div id={'testLogo'} style={{
                                                    borderRadius: 50,
                                                    width: 60,
                                                    height: 60,
                                                    minWidth: 60,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    border: '4px solid transparent',
                                                    // cursor: 'pointer',
                                                    transitionDuration: '0.2s',
                                                    transitionProperty: 'transform',
                                                    margin: '0 auto'
                                                }}
                                                >
                                                    <div style={{
                                                        padding: 7.5,
                                                        background: 'rgba(0, 55, 78, 1)',
                                                        borderRadius: 50,
                                                        width: 57,
                                                        minWidth: 27,
                                                        height: 35,
                                                        minHeight: 35,
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        border: '5px solid rgba(199, 211, 0, 1)'
                                                    }}>
                                                        <TestLogo
                                                            style={{
                                                                width: 30,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div> : <div style={{height: index == 2 ? 'unset' : 0}}>
                                                <h3 style={{fontFamily: 'VagRoundedBold', fontSize: 24}}>Vielen Dank!</h3>
                                                <p style={{fontSize: 12}}>Wir senden Dir unverzüglich eine Rechnung via Mail zu.</p>
                                                <p style={{fontSize: 12}}>Diese kannst Du auch in deinem Arbeitgeberprofil unter "Rechnungen" einsehen.</p>
                                                <CustomButton
                                                    buttontype={'yellowWhite2'}
                                                    onClick={() => {
                                                        history.push('/chat')
                                                    }}
                                                    style={{
                                                        borderRadius: 20,
                                                        marginBottom: 5
                                                    }}
                                                >JETZT BEWERBER KENNENLERNEN</CustomButton>
                                                <br/>
                                                <CustomButton
                                                    buttontype={'gray'}
                                                    onClick={() => {
                                                        history.push('/matches-arbeitgeber')
                                                    }}
                                                    style={{
                                                        fontSize: 10,
                                                        borderRadius: 20
                                                    }}
                                                >ZURÜCK ZUR MATCHÜBERSICHT</CustomButton>
                                            </div>}


                                        </>}
                                        {index == 3 && <>4</>}
                                    </div>
                                </React.Fragment>);
                        })}
                    </div>
                    {activeStep > 1 ? null :
                        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}} style={{padding: '0 50px 50px 50px'}}>
                            <CustomButton
                                buttontype={'yellowWhiteDisabled2'}
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{mr: 1}}
                                style={{
                                    borderRadius: 20
                                }}
                            >
                                <ReplyIcon
                                    style={{
                                        marginBottom: 3,
                                        width: 30,
                                        height: 30,
                                        paddingRight: 3
                                    }}
                                />
                                Zurück
                            </CustomButton>
                            <Box sx={{flex: '1 1 auto'}}/>
                            <CustomButton
                                buttontype={'yellowWhiteDisabled2'}
                                disabled={activeStep === steps.length - 1 || (!confirmedMatches || confirmedMatches.length === 0) || (activeStep == 1 && !isSelected)}
                                onClick={handleNext}
                                sx={{mr: 1}}
                                style={{
                                    borderRadius: 20
                                }}
                            >
                                {activeStep < 1 ? 'Nächster Schritt' : 'Bezahlen'}
                                <ReplyIcon
                                    style={{
                                        transform: 'scaleX(-1)',
                                        marginBottom: 3,
                                        width: 30,
                                        height: 30,
                                        paddingRight: 3
                                    }}
                                />
                            </CustomButton>
                        </Box>}
                </React.Fragment>
            )}
        </Box>
    );
}

