import {ReactSession} from "react-client-session";


let UseFetch = () => {
    let originalRequest = async (url, config, method) => {
        let response;
        try{
            response = await fetch(url, config)
        } catch(err)  {
            response = {
                status: err.message,
                error: true
            }
        }
        let data;

        if (method == 'DELETE') {
            data = null;
        } else if (response && !response.error) {
            data = await response.json()
        }

        return {response, data}
    }

    return async (url, method = 'GET', body = null, formData = false) => {
        let token = ReactSession.get('token');
        let headers = new Headers();
        let config;

        if (!formData) {
            headers.append('Content-Type', 'application/vnd.api+json');
        } else {
            // headers.append('Content-Type', 'multipart/form-data');
        }

        if (!url.includes('/user/login')) {
            headers.append('Authorization', `Bearer ${token}`);
        }
        config = {
            method: method,
            headers: headers
        }

        if (body) {
            config.body = body;
        }

        let {response, data} = await originalRequest(url, config, method);

        if (response.status == 401 && !url.includes('answered-questions')) {
            const refreshToken = ReactSession.get('refreshToken');
            const response2 = await fetch(ENV.REACT_APP_API_URL + '/user/refresh-token', {
                headers: headers,
                method: 'POST',
                body: JSON.stringify({refreshToken})
            });
            if (response2) {
                const data2 = await response2.json();
                if (response2.status == 200) {
                    token = data2.data.token;
                    ReactSession.set('token', token);

                    let headers = new Headers();
                    headers.append('Content-Type', 'application/vnd.api+json');
                    if (!url.includes('/user/login')) {
                        headers.append('Authorization', `Bearer ${token}`);
                    }

                    config = {
                        method: method,
                        headers: headers
                    }

                    if (body) {
                        config.body = body;
                    }

                    return await originalRequest(url, config, method);
                } else {
                    return {response: response2, data: data2}
                }
            }
            return {response, data}
        }
        return {response, data}
    }
}

export default UseFetch;
