import React, {useEffect, useRef, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import arbeitgeber from '../images/png/Arbeitgeber.png';
import switcherFigur from '../images/svg/swiitcher_figur.svg';
import {
    Checkbox,
    FormControl,
    FormControlLabel, FormGroup,
    FormLabel,
    Grid,
    IconButton, InputAdornment, InputLabel,
    Link, MenuItem,
    Radio,
    RadioGroup
} from "@mui/material";
import {useHistory, useLocation} from "react-router";
import {CssTextField3} from "./CssTextField3";
import useFetch from "./UseFetch";
import {toast} from "react-toastify";
import {Select2} from "./Select2";
import {CustomButton} from "./CustomButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export default function Registrierung(props) {

    const _isMounted = useRef(false);

    const {loginRef, locationChange} = props;
    const location = useLocation();
    const history = useHistory();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        disableScrollLock: true,
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                minHeight: 'unset',
            },
        },
    };
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword1(!showPassword1);
        setShowPassword2(!showPassword2);
    }

    const [mail, setMail] = React.useState('');
    const [mailError, setMailError] = React.useState(false);
    const [company, setCompany] = React.useState('');
    const [companyError, setCompanyError] = React.useState(false);
    // const [username, setUsername] = React.useState('');
    // const [usernameError, setUsernameError] = React.useState(false);
    const [accountType, setAccountType] = React.useState('Arbeitgeber');
    const [password, setPassword] = React.useState('');
    const [passwordVerify, setPasswordVerify] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordVerifyError, setPasswordVerifyError] = React.useState(false);


    const notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    useEffect(() => {
        _isMounted.current = true;
        document.title = 'Registrierung';
        locationChange(location);
        

        return () => {
            _isMounted.current = false;
        };
    }, []);

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            loginRef.current.reg(mail, accountType, accountType !== 'Arbeitgeber' ? null : company, password, passwordVerify).then((e) => {


                if (e.success) {
                    if (accountType) history.push('/arbeitgeber');
                    else history.push('/');
                } else {
                    setMailError(e.mError);
                    setCompanyError(e.coError);
                    setPasswordError(e.pError);
                    setPasswordVerifyError(e.pVError);
                }
            })
        }
    };

    return (<div>
        
             <div style={{padding: '15px 15px 15px 15px', maxWidth: 400, margin: '75px auto'}} className={'registrationMenu'}>
                 <p style={{fontFamily: 'VagRoundedBold', fontSize: 36, paddingBottom: 20}} className={'color-blue'}>Registrierung</p>

                 <CssTextField3
                     label="E-Mail*"
                     InputLabelProps={{
                         shrink: true
                     }}
                     style={{
                         transition: 'all 0.2s ease',
                         margin: '10px 5px 10px 0',
                         minHeight: 60,
                         width: 'calc(100% - 5px)'
                     }}
                     autoComplete={'off'}
                     onChange={e => {
                         if (e.target.value.trim().length === 0) {
                             setMailError(true);
                         } else {
                             setMailError(false);
                         }
                         setMail(e.target.value);
                     }}
                     className={'text-input-label-blue'}
                     onKeyPress={handleKeypress}
                     value={mail}
                     error={!!mailError}
                     helperText={mailError === true ? 'E-Mail darf nicht leer sein!' : mailError}
                 />
                 <FormControl sx={{width: '100%', minHeight: 60, marginBottom: '10px'}} className={'testClassSelect blue'}>
                     <InputLabel shrink style={{color: '#00374e', paddingBottom: 5}}>Typ*</InputLabel>
                     <Select2
                         value={accountType}
                         onChange={(e) => setAccountType(e.target.value)}
                         defaultValue={'Arbeitgeber'}
                         label="Public"
                         fullWidth
                         style={{width: 'calc(100% - 5px)', marginBottom: 10}}
                         MenuProps={MenuProps}
                         input={<Select2 />}
                     >
                         <MenuItem value={'Arbeitgeber'}>Arbeitgeber</MenuItem>
                         <MenuItem value={'Arbeitnehmer'}>Arbeitnehmer</MenuItem>
                     </Select2>
                 </FormControl>
                 {accountType == 'Arbeitgeber' ?
                     <CssTextField3
                         label="Firmenname*"
                         InputLabelProps={{
                             shrink: true
                         }}
                         style={{
                             transition: 'all 0.2s ease',
                             margin: '10px 5px 0 0',
                             minHeight: 60,
                             width: 'calc(100% - 5px)'
                         }}
                         autoComplete={'off'}
                         onChange={e => {
                             if (e.target.value.trim().length === 0) {
                                 setCompanyError(true);
                             } else {
                                 setCompanyError(false);
                             }
                             setCompany(e.target.value);
                         }}
                         className={'text-input-label-blue'}
                         onKeyPress={handleKeypress}
                         value={company}
                         error={!!companyError}
                         helperText={companyError === true ? 'CompanyName darf nicht leer sein!' : companyError}
                     />
                 : null}
                 <CssTextField3
                     label="Passwort*"
                     InputLabelProps={{
                         shrink: true
                     }}
                     type={showPassword1 ? "text" : "password"}
                     InputProps={{ // <-- This is where the toggle button is added.
                         endAdornment: (
                             <InputAdornment position="end">
                                 <IconButton
                                     aria-label="toggle password visibility"
                                     onClick={handleClickShowPassword}
                                 >
                                     {showPassword1 ? <Visibility style={{color: '#C7D300'}}/> :
                                         <VisibilityOff style={{color: '#C7D300'}}/>}
                                 </IconButton>
                             </InputAdornment>
                         ),
                         autocomplete: 'new-password',
                         form: {
                             autocomplete: 'off',
                         },
                     }}
                     style={{
                         transition: 'all 0.2s ease',
                         margin: '20px 5px 10px 0',
                         minHeight: 60,
                         width: 'calc(100% - 5px)'
                     }}
                     onChange={e => {
                         if (e.target.value.trim().length === 0) {
                             setPasswordError(true);
                         } else {
                             setPasswordError(false);
                         }
                         setPassword(e.target.value);
                     }}
                     className={'text-input-label-blue'}
                     onKeyPress={handleKeypress}
                     value={password}
                     error={!!passwordError}
                     helperText={passwordError === true ? 'Passwort darf nicht leer sein!' : passwordError}
                 />
                 <CssTextField3
                     label="Passwort wiederholen*"
                     InputLabelProps={{
                         shrink: true
                     }}
                     type={showPassword1 ? "text" : "password"}
                     InputProps={{ // <-- This is where the toggle button is added.
                         endAdornment: (
                             <InputAdornment position="end">
                                 <IconButton
                                     aria-label="toggle password visibility"
                                     onClick={handleClickShowPassword}
                                 >
                                     {showPassword1 ? <Visibility style={{color: '#C7D300'}}/> :
                                         <VisibilityOff style={{color: '#C7D300'}}/>}
                                 </IconButton>
                             </InputAdornment>
                         ),
                         autocomplete: 'new-password',
                         form: {
                             autocomplete: 'off',
                         },
                     }}
                     style={{
                         transition: 'all 0.2s ease',
                         margin: '10px 5px 10px 0',
                         minHeight: 60,
                         width: 'calc(100% - 5px)'
                     }}
                     onChange={e => {
                         if (e.target.value.trim().length === 0) {
                             setPasswordVerifyError(true);
                         } else {
                             setPasswordVerifyError(false);
                         }
                         setPasswordVerify(e.target.value);
                     }}
                     className={'text-input-label-blue'}
                     onKeyPress={handleKeypress}
                     value={passwordVerify}
                     error={!!passwordVerifyError}
                     helperText={passwordVerifyError === true ? 'Passwort darf nicht leer sein!' : passwordVerifyError}
                 />
                 <CustomButton
                     buttontype={'blueYellow'}
                     onClick={() => {
                         setMailError(false);
                         setCompanyError(false);
                         setPasswordError(false);
                         setPasswordVerifyError(false);
                         loginRef.current.reg(mail, accountType, accountType !== 'Arbeitgeber' ? null : company, password, passwordVerify).then((e) => {
                             console.log(e);

                             if (e.success) {
                                 if (accountType) history.push('/arbeitgeber');
                                 else history.push('/');
                             } else {
                                 setMailError(e.mError);
                                 setCompanyError(e.coError);
                                 setPasswordError(e.pError);
                                 setPasswordVerifyError(e.pVError);
                             }
                         })
                     }}
                     style={{
                     }}
                 >REGISTRIEREN</CustomButton>
                 <div style={{fontSize: 14, float: 'left', marginTop: 10}} className={'color-blue'}>* Pflichtfelder</div>
             </div>
    </div>);
}

