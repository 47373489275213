import React, { useEffect, useRef } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton, InputLabel,
    Modal,
    Radio,
    Select,
    Switch
} from "@mui/material";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as LogoSvgArbeitgeber1 } from "../images/svg/swiitcher_icon.svg";
import { ReactComponent as LogoSvgGefunden } from "../images/svg/gefunden.svg";
import { ReactComponent as LogoSvgHeart } from "../images/svg/heart.svg";
import { ReactComponent as LogoSvgHeartOutline } from "../images/svg/heart_outline.svg";
import { ReactComponent as LogoSvgPuzzle } from "../images/svg/puzzle.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SearchIcon from "@mui/icons-material/Search";
import useFetch from "./UseFetch";
import { ReactSession } from "react-client-session";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { ReactComponent as TrashIcon } from "../images/svg/mulleimer.svg";
import { ReactComponent as PencilIcon } from "../images/svg/pencil.svg";
import { ReactComponent as EyeIcon } from "../images/svg/auge.svg";
import { ReactComponent as CopyIcon } from "../images/svg/copy.svg";
import WestIcon from "@mui/icons-material/West";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from 'moment';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { CssTextField3 } from "./CssTextField3";
import { CustomButton } from "./CustomButton";
import { PriorityHigh } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactComponent as TestLogo } from "../images/svg/swiitcher_icon_blau.svg";
import Vorschau from "./Vorschau";
import { DateTime } from "luxon";
import { Select2 } from "./Select2";

export default function Stellenanzeigen(props) {

    const { locationChange, menuAppBarRef } = props;
    const location = useLocation();
    const history = useHistory();
    const api = useFetch();

    const [hasMoreData, setHasMoreData] = useState(false);
    const [buttonHover, setButtonHover] = useState(null);
    const [activeMatchItem, setActiveMatchItem] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    // const [rows, setRows] = useState(1);
    const [items, setItems] = useState([]);
    const [cantCreateAdvertisement, setCantCreateAdvertisement] = React.useState(null);
    const [onContingentEmpty, setOnContingentEmpty] = React.useState(false);
    const [onDelete, setOnDelete] = React.useState(false);
    const [selectedReason, setSelectedReason] = React.useState(0);
    const [showLoadingspinner, setShowLoadingspinner] = React.useState(false);
    const [isActive, setActive] = React.useState(true);
    const [vorschau, setVorschau] = React.useState(false);
    const [currentAddresses, setCurrentAddresses] = React.useState(null);
    const [currentContacts, setCurrentContacts] = React.useState(null);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [sortValue, setSortValue] = React.useState('');
    const [contingentLoaded, setContingentLoaded] = React.useState(false)

    useEffect(() => {
        if (ReactSession.get('user') != 'Arbeitgeber') {
            menuAppBarRef.current.logNeeded('Arbeitgeber');
            history.push('/');
        } else {
            document.title = 'Stellenanzeigen';
            locationChange(location);
            getAdvertisements();
            getContingent();
        }
    }, [])

    const deleteStyle = {
        position: 'absolute',
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '20%',
        minWidth: 200,
        maxHeight: '70%',
        overflow: 'auto',
        backgroundColor: 'rgba(0, 55, 78, 0.95)',
        border: '2px solid #C7D300',
        borderRadius: 5,
        padding: '5px 32px 20px',
        boxShadow: 24,
        zIndex: 4,
        lineHeight: '13px'
    };

    const passwordStyle = {
        position: 'absolute',
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '20%',
        minWidth: 200,
        maxHeight: '70%',
        overflow: 'auto',
        backgroundColor: 'rgba(218, 227, 243, 0.95)',
        border: '2px solid transparent !important',
        borderRadius: 5,
        padding: '5px 16px 20px',
        boxShadow: 24,
        zIndex: 4,
        lineHeight: '13px'
    };

    const selectItems = [
        'Stelle über swiitcher besetzt', 'Stelle anderweitig besetzt', 'Ohne Grund löschen'
    ];

    const getAdvertisements = async () => {

        let rows = [];
        let hasMore = true;

        for (let i = 1; i < pageNumber + 1; i++) {
            let sortField = undefined
            let sortDesc = undefined

            if(sortValue === 'activeFirst') {
                sortField = 'isActiveAdvert'
                sortDesc = true
            } else if(sortValue === 'inactiveFirst') {
                sortField = 'isActiveAdvert'
                sortDesc = false
            } else if(sortValue === 'templatesFirst') {
                sortField = 'draft'
                sortDesc = true
            }
            let url = ENV.REACT_APP_API_URL + '/arbeitgeber/1/job-advertisements?page%5Bsize%5D=10&page%5Bnumber%5D=' + i;

            if(sortField) url += '&sortField=' + sortField
            if(sortDesc !== undefined) url += '&sortDesc=' + sortDesc

            let { response, data } = await api(url)


            if (!response || response.status == 401) {
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                history.push('/arbeitgeber');
                return;
            }
            console.log("DATA.DATA", data.data)
            if (data && data.data) {
                data.data.map((item, index) => {

                    item.attributes.badges = JSON.parse(item.attributes.badges);
                    if (rows.filter(i => i.id === item.id).length === 0) {
                        rows.push(item);
                    } else {
                        rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])] = item;
                    }
                    // rows.push(item)
                })
                //
                // for(const item of data.data) {
                //     item.attributes.badges = JSON.parse(item.attributes.badges);
                //     if (rows.filter(i => i.id === item.id).length === 0) {
                //                 rows.push(item);
                //             } else {
                //                 rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])] = item;
                //             }
                // }

                if (data.meta.pagination.total === rows.length) {
                    hasMore = false;
                }

            } else if (response.error) {
                notify('API Fehler: ' + response.status, true);
            }
        }
        setItems(rows);
        setHasMoreData(hasMore);
    }

    const getContingent = async () => {

        let url = ENV.REACT_APP_API_URL + '/user/' + ReactSession.get('userId') + '?include=jobAdvertisementContingent';

        let { response, data } = await api(url)


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/arbeitgeber');
            return;
        }

        console.log(data);
        if (data && data.included && data.included[0] && (data.included[0].attributes.availableSingleJobAdvertisements > 0 || (data.included[0].attributes.flatDuration && moment(data.included[0].attributes.flatDuration) > moment()))) {
            console.log('ACCESS GRANTED');
            setCantCreateAdvertisement(true);
        } else if (data && data.included && data.included[0] && (data.included[0].attributes.availableSingleJobAdvertisements == 0 && (data.included[0].attributes.flatDuration && moment(data.included[0].attributes.flatDuration) < moment()))) {
            console.log('ACCESS DENIED');
            setCantCreateAdvertisement(false);
        } else if (response.error) {
            notify('API Fehler: ' + response.status, true);
        }
        setContingentLoaded(true)
    }

    const notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: { marginTop: 30 }
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: { marginTop: 30 }
            });
        }
    }

    const createStellenanzeige = async () => {
        let updatedAttributes = {
            data: {
                attributes: {
                    title: 'Titel',
                    subTitle: '(m/w/d) in Vollzeit',
                    slogan: 'Titelslogan der Bewerbung',
                    description: 'Description',
                    text: 'Text',
                    creator_id: 1,
                },
                relationships: {}
            }
        }

        let {
            response,
            data
        } = await api(ENV.REACT_APP_API_URL + "/job-advertisements", 'POST', JSON.stringify(updatedAttributes))

        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/arbeitgeber');
            return;
        }
        if (response && response.status != '404') {
            reload(false, 'Deine Stellenanzeige wurde erfolgreich angelegt');
            history.push('/stellenanzeigen');
        } else if (response.status == '404') {
            notify('Stellenanzeige existiert bereits', true);
        } else {
            notify('API Fehler: ' + response.status, true);
        }
    }

    useEffect(() => {
        reload()
    }, [sortValue])
    const reload = async (newData = false, message, error = false) => {
        console.log('reload')
        await getAdvertisements(newData);
        if (message) {
            this.notify(message, error);
        }
    }

    const handleMouseIn = (type) => {
        setButtonHover(type)
    };

    const handleMouseOut = () => {
        setButtonHover(null)
    };

    const deleteStellenanzeige = async () => {

        const formData = new FormData();
        formData.append(
            'data',
            JSON.stringify({
                data: {
                    attributes: {
                        deleted: true,
                        reason: selectItems[selectedReason]
                    }
                }
            }),
        );

        setShowLoadingspinner(true);
        setOnDelete(false);

        let {
            response,
            data
        } = await api(ENV.REACT_APP_API_URL + "/job-advertisement/" + onDelete + '/upload/file', 'POST', formData, true)

        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/arbeitgeber');
            return;
        }

        if (response && response.status != '404' && response.status != '403') {
            console.log('DELETE')
            setOnDelete(false);
            setSelectedReason(0);
            getAdvertisements();
            notify('Deine Stellenanzeige wurde erfolgreich gelöscht');
        } else if (response.status == '404') {
            notify('Stellenanzeige existiert bereits', true);
        } else {
            notify('API Fehler: ' + response.status, true);
        }

        setShowLoadingspinner(false);
    }

    const getContacts = async () => {
        let url = ENV.REACT_APP_API_URL + '/contacts?page[size]=20&page[number]=1';

        let { response, data } = await api(url)


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/arbeitgeber');
            return;
        }
        console.log("GET CONTACTS", data);
        setCurrentContacts(data.data)
    }

    const getAddresses = async (id) => {
        // let url = ENV.REACT_APP_API_URL + '/company-addresses?page[size]=20&page[number]=1';
        let url =ENV.REACT_APP_API_URL+'/job-advertisement/'+id+'?include=contacts,companyAddresses,answeredQuestions';
        let { response, data } = await api(url)


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/arbeitgeber');
            return;
        }

        setCurrentAddresses(data.included.filter(i => i.type == 'company-addresses'));
    }

    const activeString = (attributes) => {
        if (!attributes.statusChangedAt) return '';

        return (!!attributes.isActiveAdvert) ? `aktiv` : `inaktiv`
    }
    const newData = () => {
        if (hasMoreData === true) {
            let pageN = pageNumber;
            let temp = ++pageN;
            setPageNumber(temp);
        }
    }

    const vorschauStyle = {
        position: 'absolute',
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxHeight: '70%',
        overflow: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        zIndex: 4
    };

    const createDraft = async (id) => {
        let url = ENV.REACT_APP_API_URL + "/job-advertisement/" + id

        let { response, data } = await api(url, 'POST')


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/arbeitgeber');
            return;
        }
        reload()
    }

    const setActiveAdvert = async (id, isActive) => {
        let url = ENV.REACT_APP_API_URL + "/job-advertisement/" + id + '/upload/file'

        const formData = new FormData();
        formData.append(
            'data',
            JSON.stringify({
                data: {
                    attributes: {
                        isActiveAdvert: isActive //(isActive) ? 1 : 0
                    }
                }
            }),
        );

        let { response, data } = await api(url, 'POST', formData, true)


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/arbeitgeber');
            return;
        }
        reload()
    }

    const sortingChanged = (event) => {
        setSortValue(event.target.value)
    }

    useEffect(() => {
        if (pageNumber) {
            getAdvertisements();
        }
    }, [pageNumber])

    return (
        <Grid className="mainApp" container style={{
            padding: 0,
            position: 'relative'
        }}>
            {showLoadingspinner && <div
                style={{
                    position: 'absolute',
                    zIndex: 111,
                    color: 'white',
                    width: '100%',
                    height: '100vh',
                    background: 'rgba(51, 51, 51, 0.7)',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}>
                <div id={'testLogo'} style={{
                    borderRadius: 50,
                    width: 60,
                    height: 60,
                    minWidth: 60,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    border: '4px solid transparent',
                    transitionDuration: '0.2s',
                    transitionProperty: 'transform',
                    margin: '0 auto'
                }}
                >
                    <div style={{
                        padding: 7.5,
                        background: 'rgba(0, 55, 78, 1)',
                        borderRadius: 50,
                        width: 57,
                        minWidth: 27,
                        height: 35,
                        minHeight: 35,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        border: '5px solid rgba(199, 211, 0, 1)'
                    }}>
                        <TestLogo
                            style={{
                                width: 30,
                            }}
                        />
                    </div>
                </div>
            </div>}
            <Grid id={'top-container'} item xs={12} style={{
                display: 'block',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 64,
                marginBottom: activeMatchItem !== null ? 50 : 0,
                padding: '30px 50px 50px',
                // height: 'calc(100vh - 20px)',
                width: '100%',
                minHeight: '800px',
            }}>
                <Grid container>
                    <Grid item xs={6}>
                        <p className={'headerName'} style={{ position: 'relative', lineHeight: '40px' }}>
                            Stellenanzeigenübersicht
                        </p>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                        <button className={'fadeInOut'}
                                onMouseOver={() => handleMouseIn('newStellenanzeigeButtonHover')}
                                onMouseOut={() => handleMouseOut('newStellenanzeigeButtonHover')}
                                disabled={!contingentLoaded}
                                style={{
                            fontFamily: 'VagRoundedBold',
                            //background: logoutButtonHover ? '#00374e' : '#C7D300',
                            background: buttonHover == 'newStellenanzeigeButtonHover' ? '#00374e' : '#C7D300',
                            cursor: 'pointer',
                            textAlign: 'center',
                            padding: '10px 10px 10px 10px',
                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                            color: buttonHover == 'newStellenanzeigeButtonHover' ? '#C7D300' : '#00374e',
                            border: '2px solid ' + (buttonHover == 'newStellenanzeigeButtonHover' ? '#00374e' : '#C7D300'),
                        }} onClick={() => {
                            if (cantCreateAdvertisement) {
                                history.push('/neue-stellenanzeige');
                            } else {
                                setOnContingentEmpty(true);
                            }
                        }}>NEUE ANZEIGE ERSTELLEN
                        </button>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                        {/* TODO ADD*/}
                        <div style={{position: 'relative'}}>
                            <FormControl size={'small'} style={{position: 'absolute', top: 0, right: '-25px'}}>
                                <InputLabel style={{background: 'white'}}>Sortieren</InputLabel>
                                <Select
                                    native
                                    value={sortValue}
                                    onChange={sortingChanged}
                                    defaultValue={'all'}
                                >
                                    <option value={'all'}>Alle</option>
                                    <option value={'activeFirst'}>Aktive zuerst</option>
                                    <option value={'inactiveFirst'}>Inaktive zuerst</option>
                                    <option value={'templatesFirst'}>Entwürfe zuerst</option>
                                </Select>
                            </FormControl>
                        </div>

                        {/*<div id="inputWrapper"*/}
                        {/*     style={{ padding: 0, textAlign: 'right', position: 'relative', width: '100%' }}>*/}
                        {/*    <input id="input" type="search"/>*/}

                        {/*    <SearchIcon id="icon" style={{ position: 'absolute', marginLeft: '5%' }}/>*/}
                        {/*</div>*/}
                    </Grid>
                </Grid>


                <div id={'übersicht'}>
                    {items && <InfiniteScroll
                        dataLength={items ? items.length : 0} //This is important field to render the next data
                        next={newData}
                        hasMore={hasMoreData}
                        loader={<h4>Stellenanzeigen werden geladen...</h4>}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                            </p>
                        }
                        style={{ overflow: 'unset' }}
                    >
                        <Grid container columnSpacing={7} rowSpacing={4} style={{ display: 'block', paddingTop: 20 }}>
                            {items.map((item, index) => {
                                return (
                                    <Grid key={index} item xs={12}>
                                        <div style={{
                                            height: '100%',
                                            width: '100%',
                                            background: '#dddddd',
                                            border: item.attributes.active === 1 ? '3px solid transparent' : 'unset',
                                            padding: '5px 10px',
                                            borderRadius: 20,
                                            position: 'relative'
                                        }}>
                                            <p className={'headerName'}
                                               style={{ position: 'relative', width: 'fit-content', marginBottom: 3 }}>
                                                {item.attributes.title}
                                                {(item.attributes.draft) ? <span
                                                    style={{ color: '#c7d300', paddingLeft: 15 }}>Entwurf</span> : ''}
                                                {/* {item.attributes.m_w_d ? <span style={{fontFamily: 'VagRoundedRegular', color: '#333', fontSize: 16}}>(m/w/d)</span> : ''}*/}
                                            </p>
                                            <p style={{
                                                textAlign: 'left',
                                                marginTop: 0,
                                                marginLeft: 5,
                                                marginBottom: 5,
                                                color: '#00374e',
                                            }}>{item.attributes.subTitle}</p>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Grid item xs={6}>
                                                    <Grid container className={'column-p'}
                                                          style={{ textAlign: 'left', maxWidth: '100%' }}>
                                                        {item.attributes.badges.map((item, index) => {
                                                            if (item.value) {
                                                                return (
                                                                    <span key={index}
                                                                          className={'center-vert overflow-ellipsis'}
                                                                          style={{
                                                                              boxDecorationBreak: 'clone',
                                                                              color: 'white',
                                                                              padding: '6px 12px',
                                                                              margin: '1px 5px 0 0',
                                                                              height: '19px',
                                                                              fontSize: '1rem',
                                                                              background: '#00374e',
                                                                              borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                          }}>
                                                                    {item.value}
                                                                </span>
                                                                );
                                                            }
                                                        })}
                                                    </Grid>
                                                </Grid>
                                                {(!item.attributes.draft) ? <Grid item xs={3} className={'center-vert'}>
                                                    <FormGroup>
                                                        <Switch
                                                            defaultChecked={!!item.attributes.isActiveAdvert}
                                                            checked={!!item.attributes.isActiveAdvert}
                                                            onChange={async () => {
                                                                await setIsUpdating(item.id)
                                                                await setActiveAdvert(item.id, !item.attributes.isActiveAdvert)
                                                                setTimeout(() => {
                                                                    setIsUpdating(null)
                                                                }, 1000)
                                                            }}
                                                                />
                                                                </FormGroup>
                                                            {(isUpdating !== item.id) ? <><span style={{fontWeight: 'bold'}}>{activeString(item.attributes)}</span>&nbsp;{(item.attributes.statusChangedAt) ? `seit (${DateTime.fromFormat(item.attributes.statusChangedAt, 'yyyy-MM-dd HH:mm:ss').toFormat('dd.MM.yyyy')})` : ''}</> : ''}
                                                </Grid> : <></>}
                                                <Grid item className={'center-vert'}>
                                                    <Modal
                                                        open={vorschau === item.id}
                                                        onClose={() => setVorschau(null)}
                                                        aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"
                                                    >
                                                        <Box sx={vorschauStyle}>
                                                            <Vorschau title={{ title: item.attributes.title }}
                                                                      subTitle={item.attributes.subTitle}
                                                                      slogan={item.attributes.slogan}
                                                                      m_w_d={item.attributes.m_w_d}
                                                                      employmentTypes={item.attributes.employmentTypes}
                                                                      description={item.attributes.description}
                                                                      imageDescription={item.attributes.imageDescription}
                                                                      editorText={item.attributes.text}
                                                                      companyImage={item.attributes.logo_id ? { id: item.attributes.logo_id } : null}
                                                                      teaserImage={item.attributes.file_id ? { id: item.attributes.file_id } : null}

                                                                      selectedCompanyAddresses={currentAddresses}

                                                                      selectedContacts={currentContacts}

                                                                      badges={item.attributes.badges}
                                                            />
                                                        </Box>
                                                    </Modal>
                                                    <IconButton
                                                        className={'icon-button'}
                                                        style={{
                                                            height: 20,
                                                            width: 20,
                                                            fontSize: 20,
                                                            padding: 15,
                                                            marginLeft: 5,
                                                            transition: 'all 0.05s ease-in-out',
                                                            background: '#00374E'
                                                        }}
                                                        onClick={async () => {
                                                            await getAddresses(item.id)
                                                            await getContacts()
                                                            setVorschau(item.id);
                                                        }}
                                                    >
                                                        <EyeIcon
                                                            className={'not-yellow-white'}
                                                            style={{
                                                                position: 'absolute',
                                                                width: 20,
                                                                height: 20,
                                                                transition: 'all 0.2s ease-in-out',
                                                                textAlign: 'center',
                                                                cursor: 'pointer',
                                                                stroke: 'white',
                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                            }}
                                                        />
                                                    </IconButton>
                                                    <IconButton
                                                        className={'icon-button'}
                                                        style={{
                                                            height: 20,
                                                            width: 20,
                                                            fontSize: 20,
                                                            padding: 15,
                                                            marginLeft: 5,
                                                            transition: 'all 0.05s ease-in-out',
                                                            background: '#00374E'
                                                        }}
                                                        onClick={() => createDraft(item.id)}
                                                    >
                                                        <CopyIcon
                                                            style={{
                                                                position: 'absolute',
                                                                width: 20,
                                                                height: 20,
                                                                transition: 'all 0.2s ease-in-out',
                                                                textAlign: 'center',
                                                                cursor: 'pointer',
                                                                color: 'white',
                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                            }}
                                                        />
                                                    </IconButton>
                                                    <IconButton
                                                        className={'icon-button'}
                                                        style={{
                                                            height: 20,
                                                            width: 20,
                                                            fontSize: 20,
                                                            padding: 15,
                                                            marginLeft: 5,
                                                            transition: 'all 0.05s ease-in-out',
                                                            background: '#00374E'
                                                        }}
                                                        onClick={() => {
                                                            history.push('/stellenanzeige/' + item.id + '/update');
                                                        }}
                                                    >
                                                        <PencilIcon
                                                            style={{
                                                                position: 'absolute',
                                                                width: 20,
                                                                height: 20,
                                                                transition: 'all 0.2s ease-in-out',
                                                                textAlign: 'center',
                                                                cursor: 'pointer',
                                                                stroke: 'white',
                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                            }}
                                                        />
                                                    </IconButton>
                                                    <IconButton
                                                        className={'icon-button'}
                                                        style={{
                                                            height: 20,
                                                            width: 20,
                                                            fontSize: 20,
                                                            padding: 15,
                                                            marginLeft: 5,
                                                            transition: 'all 0.05s ease-in-out',
                                                            background: '#00374E'
                                                        }}
                                                        onClick={() => {
                                                            setOnDelete(item.id);
                                                        }}
                                                    >
                                                        <TrashIcon
                                                            className={'not-yellow-white'}
                                                            style={{
                                                                position: 'absolute',
                                                                width: 20,
                                                                height: 20,
                                                                transition: 'all 0.2s ease-in-out',
                                                                textAlign: 'center',
                                                                cursor: 'pointer',
                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                            {/*<Button style={{*/}
                                            {/*    position: 'absolute',*/}
                                            {/*    bottom: 10,*/}
                                            {/*    left: 10,*/}
                                            {/*    fontFamily: 'VagRoundedBold',*/}
                                            {/*    textDecoration: 'underline',*/}
                                            {/*    color: '#00374e'*/}
                                            {/*}}*/}
                                            {/*        onClick={() => {*/}
                                            {/*            history.push('/stellenanzeige/' + item.id + '/update');*/}
                                            {/*        }}*/}
                                            {/*>Bearbeiten</Button>*/}

                                            {!item.attributes.active && !item.attributes.draft && <div className={'color-blue'} style={{
                                                position: 'absolute',
                                                fontFamily: 'VagRoundedBold',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '100%',
                                                height: '100%',
                                                border: !item.attributes.active ? '3px solid rgb(100,100,100)' : 'unset',
                                                borderRadius: 20,
                                                top: 0,
                                                left: 0,
                                                background: 'rgba(222,222,222,0.8)'
                                            }}>
                                                Diese Anzeige wird noch geprüft!&nbsp;<AccessAlarmIcon/>
                                            </div>}
                                        </div>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </InfiniteScroll>}
                </div>
            </Grid>
            <Modal
                open={onDelete}
                onClose={() => setOnDelete(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={deleteStyle}>
                    <h3 style={{ color: '#C7D300', fontSize: 20 }}>Stellenanzeige löschen</h3>

                    <p style={{ color: 'white', fontSize: 12 }}>Warum möchtest du diese
                        Stellenanzeige löschen?</p>

                    <FormGroup>
                        <FormControlLabel
                            style={{ marginLeft: 0 }}
                            control={
                                <Radio
                                    sx={{
                                        p: '4px 9px',
                                        color: '#C7D300',
                                        '&.Mui-checked': {
                                            color: '#C7D300',
                                        },
                                    }}
                                    onClick={() => {
                                        if (selectedReason === 0) {
                                            // setSelectedReason(false);
                                        } else {
                                            setSelectedReason(0);
                                        }
                                    }}
                                    checked={selectedReason === 0}
                                />
                            }
                            label={<Typography style={{
                                fontSize: 12,
                                color: 'white',
                                fontFamily: 'VagRoundedBold'
                            }}>Stelle über swiitcher besetzt</Typography>}
                            sx={{
                                '& .MuiSvgIcon-root': { fontSize: 12 },
                                '& .MuiTypography-root': { marginLeft: '10px' }
                            }}
                        />
                        <FormControlLabel
                            style={{ marginLeft: 0 }}
                            control={
                                <Radio
                                    sx={{
                                        p: '4px 9px',
                                        color: '#C7D300',
                                        '&.Mui-checked': {
                                            color: '#C7D300',
                                        },
                                    }}
                                    onClick={() => {
                                        if (selectedReason === 1) {
                                            // setSelectedReason(false);
                                        } else {
                                            setSelectedReason(1);
                                        }
                                    }}
                                    checked={selectedReason === 1}
                                />
                            }
                            label={<Typography style={{
                                fontSize: 12,
                                color: 'white',
                                fontFamily: 'VagRoundedBold'
                            }}>Stelle anderweitig besetzt</Typography>}
                            sx={{
                                '& .MuiSvgIcon-root': { fontSize: 12 },
                                '& .MuiTypography-root': { marginLeft: '10px' }
                            }}
                        />
                        <FormControlLabel
                            style={{ marginLeft: 0 }}
                            control={
                                <Radio
                                    sx={{
                                        p: '4px 9px',
                                        color: '#C7D300',
                                        '&.Mui-checked': {
                                            color: '#C7D300',
                                        },
                                    }}
                                    onClick={() => {
                                        if (selectedReason === 2) {
                                            // setSelectedReason(false);
                                        } else {
                                            setSelectedReason(2);
                                        }
                                    }}
                                    checked={selectedReason === 2}
                                />
                            }
                            label={<Typography style={{
                                fontSize: 12,
                                color: 'white',
                                fontFamily: 'VagRoundedBold'
                            }}>Ohne Grund löschen</Typography>}
                            sx={{
                                '& .MuiSvgIcon-root': { fontSize: 12 },
                                '& .MuiTypography-root': { marginLeft: '10px' }
                            }}
                        />
                    </FormGroup>
                    <Typography sx={{ mt: 2, mb: 1 }}>

                        <button className={'fadeInOut'}
                                onMouseOver={() => setButtonHover('doDeleteButtonHover')}
                                onMouseOut={() => setButtonHover('')} style={{
                            width: '100%',
                            fontFamily: 'VagRoundedBold',
                            //background: logoutButtonHover ? '#00374e' : '#C7D300',
                            background: buttonHover == 'doDeleteButtonHover' ? '#00374e' : '#C7D300',
                            cursor: 'pointer',
                            textAlign: 'center',
                            padding: '5px 10px',
                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                            color: buttonHover == 'doDeleteButtonHover' ? '#C7D300' : '#00374e',
                            border: '2px solid ' + (buttonHover == 'doDeleteButtonHover' ? '#C7D300' : '#C7D300'),
                        }} onClick={() => {
                            deleteStellenanzeige();
                        }}>Löschen
                        </button>
                    </Typography>

                    <p style={{ fontSize: 10, color: 'white', marginTop: 10 }}>
                                                <span
                                                    style={{ fontFamily: 'VagRoundedBold' }}>Wusstest du schon?</span><br/>
                        Beim Löschen deiner Stellenanzeigen lösen sich alle von dir angefragten
                        Matches automatisch auf.
                        Somit entstehen keine Kosten.
                    </p>
                </Box>
            </Modal>
            <Modal
                open={onContingentEmpty}
                onClose={() => {
                    setOnContingentEmpty(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={passwordStyle}>
                    <h3 style={{ marginTop: 0, marginBottom: 30, fontFamily: 'VagRoundedBold' }}>Fehlendes
                        Kontingent<PriorityHigh
                            style={{
                                color: '#92D050',
                                marginBottom: -5,
                                width: 40,
                                height: 40,
                            }}
                        /></h3>

                    <p style={{ fontSize: 12 }}>Damit du eine Stellenanzeige erstellen kannst, buche eine Einzelanzeige
                        oder Flat.</p>
                    <CustomButton
                        buttontype={'yellowBlue'}
                        onClick={() => {
                            // login();
                            history.push('/arbeitgeber#produkte-und-preise');
                        }}
                        style={{
                            marginTop: 20,
                            width: '100%'
                        }}
                    >PRODUKTE & PREISE</CustomButton>
                    <span style={{ position: "absolute", right: 5, top: 5 }}>
                        <ClearIcon
                            className={'not-yellow-white'}
                            onClick={() => {
                                setOnContingentEmpty(false)
                            }}
                            style={{
                                height: 26,
                                width: 26,
                                marginRight: 2,
                                transition: 'all 0.2s ease-in-out',
                                textAlign: 'center',
                                cursor: 'pointer',
                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                            }}
                        />
                    </span>
                </Box>
            </Modal>
        </Grid>
    );
}

