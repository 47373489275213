import React, { useEffect, useRef } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, Link, Modal, TextareaAutosize, Tooltip, tooltipClasses } from "@mui/material";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as LogoSvgArbeitgeber1 } from "../images/svg/swiitcher_icon.svg";
import { ReactComponent as LogoSvgGefunden } from "../images/svg/auge.svg";
import { ReactComponent as LogoSvgHeart } from "../images/svg/heart.svg";
import { ReactComponent as LogoSvgHeartOutline } from "../images/svg/heart_outline.svg";
import { ReactComponent as LogoSvgSwiitcherIcon } from "../images/svg/swiitcher_icon.svg";
import { ReactSession } from "react-client-session";
import htmlToDraft from "html-to-draftjs";
import ContentState from "draft-js/lib/ContentState";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import useFetch from "./UseFetch";
import { toast } from "react-toastify";
import moment from 'moment'
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { ReactComponent as TrashIcon } from "../images/svg/mulleimer.svg";
import ToggleSwitch from "./ToggleSwitch";
import { ReactComponent as LogoSvgQouselduselmark } from "../images/svg/qouselduselmark.svg";
import { CustomButton } from "./CustomButton";
import { ReactComponent as LogoSvgDenker } from "../images/svg/Illustration_TTVerlag_Denker_Flaeche.svg";
import { ReactComponent as LogoSvgEntdecker } from "../images/svg/Illustration_TTVerlag_Entdecker_Flaeche.svg";
import { ReactComponent as LogoSvgHelfer } from "../images/svg/Illustration_TTVerlag_Helfer_Flaeche.svg";
import { ReactComponent as LogoSvgDiplomat } from "../images/svg/Illustration_TTVerlag_Diplomat_Flaeche.svg";
import { ReactComponent as LogoSvgB } from "../images/svg/B.svg";
import { ReactComponent as LogoSvgC } from "../images/svg/C.svg";
import { ReactComponent as LogoSvgD } from "../images/svg/D.svg";
import { ReactComponent as LogoSvgL } from "../images/svg/L.svg";
import { ReactComponent as LogoSvgFL } from "../images/svg/FL.svg";
import InfoIcon from "@mui/icons-material/Info";
import { ReactComponent as TestLogo } from "../images/svg/swiitcher_icon_blau.svg";
import EditIcon from "@mui/icons-material/Edit";
import { isMobile } from "react-device-detect";
import useWindowDimensions from "./useWindowDimensions";
import Box from "@mui/material/Box";
import CheckIcon from '@mui/icons-material/Check';
import { useParams } from "react-router-dom";
import { styled } from "@mui/styles";

const useQuery = () => new URLSearchParams(useLocation().search);

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }}/>
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        border: '1px solid #dadde9',
    },
}));

export default function Matches(props) {

    const query = useQuery();

    const { locationChange, menuAppBarRef, setMenuState } = props;
    const location = useLocation();
    const api = useFetch();
    const history = useHistory();
    const { width } = useWindowDimensions();


    const testRef = useRef(null);
    const ref = useRef([]);

    const [buttonHover, setButtonHover] = useState(null);
    const [onConfirm, setOnConfirm] = useState(false);
    const [onDecline, setOnDecline] = useState(false);
    const [onEdit, setOnEdit] = useState(false);
    const [onDelete, setOnDelete] = useState(false);
    const [activeMatchItem, setActiveMatchItem] = useState(null);
    const [questionsAN, setQuestionsAN] = useState([]);
    const [questionsAG, setQuestionsAG] = useState([]);
    const [answeredQuestionsAN, setAnsweredQuestionsAN] = useState([]);
    const [answeredQuestionsAG, setAnsweredQuestionsAG] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [favorite, setFavorite] = useState(null);
    const loadingSpinnerRef = useRef(null)
    const [questions, setQuestions] = useState([]);
    const [helperText, setHelperText] = useState(null);
    const [description, setDescription] = useState('');
    const [editDescription, setEditDescription] = useState(false);
    const [savedDescription, setSavedDescription] = useState('');
    const [icon, setIcon] = useState(null);
    const [placeholderText, setPlaceholderText] = useState(null);
    const [progress, setProgress] = useState(90);

    const onConfirmStyle = {
        position: 'absolute',
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '20%',
        minWidth: 227,
        maxHeight: '70%',
        overflow: 'auto',
        backgroundColor: 'rgba(0, 55, 78, 0.9)',
        borderRadius: 5,
        padding: '5px 32px 20px',
        boxShadow: 24,
        zIndex: 4,
        textAlign: 'center',
        lineHeight: '20px',
    };

    const onDeclineStyle = onConfirmStyle;

    onDeclineStyle.width = '30%';
    onDeclineStyle.minWidth = isMobile ? '85%' : 288;
    onDeclineStyle.maxWidth = '90%';
    if (isMobile) onDeclineStyle.padding = '5px 16px 20px';

    const onEditStyle = onConfirmStyle;
    const onDeleteStyle = onConfirmStyle;

    const [rows, setRows] = useState([]);

    const items = [
        '1', '2', '3'
    ];


    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, items.length);
    }, [items]);


    const [matchesLoading, setMatchesLoading] = useState(true);
    const [matchesFound, setMatchesFound] = useState(0);
    const [matches, setMatches] = useState(0);
    const itemsRef = useRef([]);


    const getMatchingOverview = async () => {
        let url = ENV.REACT_APP_API_URL + '/matchings/count/matching-overview';

        let { response, data } = await api(url)


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            return;
        }
        console.log(data);
        if (data && data.data) {
            setMatchesFound(data.data.matching_count_found);
            setMatches(data.data.matching_count);
            setMatchesLoading(false);
        }
    }

    useEffect(() => {
        if (ReactSession.get('user') != 'Arbeitnehmer') {
            if (!ReactSession.get('user')) {
                menuAppBarRef.current.logNeeded();
                history.push('/');
            } else if (ReactSession.get('user') == 'Arbeitgeber') {
                menuAppBarRef.current.logNeeded('Arbeitnehmer');
                history.push('/arbeitgeber');
            }
        } else {
            document.title = 'Matches';
            if (location.hash == '#anonymes-profil') {
                window.history.replaceState("", document.title, window.location.pathname);
                setIsChecked(true);
                setTimeout(() => {
                    const id = 'beschreibung';
                    const yOffset = -120;
                    const element = document.getElementById(id);
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                    window.scrollTo({ top: y, behavior: 'smooth' });
                }, 500)
            }
            locationChange(location);
            getData();
            getAnsweredQuestions();
            getMatchingOverview();
            getUser();
        }

        const test = "Beispiel:\n\nPünktlichkeit und Zuverlässigkeit sind eine meiner Stärken. Zudem eigne ich mich hervorragend als Problemlöser und besitze allgemein eine gute Auffassungsgabe. Ich bringe mich ein und helfe dort, wo ich gebraucht werde.\nIch würde mich daher selbst als „Allrounder“ bezeichnen.\nWeitere Zusatzqualifikationen: Ausbilderschein und diverse Fort- und Weiterbildungen im Personalwesen.\n\nIch biete Ihnen einen echten Mehrwert für Ihr Unternehmen.";

        setPlaceholderText(test);
    }, [])

    const getUser = async () => {
        let url = ENV.REACT_APP_API_URL + '/user/' + ReactSession.get('userId');

        let { response, data } = await api(url)


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/arbeitgeber');
            return;
        }
        if (data && data.data) {


            if (data.data.attributes.description && data.data.attributes.description.length > 0) {
                setProgress(100);
                setDescription(data.data.attributes.description);
                setSavedDescription(data.data.attributes.description);
            } else {
                setProgress(90);
                setDescription('');
                setSavedDescription('');
            }

        } else if (response.error) {
            //notify('API Fehler: ' + response.status, true);
        }
    }

    const getData = async () => {
        let url = ENV.REACT_APP_API_URL + '/matchings?include=jobAdvertisement,arbeitgeber';

        let { response, data } = await api(url)

        let rows = [];
        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            return;
        }
        console.log(data);
        let activeItemToSet = null;
        if (data && data.data) {
            data.data.map((item, index) => {
                let temp = {};
                let status = '';
                if (item.attributes.matchingArbeitgeber && item.attributes.matchingArbeitnehmer) {
                    status = 'bestätigt';
                } else if (item.attributes.matchingArbeitgeber && !item.attributes.declineArbeitnehmer) {
                    status = 'angefragt';
                } else if (item.attributes.matchingArbeitgeber && item.attributes.declineArbeitnehmer) {
                    status = 'abgelehnt';
                }


                temp.matching = item;
                temp.matching.status = status;
                temp.jobAdvertisement = data.included.filter(i => i.type == 'job-advertisements' && item.attributes.job_advertisement_id == i.id)[0];
                temp.arbeitgeber = data.included.filter(i => i.type == 'users' && item.attributes.arbeitgeber_id == i.id)[0];
                if (item.id == query.get('id')) activeItemToSet = temp;
                rows.push(temp);
            })
            console.log(rows);
            rows.sort((a, b) => (a.matching.status == 'bestätigt' ? -1 : 1));

            setRows(rows);
            if (activeItemToSet) {
                setActiveMatchItem(activeItemToSet);

                setTimeout(() => {
                    const id = 'match-' + activeItemToSet.matching.id;
                    const yOffset = -90;
                    const element = document.getElementById(id);
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                    window.scrollTo({ top: y, behavior: 'smooth' });
                }, 500)

                window.history.replaceState({}, '', window.location.pathname);
            }

        } else if (response.error) {

        }
    }

    const truncate = (str, n) => {
        return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
    };

    const getAnsweredQuestions = async () => {
        let answeredQuestionsTemp = [];
        let temp = '';
        let items = [];

        let url = ENV.REACT_APP_API_URL + '/answered-questions/for/matching';


        let postData = {
            userId: ReactSession.get('userId'),
        }


        let tempData = await api(url, 'POST', JSON.stringify(postData))
        let response = tempData.response;
        let data = tempData.data;

        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
        }
        if (data && data.data) {
            data.data.map((item, index) => {
                answeredQuestionsTemp.push(item);
            });
        } else if (response.error) {
            notify('API Fehler: ' + response.status, true);
        }

        answeredQuestionsTemp.sort((a, b) => (a.question_id == 1) ? -1 : 1);

        if (answeredQuestionsTemp.length > 0) {
            let answersAN2 = [];
            let answersAN3 = [];
            answeredQuestionsTemp.filter(i => i.question_id == 2).map((item, index) => {
                item.answers.answerAN.map((item, index) => {
                    if (item != 'Nein' && !answersAN2.includes(item) && !answersAN3.includes(item)) answersAN2.push(item);
                })
            })

            answeredQuestionsTemp.filter(i => i.question_id != 2 && (i.question_id == 3)).map((item, index) => {
                item.answers.answerAN.map((item, index) => {
                    if (item != 'Nein' && !answersAN2.includes(item) && !answersAN3.includes(item)) answersAN3.push(item);
                })
            })

            // answersAN2.push(...answersAN3);
            // rightAnswers2.push(...rightAnswers3);
            let newItem = {
                text: 'Aktueller Status:',
                answersAN: answersAN2,
                answersAN3: answersAN3,
                question_id: 2
            };

            items.push(newItem);

            let answersAN = [];
            let rightAnswers = [];

            answeredQuestionsTemp.filter(i => i.question_id == 36 || i.question_id == 48).map((item, index) => {
                item.answers.answerAN.map((item, index) => {
                    if (item != 'Nein' && !answersAN.includes(item) && !answersAN.includes(item)) answersAN.push(item);
                })
            })

            newItem = {};
            newItem = {
                text: 'Konkreter Berufswunsch:',
                answersAN,
                rightAnswers,
                question_id: 36
            };

            items.push(newItem);

            newItem = {};
            answersAN = [];
            let helperText = null;
            let icon = null;

            answeredQuestionsTemp.filter(i => i.question_id != 2 && i.question_id != 3 && i.question_id != 36 && i.question_id != 48).map((item, index) => {
                newItem = {};
                newItem.text = item.question;
                newItem.question_id = item.question_id;
                newItem.helperText = item.helperText;
                answersAN = [];

                item.answers.answerAN.map((item, index) => {
                    answersAN.push(item);
                    switch (item) {
                        case 'Denker':
                            helperText = 'Er/Sie hat viel Fantasie und kombiniert seinen/ihren enormen Wissensdurst mit ausgefeilter strategischer Planung. Intellektuelle Herausforderungen lassen sein/ihr Licht heller strahlen und er/sie findet immer einen Weg zum Ziel.';
                            icon = item;
                            break;
                        case 'Entdecker':
                            helperText = 'Ausprobieren, neu kombinieren und in unbekannte Welten vordringen: Mit ihm/ihr an der Seite werden überraschende Entdeckungen möglich. Er/Sie hat innovative Ideen und spannende Visionen und begeistert die Menschen um sich mit Forscherdrang und seinem/ihrem verspieltem Charme.';
                            icon = item;
                            break;
                        case 'Helfer':
                            helperText = 'Auf ihn/sie ist einfach immer Verlass, denn er/sie kümmert sich jederzeit um andere und hilft, wo er/sie kann. Dabei ist er/sie praktisch veranlagt und organisiert problemlos das alltägliche Chaos um sich herum.';
                            icon = item;
                            break;
                        case 'Diplomat':
                            helperText = 'Er/Sie glaubt fest an das Gute und verbreitet Zuversicht auf Schritt und Tritt. Mit seinem/ihrem freien und kreativen Geist inspiriert er/sie die Menschen um sich herum zu kühnen Taten. Er/Sie steht zu seinen/ihren Idealen und sprüht vor positiver Energie.';
                            icon = item;
                            break;
                    }
                })

                if (helperText && icon) {
                    setHelperText(helperText);
                    setIcon(icon);
                }

                newItem.rightAnswers = rightAnswers;
                newItem.answersAN = answersAN;
                items.push(newItem);
            })
            console.log(items);
            setQuestions(items);
        }
    }

    const deleteAnsweredQuestions = async () => {
        let url = ENV.REACT_APP_API_URL + '/answered-question/1';
        const { response, data } = await api(url, 'DELETE');
        console.log(data);
        if (response.status == '200') {
            setOnDelete(false);
            setQuestions([]);
            getAnsweredQuestions('AN');
            getMatchingOverview();
            notify('Dein anonymes Profil wurde erfolgreich gelöscht');
        } else {
            notify('API Fehler: ' + response.status, true);
        }

    }

    const handleMouseIn = (type) => {
        setButtonHover(type)
    };

    const handleMouseOut = () => {
        setButtonHover(null)
    };

    const updateMatch = async (item, type, decline = false) => {
        console.log(item);
        let url = ENV.REACT_APP_API_URL + '/matching/' + item.matching.id;


        let postData = {
            data: {
                attributes: {
                    matchingArbeitnehmer: decline ? 0 : item.matching.attributes.matchingArbeitnehmer == 0,
                    declineArbeitnehmer: decline
                }
            }
        };


        const { response, data } = await api(url, 'PATCH', JSON.stringify(postData));

        console.log(data);

        if (response && response.status == 200) {
            switch (type) {
                case 'confirm':
                    setOnConfirm(item);
                    break;
                case 'decline':
                    setOnDecline(item);
                    break;
            }
            getData();
        } else {
            notify('API Fehler: ' + response.status, true);
        }
    }

    const notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: { marginTop: 30 }
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: { marginTop: 30 }
            });
        }
    }

    useEffect(() => {
        console.log(activeMatchItem);
        setUnread();
        getAnsweredQuestions('AG')
    }, [activeMatchItem])

    const setUnread = async () => {
        console.log('setUnread');
        console.log(activeMatchItem);
        if (activeMatchItem && activeMatchItem.matching.attributes.matchingArbeitnehmerUnread) {
            let url = ENV.REACT_APP_API_URL + '/matching/' + activeMatchItem.matching.id;


            const { response, data } = await api(url, 'PATCH', JSON.stringify({
                data: {
                    attributes: {
                        matchingArbeitnehmerUnread: 0
                    }
                }
            }));

            console.log(data);

            if (response && response.status == 200) {
                //getData();
            } else {
                notify('API Fehler: ' + response.status, true);
            }
        }
    }

    const updateUser = async () => {
        if ((editDescription && !description.trim()) || description.trim().length > 0) {
            let updatedAttributes = {
                data: {
                    attributes: {
                        description: description.trim(),
                    }
                }
            }

            let url = ENV.REACT_APP_API_URL + "/user/" + ReactSession.get('userId');
            const { response, data } = await api(url, 'PATCH', JSON.stringify(updatedAttributes));
            console.log(data);

            if (response && response.status != '404' && data && response.status != '422') {
                getUser();
                setEditDescription(false);
                if (description.trim()) {
                    ReactSession.set('description', description)
                    notify('Dein Beschreibung wurde erfolgreich gespeichert');
                } else {
                    ReactSession.set('description', null)
                    notify('Dein Beschreibung wurde erfolgreich entfernt');
                }
            } else {
                notify('API Fehler: ' + response.status, true);
            }
        } else {
            if (!description) {
                notify('Deine Beschreibung ist leer!', 'error');
            } else {
                notify('Deine Beschreibung enthält nur Leerzeichen!', 'error');
            }
        }
    }

    let führerscheinLogos = {
        B: LogoSvgB,
        C: LogoSvgC,
        D: LogoSvgD,
        L: LogoSvgL,
        FL: LogoSvgFL
    };
    return (
        <Grid container style={{
            padding: 0,
        }}>
            <Grid id={'top-container'} item xs={12} style={{
                display: 'block',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 64,
                marginBottom: activeMatchItem !== null ? 50 : 0,
                padding: isMobile ? '30px 10px 50px' : '30px 50px 50px',
                width: '100%',
                minHeight: '100vh',
                transition: 'all 0.2s ease-in-out',
                // overflowX: 'hidden',
                // background: '',
                //boxShadow: 'inset 0px 2px 4px -1px rgb(0 0 0 / 20%), inset 0px 4px 5px 0px rgb(0 0 0 / 14%), inset 0px 1px 10px 0px rgb(0 0 0 / 12%)'
            }}>
                <Grid container>
                    <Grid item xs={2}>
                        <p className={'headerName'} style={{ position: 'relative' }}>
                            Matches
                        </p>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 8} style={{ textAlign: isMobile ? 'right' : 'center' }}>
                        {!isMobile && <ToggleSwitch id="isChecked" checked={isChecked} onChange={() => {
                            setIsChecked(!isChecked)
                        }}/>}
                    </Grid>
                    <Grid item xs={isMobile ? 4 : 2} style={{ textAlign: 'right' }}>
                        {(questions && questions.length > 0 && isChecked) ?
                            <div style={{
                                // position: 'absolute',
                                // bottom: 15,
                                // right: 15
                                marginTop: 5,
                            }}>
                                        <span style={{ float: 'right' }}>
                                            <TrashIcon className={'not-yellow'}
                                                       onClick={() => {
                                                           //deleteCompanyAddress(id);
                                                           setOnDelete(true);
                                                       }}
                                                       style={{
                                                           height: 30,
                                                           width: 30,
                                                           marginRight: 2,
                                                           transition: 'all 0.2s ease-in-out',
                                                           textAlign: 'center',
                                                           cursor: 'pointer',
                                                           borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                       }}
                                            />
                                            <EditIcon
                                                className={'not-yellow'}
                                                style={{
                                                    height: 30,
                                                    width: 30,
                                                    marginRight: 2,
                                                    transition: 'all 0.2s ease-in-out',
                                                    textAlign: 'right',
                                                    fill: '#00374e',
                                                    cursor: 'pointer',
                                                    borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                }}
                                                onClick={() => {
                                                    console.log('EDIT')

                                                    setOnEdit(true);
                                                }}
                                            />
                                        </span>
                            </div> : null}
                    </Grid>
                    {isMobile && <Grid item xs={12} style={{ textAlign: 'center', marginTop: isChecked ? 20 : 25 }}>
                        <ToggleSwitch id="isChecked" checked={isChecked} onChange={() => {
                            setIsChecked(!isChecked)
                        }}/>
                    </Grid>}
                </Grid>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <div style={{
                        display: 'block',
                        transform: !isChecked ? 'translate(0)' : 'translateX(-130%)',
                        overflow: 'hidden',
                        flex: '0 0 100%'
                    }}>
                        <Grid container>
                            <Grid item xs={6}>
                                <div style={{ padding: isMobile ? '20px 10px' : '50px 70px', position: 'relative' }}>
                                    <div style={{
                                        margin: 'auto',
                                        maxWidth: '100%',
                                        height: isMobile ? width / 4 - 50 : 100,
                                        width: isMobile ? width / 4 - 50 : 100,
                                        padding: '30px 30px 0',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }} ref={testRef}>
                                        <LogoSvgSwiitcherIcon style={{ height: 80 }}/>
                                    </div>
                                    <div style={{
                                        fontSize: 52,
                                        fontFamily: 'VagRoundedBold',
                                        color: '#00374e'
                                    }}>{matches}</div>

                                    <p style={{
                                        fontSize: 20,
                                        marginTop: 5,
                                        fontFamily: 'VagRoundedBold',
                                        color: '#00374e'
                                    }}>Anfragen</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ padding: isMobile ? '20px 10px' : '50px 70px', position: 'relative' }}>
                                    <div style={{
                                        margin: 'auto',
                                        maxWidth: '100%',
                                        height: isMobile ? width / 4 - 50 : 100,
                                        width: isMobile ? width / 4 - 50 : 100,
                                        padding: '30px 30px 0',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }} ref={testRef}>
                                        <LogoSvgGefunden style={{ height: 80 }} className={'eyeIcon'}/>
                                    </div>
                                    <div style={{
                                        fontSize: 52,
                                        fontFamily: 'VagRoundedBold',
                                        color: '#00374e',
                                        position: 'relative'
                                    }}>{matchesFound}<span
                                        style={{ fontSize: 20, position: 'absolute', top: 5 }}><HtmlTooltip
                                        enterTouchDelay={0}
                                        placement={'bottom'}
                                        title={<>
                                            <p className={'color-blue'}
                                               style={{ fontSize: 14, marginBottom: 2, marginTop: 0, padding: 5 }}>So
                                                oft wurde bereits dein anonymes Profil von Unternehmen angesehen!</p>
                                        </>}>
                                        <InfoIcon
                                            style={{ marginLeft: 4 }}
                                            fontSize={'14px'}
                                        />
                                    </HtmlTooltip></span></div>
                                    {/*<p style={{fontSize: 20, marginTop: 5, fontFamily: 'VagRoundedBold', color: '#00374e'}}>Treffer</p>*/}
                                </div>
                            </Grid>
                        </Grid>


                        <div style={{
                            position: 'relative',
                            marginTop: 2,
                            paddingTop: 2,
                            marginBottom: 20,
                            // border: '2px solid #C7D300',
                            borderRadius: isMobile ? 20 : 100,
                            padding: 10
                        }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <p style={{
                                        textAlign: 'left',
                                        fontFamily: 'VagRoundedBold',
                                        fontSize: 24,
                                        margin: 0,
                                        paddingLeft: 10
                                    }} className={'color-blue'}>
                                        Deine Anfragen
                                    </p>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex', marginTop: 5 }}>
                                    <span className={'color-blue'} style={{ fontSize: 20 }}>
                                        <InfoIcon
                                            style={{ marginLeft: 8 }}
                                        />
                                    </span>
                                    <p className={'color-blue'} style={{
                                        textAlign: 'left',
                                        fontSize: 14,
                                        marginBottom: 2,
                                        marginTop: 0,
                                        padding: 5
                                    }}>Diese Unternehmen haben dir eine Anfrage gestellt, um mit dir via Chat in Kontakt
                                        zu treten.<br/>Interessiert? Dann bestätige die Anfrage und erfahre weitere
                                        Details zum Job. <b>Kostenfrei und anonym.</b><br/>Solltest du kein Interesse
                                        haben, informiere das Unternehmen, in dem du auf „Ich habe kein Interesse“
                                        klickst.</p>
                                </Grid>
                            </Grid>
                        </div>


                        <div id={'übersicht'}>
                            {rows.map((item, index) => {
                                return (
                                    <>
                                        <div key={index} id={('match-' + item.matching.id)}
                                             style={{
                                                 position: 'relative',
                                                 height: (activeMatchItem !== item && !item.matching.attributes.deleted) ? '70px' : '100%',
                                                 paddingBottom: 20
                                             }}>
                                            <div style={{
                                                position: 'relative',
                                                zIndex: 2,
                                                height: isMobile ? 50 : 25,
                                                marginTop: 2,
                                                paddingTop: 2,
                                                background: '#C7D300',
                                                border: '2px solid #C7D300',
                                                borderRadius: 100,
                                                padding: 10,
                                                color: 'white',
                                                // marginRight: 50,
                                            }} onClick={() => {

                                            }}>
                                                <Grid container>
                                                    <Grid item xs={isMobile ? 8 : 2}>
                                                        <p style={{
                                                            textAlign: 'left',
                                                            fontFamily: 'VagRoundedBold',
                                                            fontSize: 16,
                                                            margin: 0,
                                                            paddingLeft: isMobile ? 15 : 10
                                                        }} className={'color-blue'}>
                                                            {/*Firmenname*/}
                                                            {item.arbeitgeber && item.arbeitgeber.attributes.companyName ? truncate(item.arbeitgeber.attributes.companyName, 19) : null}
                                                        </p>
                                                    </Grid>
                                                    <Grid item xs={isMobile ? 4 : 2}>
                                                        <p style={{
                                                            lineHeight: '30px',
                                                            textAlign: 'left',
                                                            paddingRight: isMobile ? 15 : 0,
                                                            fontSize: 16,
                                                            margin: 0,
                                                            display: 'flex'
                                                        }}>
                                                            {item.matching.status == 'bestätigt' ?
                                                                <CheckIcon/> : item.matching.status == 'abgelehnt' ?
                                                                    <AddIcon style={{
                                                                        rotate: '45deg',
                                                                        marginTop: 2
                                                                    }}/> : '...'}&nbsp;{item.matching.status}
                                                        </p>
                                                    </Grid>
                                                    <Grid item xs={isMobile ? 6 : 6}>
                                                        <p style={{
                                                            textAlign: 'left',
                                                            fontFamily: 'VagRoundedBold',
                                                            fontSize: 16,
                                                            margin: 0,
                                                            paddingLeft: isMobile ? 15 : 0
                                                        }}>
                                                            {item.jobAdvertisement?.attributes.title ? truncate(item.jobAdvertisement?.attributes.title, 20) : null}
                                                        </p>
                                                    </Grid>
                                                    <Grid item xs={isMobile ? 6 : 2}>
                                                        <p style={{
                                                            lineHeight: '30px',
                                                            textAlign: isMobile ? 'right' : 'left',
                                                            fontSize: 16,
                                                            margin: 0,
                                                            paddingRight: isMobile ? 25 : 0
                                                        }}>
                                                            {/*{moment.utc(item.matching.attributes.matchingTimeArbeitgeber).local().fromNow()}*/}
                                                            {moment(item.matching.attributes.matchingTimeArbeitgeber).fromNow()}
                                                        </p>
                                                    </Grid>
                                                </Grid>
                                                <div style={{
                                                    display: 'flex',
                                                    position: 'absolute',
                                                    top: 9,
                                                    right: 0,
                                                    paddingRight: isMobile ? 5 : 10,
                                                    zIndex: 1
                                                }}>
                                                    {(activeMatchItem !== item && !item.matching.attributes.deleted) &&
                                                        <AddIcon
                                                            style={{
                                                                transition: 'all 0.2s ease-in-out',
                                                                textAlign: 'center',
                                                                color: 'white',
                                                                cursor: 'pointer',
                                                                marginTop: isMobile ? 15 : 0,
                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                            }}
                                                            onClick={() => {
                                                                setActiveMatchItem(item);
                                                            }}
                                                        />}
                                                    {((activeMatchItem === item || (activeMatchItem && activeMatchItem.id == item.matching.id)) && !item.matching.attributes.deleted) &&
                                                        <ClearIcon
                                                            style={{
                                                                fontSize: 22,
                                                                marginRight: 2,
                                                                transition: 'all 0.2s ease-in-out',
                                                                textAlign: 'center',
                                                                color: 'white',
                                                                cursor: 'pointer',
                                                                marginTop: isMobile ? 15 : 0,
                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                            }}
                                                            onClick={() => {
                                                                let temp = item;
                                                                if ((activeMatchItem === item || (activeMatchItem && activeMatchItem.id == item.matching.id))) {
                                                                    temp = null;
                                                                }
                                                                setActiveMatchItem(temp);
                                                            }}
                                                        />}
                                                </div>

                                            </div>
                                            <div style={{
                                                position: 'absolute',
                                                zIndex: 1,
                                                transition: 'all 0.05s ease-in-out',
                                                width: 'calc(100% - 4px)',
                                                transform: (activeMatchItem === item || (activeMatchItem && activeMatchItem.id == item.matching.id)) ? 'scaleY(1)' : 'scaleY(0)',
                                                visibility: (activeMatchItem === item || (activeMatchItem && activeMatchItem.id == item.matching.id)) ? 'visible' : 'hidden',
                                                // height: (activeMatchItem === item || (activeMatchItem && activeMatchItem.id == item.matching.id)).text ? (150 + 20) : 40,
                                                // height: (activeMatchItem === item || (activeMatchItem && activeMatchItem.id == item.matching.id)).text ? itemsRef.current[parseInt(index + '' + index2)].offsetHeight : 40,
                                                height: (isMobile ? 'calc(100% - 60px)' : 'calc(100% - 50px)'),
                                                top: isMobile ? 37.5 : 25,
                                                right: 0,
                                                borderBottomLeftRadius: 25,
                                                borderBottomRightRadius: 25,
                                                borderLeft: (activeMatchItem === item || (activeMatchItem && activeMatchItem.id == item.matching.id)) ? '2px solid #C7D300' : 'none',
                                                borderRight: (activeMatchItem === item || (activeMatchItem && activeMatchItem.id == item.matching.id)) ? '2px solid #C7D300' : 'none',
                                                borderBottom: (activeMatchItem === item || (activeMatchItem && activeMatchItem.id == item.matching.id)) ? '2px solid #C7D300' : 'none',
                                                paddingTop: 20
                                            }}>
                                            </div>
                                            <div style={{
                                                transition: 'all 0.1s ease-in-out',
                                                height: '100%',
                                                zIndex: 2,
                                                position: 'relative',
                                                contentVisibility: (activeMatchItem === item || (activeMatchItem && activeMatchItem.id == item.matching.id)) ? 'visible' : 'hidden',
                                                // display: (activeMatchItem === item || (activeMatchItem && activeMatchItem.id == item.matching.id)).text ? 'block' : 'none',
                                                opacity: (activeMatchItem === item || (activeMatchItem && activeMatchItem.id == item.matching.id)) ? 1 : 0,
                                                marginTop: 15,
                                                // marginRight: 50,
                                            }} ref={el => itemsRef.current[parseInt(index)] = el}>
                                                {answeredQuestionsAG ? <>
                                                    <div style={{
                                                        paddingLeft: isMobile ? 20 : 45,
                                                        paddingRight: isMobile ? 5 : 30
                                                    }}>
                                                        <p style={{
                                                            textAlign: 'left',
                                                            fontFamily: 'VagRoundedBold',
                                                            fontSize: 14,
                                                            margin: 0,
                                                            paddingRight: 15
                                                        }} className={'color-blue'}>
                                                            Details zum Job:
                                                        </p>
                                                        <p style={{
                                                            color: '#C7D300',
                                                            textAlign: 'left',
                                                            paddingRight: 15
                                                        }}>
                                                            <span className={'testMode'}><span className={'linkToJob'}
                                                                                               style={{
                                                                                                   textDecoration: 'unset',
                                                                                                   marginRight: 35
                                                                                               }}><a style={{
                                                                color: '#C7D300',
                                                                fontFamily: 'VagRoundedBold',
                                                                textDecoration: 'unset'
                                                            }} target={'_blank'}
                                                                                                     href={ENV.REACT_APP_FRONTEND_URL + '/jobsuche/' + item.jobAdvertisement?.id}>{item.jobAdvertisement?.attributes.title}</a></span></span>
                                                        </p>
                                                        {/*<div style={{color: '#00374e', textAlign: 'left', display: 'flex', flexWrap: 'wrap'}}>*/}
                                                        {/*    {answeredQuestionsAG.filter(i => i.attributes.question_id == 59).map((item, index) => {*/}
                                                        {/*        return (*/}
                                                        {/*            <span key={index} style={{margin: '5px 15px 5px 0', fontSize: 14, background: '#ddd', padding: '7px 20px 5px', borderRadius: 25}}>{item.attributes.answerText}</span>*/}
                                                        {/*        );*/}
                                                        {/*    })}*/}
                                                        {/*</div>*/}
                                                        {console.log(activeMatchItem)}
                                                        {((activeMatchItem && activeMatchItem.matching?.attributes.noteArbeitnehmer)) ?
                                                            <>
                                                                <Grid container>
                                                                    <Grid item xs={12} style={{
                                                                        marginBottom: 20,
                                                                        textAlign: 'left',
                                                                        display: 'flex',
                                                                        alignItems: 'flex-start',
                                                                    }}>
                                                                        <LogoSvgQouselduselmark style={{
                                                                            height: 55,
                                                                            width: isMobile ? '10%' : '35px',
                                                                            maxWidth: 35,
                                                                            fill: '#C7D300',
                                                                            margin: '1em 20px 0',
                                                                            fontStyle: 'italic'
                                                                        }}/>
                                                                        <p style={{
                                                                            textAlign: 'left',
                                                                            fontFamily: 'VagRoundedBold',
                                                                            maxWidth: '60%',
                                                                            whiteSpace: 'pre-wrap'
                                                                        }} className={'color-blue'}>
                                                                            {activeMatchItem.matching.attributes.noteArbeitnehmer}
                                                                        </p>
                                                                    </Grid>
                                                                </Grid></> : null}
                                                    </div>
                                                    {(activeMatchItem && !activeMatchItem.matching?.attributes.matchingArbeitnehmer) ?
                                                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                            {(activeMatchItem && !activeMatchItem.matching?.attributes.declineArbeitnehmer) ?
                                                                <div>
                                                                    <CustomButton
                                                                        buttontype={'gray'}
                                                                        style={{
                                                                            fontSize: 16,
                                                                            width: 'auto',
                                                                            margin: '0 15px 0 15px',
                                                                            padding: '2px 15px 1.43px',
                                                                        }}
                                                                        onClick={() => {
                                                                            updateMatch(item, 'decline', true);
                                                                        }}
                                                                    >Ich habe kein Interesse</CustomButton>
                                                                </div> : null}

                                                            <div>
                                                                <CustomButton
                                                                    buttontype={'blueYellow'}
                                                                    style={{
                                                                        color: '#C7D300',
                                                                        fontSize: 16,
                                                                        width: 'auto',
                                                                        margin: '0 15px 0 0'
                                                                    }}
                                                                    onClick={() => {
                                                                        updateMatch(item, 'confirm');
                                                                    }}
                                                                >Anfrage bestätigen</CustomButton>
                                                                <p style={{
                                                                    color: '#C7D300',
                                                                    margin: '10px 15px 0 0'
                                                                }}>kostenfrei</p>
                                                            </div>
                                                        </div> : null}
                                                    {(activeMatchItem && activeMatchItem.matching.attributes.matchingArbeitnehmer && activeMatchItem.matching.attributes.isBought) ?
                                                        <Grid item xs={12} style={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-end',
                                                            lineHeight: '14px'
                                                        }}>
                                                            <div>
                                                                <CustomButton
                                                                    buttontype={'blueYellow'}
                                                                    style={{
                                                                        color: '#C7D300',
                                                                        fontSize: 16,
                                                                        width: 'auto',
                                                                        margin: '0 15px'
                                                                    }}
                                                                    onClick={() => {
                                                                        history.push('/chat')
                                                                    }}
                                                                >Zum Chat</CustomButton>
                                                            </div>
                                                        </Grid> : null}
                                                </> : <>
                                                    <div ref={loadingSpinnerRef}
                                                         style={{
                                                             //display: 'flex',
                                                             //justifyContent: 'center',
                                                             //alignItems: 'center',
                                                             color: 'white',
                                                             paddingTop: 0,
                                                             paddingLeft: 0,
                                                             paddingRight: 0,
                                                             paddingBottom: 50,
                                                             width: '100%',
                                                             display: 'flex',
                                                             justifyContent: 'center',
                                                             flexDirection: 'column'
                                                         }}>
                                                        <div id={'testLogo'} style={{
                                                            borderRadius: 50,
                                                            width: 60,
                                                            height: 60,
                                                            minWidth: 60,
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            border: '4px solid transparent',
                                                            // cursor: 'pointer',
                                                            transitionDuration: '0.2s',
                                                            transitionProperty: 'transform',
                                                            margin: '0 auto'
                                                        }}
                                                        >
                                                            <div style={{
                                                                padding: 7.5,
                                                                background: 'rgba(0, 55, 78, 1)',
                                                                borderRadius: 50,
                                                                width: 57,
                                                                minWidth: 27,
                                                                height: 35,
                                                                minHeight: 35,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                display: 'flex',
                                                                border: '5px solid rgba(199, 211, 0, 1)'
                                                            }}>
                                                                <TestLogo
                                                                    style={{
                                                                        width: 30,
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>}
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </div>
                    <div style={{
                        display: 'block',
                        transform: !isChecked ? 'translate(20%)' : 'translateX(-100%)',
                        overflow: 'hidden',
                        flex: '0 0 100%'
                    }}>
                        <div
                            style={{
                                position: 'relative',
                                padding: isMobile ? 0 : 50,
                            }}>
                            {(questions && questions.length > 0) ? <>
                                <p style={{
                                    textAlign: 'left',
                                    fontSize: 12,
                                    color: '#C7D300',
                                    marginBottom: 1,
                                }}>Dein Profil ist zu {progress}% fertiggestellt</p>
                                <div style={{
                                    width: 'calc(100% - 5px)',
                                    height: 15,
                                    fontSize: 12,
                                    textDecoration: 'underline',
                                    border: '3px solid #C7D300',
                                    borderRadius: 10,
                                    marginBottom: -2
                                }}>
                                    <div style={{
                                        marginTop: -1,
                                        background: '#C7D300',
                                        width: (progress.toString() + '%'),
                                        height: 'calc(100% + 2px)',
                                        borderBottomLeftRadius: 10,
                                        borderTopLeftRadius: 10
                                    }}></div>
                                </div>
                            </> : null}
                            {(questions && questions.length > 0) ? <div style={{
                                transition: 'all 0.1s ease-in-out',
                                height: '100%',
                                zIndex: 2,
                                position: 'relative',
                                border: '2px solid #C7D300',
                                borderTopLeftRadius: 3,
                                borderTopRightRadius: 3,
                                borderBottomLeftRadius: 20,
                                borderBottomRightRadius: 20
                            }}>
                                <div style={{ padding: isMobile ? 20 : 50 }}>

                                    {(questions) ? <>
                                        <div className={icon} style={{ padding: '10px 0 50px' }}>
                                            <div style={{
                                                textAlign: 'left',
                                                position: 'relative',
                                            }}>
                                                <Grid container style={{ position: 'relative', zIndex: 2 }}>
                                                    {(questions && questions.length > 0) && questions.map((item, index3) => {
                                                        let items = [];
                                                        let test = [];

                                                        if (item.rightAnswers) {
                                                            item.rightAnswers.map((item, index) => {

                                                                items.push(
                                                                    <span key={index + '-0'}
                                                                          style={{ padding: (item == icon && helperText) ? '8px 20px' : '10px 20px 7px 20px' }}
                                                                          className={'answer rightAnswer'}>
                                                                                    {(item == icon && helperText) ? <>{item}<Tooltip
                                                                                        enterTouchDelay={0}
                                                                                        title={helperText}>
                                                                                        <InfoIcon
                                                                                            style={{ marginLeft: 4 }}
                                                                                            fontSize={'14px'}
                                                                                            id={'infoIcon-white'}
                                                                                        />
                                                                                    </Tooltip></> : item}
                                                                                </span>
                                                                )
                                                                test.push(item);
                                                            })
                                                        }
                                                        if (item.answersAN) {
                                                            console.log("ITEM", item)
                                                            item.answersAN.map((answer, index) => {
                                                                const Icon = (führerscheinLogos.hasOwnProperty(answer)) ? führerscheinLogos[answer] : null
                                                                if (!test.includes(answer)) {
                                                                    console.log(item, answer)
                                                                    items.push(
                                                                        <span key={index + '-1'}
                                                                              style={{ padding: (answer == icon && helperText) ? '10px 20px' : '10px 20px 7px 20px' }}
                                                                              className={'answer center-vert'}>
                                                                                               {
                                                                                                   (Icon && item.question_id === "9" && (answer === "FL" || answer === "L")) ?
                                                                                                       <Icon style={{
                                                                                                           marginRight: 10,
                                                                                                           maxHeight: 18,
                                                                                                           width: 35
                                                                                                       }}/> : ""
                                                                                               }
                                                                            {
                                                                                (Icon && item.question_id === "9" && answer !== "FL" && answer !== "L") ?
                                                                                    <Icon style={{
                                                                                        marginRight: 10,
                                                                                        maxHeight: 15,
                                                                                        width: 35
                                                                                    }}/> : ""
                                                                            }
                                                                            {(answer == icon && helperText) ? <>{answer}
                                                                                <Tooltip enterTouchDelay={0}
                                                                                         title={helperText}>
                                                                                    <InfoIcon
                                                                                        style={{ marginLeft: 4 }}
                                                                                        fontSize={'14px'}
                                                                                        id={'infoIcon'}
                                                                                    />
                                                                                </Tooltip></> : answer}
                                                                                </span>
                                                                    )
                                                                }
                                                            })
                                                        }

                                                        if (item.rightAnswers3) {
                                                            item.rightAnswers3.map((item, index) => {
                                                                items.push(
                                                                    <span key={index + '-2'}
                                                                          style={{ padding: (item == icon && helperText) ? '8px 20px' : '10px 20px 7px 20px' }}
                                                                          className={'answer rightAnswer'}>
                                                                                    {(item == icon && helperText) ? <>{item}<Tooltip
                                                                                        enterTouchDelay={0}
                                                                                        title={helperText}>
                                                                                        <InfoIcon
                                                                                            style={{ marginLeft: 4 }}
                                                                                            fontSize={'14px'}
                                                                                            id={'infoIcon-white'}
                                                                                        />
                                                                                    </Tooltip></> : item}
                                                                                </span>
                                                                )
                                                                test.push(item);
                                                            })
                                                        }

                                                        if (item.answersAN3) {
                                                            item.answersAN3.map((item, index) => {

                                                                if (!test.includes(item)) {
                                                                    items.push(
                                                                        <span key={index + '-3'}
                                                                              style={{ padding: (item == icon && helperText) ? '10px 20px' : '10px 20px 7px 20px' }}
                                                                              className={'answer'}>
                                                                                    {(item == icon && helperText) ? <>{item}<Tooltip
                                                                                        enterTouchDelay={0}
                                                                                        title={helperText}>
                                                                                        <InfoIcon
                                                                                            style={{ marginLeft: 4 }}
                                                                                            fontSize={'14px'}
                                                                                            id={'infoIcon'}
                                                                                        />
                                                                                    </Tooltip></> : item}
                                                                                </span>
                                                                    )
                                                                }
                                                            })
                                                        }


                                                        if (item.text) {
                                                            return <>
                                                                {index3 == 0 ? <Grid item xs={12} style={{
                                                                    textAlign: 'left',
                                                                    fontFamily: 'VagRoundedBold',
                                                                    fontSize: 16,
                                                                    margin: 0,
                                                                    marginBottom: 10
                                                                }} className={'color-blue'}>
                                                                    Übersicht
                                                                </Grid> : null}
                                                                {(items && items.length > 0) && <div
                                                                    style={{
                                                                        minWidth: (item.question_id == 1 || item.question_id == 2 || item.question_id == 12 || width < 1100) ? '100%' : '100%',
                                                                        maxWidth: '50%',
                                                                        display: 'flex',
                                                                        flexWrap: 'wrap',
                                                                    }}
                                                                    key={index3}>
                                                                    <div style={{
                                                                        minWidth: 'fit-content',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        {item.text}
                                                                    </div>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        flexWrap: 'wrap',
                                                                        marginBottom: 'auto',
                                                                        lineHeight: 18,
                                                                        paddingLeft: 5
                                                                    }}>
                                                                        {items}
                                                                    </div>
                                                                </div>}
                                                                {item.question_id == 1 ? <Grid item xs={12} style={{
                                                                    textAlign: 'left',
                                                                    fontFamily: 'VagRoundedBold',
                                                                    fontSize: 16,
                                                                    margin: 0,
                                                                    marginBottom: 10
                                                                }} className={'color-blue'}>
                                                                    Qualifikationen
                                                                </Grid> : null}
                                                            </>
                                                        }

                                                    })}
                                                </Grid>
                                            </div>
                                            {/*{((questions && questions.filter(i => i.question_id == 10).length > 0) && questions.filter(i => i.question_id == 10)[0].answersAN && questions.filter(i => i.question_id == 10)[0].answersAN[0]) ?*/}
                                            {/*    <Grid container>*/}
                                            {/*        <Grid item xs={12} style={{*/}
                                            {/*            marginTop: 20,*/}
                                            {/*            textAlign: 'left',*/}
                                            {/*            display: 'flex',*/}
                                            {/*            alignItems: 'center',*/}
                                            {/*        }}>*/}
                                            {/*            <LogoSvgQouselduselmark style={{*/}
                                            {/*                height: 55,*/}
                                            {/*                width: '75px',*/}
                                            {/*                fill: '#C7D300',*/}
                                            {/*                margin: '0 20px',*/}
                                            {/*                fontStyle: 'italic'*/}
                                            {/*            }}/>*/}
                                            {/*            <p style={{*/}
                                            {/*                textAlign: 'left',*/}
                                            {/*                fontFamily: 'VagRoundedBold',*/}
                                            {/*                maxWidth: '60%'*/}
                                            {/*            }} className={'color-blue'}>*/}
                                            {/*                {questions.filter(i => i.question_id == 10)[0].answersAN[0]}*/}
                                            {/*            </p>*/}
                                            {/*        </Grid>*/}
                                            {/*    </Grid> : null}*/}
                                        </div>

                                        <Grid item xs={12} style={{
                                            textAlign: 'left',
                                            fontFamily: 'VagRoundedBold',
                                            fontSize: 16,
                                            margin: 0,
                                            marginBottom: 10
                                        }} className={'color-blue'}>
                                            Beschreibung
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} style={{
                                                marginTop: 20,
                                                textAlign: 'left',
                                                display: 'flex',
                                                // alignItems: 'center',
                                            }}>
                                                <LogoSvgQouselduselmark style={{
                                                    height: isMobile ? 40 : 55,
                                                    display: 'block',
                                                    width: 'auto',
                                                    fill: '#C7D300',
                                                    margin: '0 20px',
                                                    fontStyle: 'italic'
                                                }}/>
                                                <div id={"beschreibung"} style={{
                                                    display: savedDescription.length == 0 ? 'block' : 'flex',
                                                    flexDirection: isMobile ? 'column' : 'row',
                                                    alignItems: savedDescription.length == 0 ? 'unset' : 'end',
                                                    marginLeft: savedDescription.length == 0 ? 0 : 5,
                                                    textAlign: 'left',
                                                    width: '100%',
                                                    maxWidth: savedDescription.length == 0 ? isMobile ? 'calc(100% - 110px)' : 'calc(100% - 165px)' : isMobile ? 'calc(100% - 105px)' : 'calc(100% - 130px)',
                                                    borderRadius: savedDescription.length == 0 ? isMobile ? 10 : ENV.REACT_APP_BORDER_RADIUS : 15,
                                                    background: !editDescription && savedDescription.length != 0 ? 'unset' : '#C7D300',
                                                    padding: savedDescription.length == 0 ? isMobile ? 15 : 30 : 10,
                                                    color: 'white',
                                                    position: 'relative'
                                                }}>
                                                    {(editDescription || savedDescription.length == 0) ? <>
                                                        {savedDescription.length == 0 ? <>
                                                            <p style={{ fontSize: 22, marginTop: 0, marginBottom: 0 }}>
                                                                <span style={{ fontFamily: 'VagRoundedBold' }}>Vervollständige dein anonymes Profil!</span> Füge
                                                                hier eine Beschreibung hinzu.</p>
                                                            <p style={{ fontSize: 14, marginTop: 5 }}
                                                               className={'color-blue'}>Jeder Mensch ist einzigartig und
                                                                du bist es auch - erzähle hier von weiteren
                                                                Qualifikationen, Fähigkeiten oder persönlichen
                                                                Eigenschaften, um dich von anderen Bewerbenden
                                                                abzuheben</p>
                                                        </> : null}

                                                        <TextareaAutosize value={description}
                                                                          onChange={(e) => setDescription(e.target.value)}
                                                                          className={'descriptionAnonymSwiitchen'}
                                                                          style={{
                                                                              width: isMobile ? 'calc(100% - 20px)' : 'calc(100% - 70px)',
                                                                              maxWidth: isMobile ? 'calc(100% - 20px)' : 'calc(100% - 70px)',
                                                                              borderColor: 'transparent !important',
                                                                              borderRadius: savedDescription.length == 0 ? isMobile ? 10 : 30 : 15,
                                                                              padding: isMobile ? 10 : 35
                                                                          }} minRows={5} placeholder={placeholderText}>
                                                        </TextareaAutosize>

                                                        <div>
                                                            {savedDescription.length == 0 ? <span style={{
                                                                float: 'left',
                                                                paddingLeft: isMobile ? 0 : 30,
                                                                marginTop: isMobile ? 10 : 0
                                                            }}>(optional)</span> : null}
                                                            <CustomButton
                                                                buttontype={'blue'}
                                                                onClick={() => {
                                                                    // history.push('/anonym-swiitchen')
                                                                    updateUser();
                                                                }}
                                                                style={{
                                                                    float: 'right',
                                                                    margin: savedDescription.length == 0 ? 0 : (isMobile ? '10px' : '0') + ' 0 0 10px',
                                                                    height: 40.13
                                                                }}
                                                            >Speichern</CustomButton>
                                                        </div>
                                                    </> : null}

                                                    {(!editDescription && savedDescription.length != 0) ? <div>
                                                        <p style={{
                                                            paddingLeft: isMobile ? 10 : 24,
                                                            paddingRight: isMobile ? 10 : 50,
                                                            color: '#00374e',
                                                            fontFamily: 'VagRoundedBold'
                                                        }}>{description}</p>
                                                        <EditIcon
                                                            className={'not-yellow'}
                                                            style={{
                                                                position: 'absolute',
                                                                right: 15,
                                                                top: 15,
                                                                height: 30,
                                                                width: 30,
                                                                marginRight: 2,
                                                                transition: 'all 0.2s ease-in-out',
                                                                textAlign: 'right',
                                                                fill: '#00374e',
                                                                cursor: 'pointer',
                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                            }}
                                                            onClick={() => {
                                                                console.log('EDIT')
                                                                setEditDescription(true);
                                                            }}
                                                        />
                                                    </div> : null}
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <div style={{ height: 50 }}></div>
                                    </> : <>
                                        <div ref={loadingSpinnerRef}
                                             style={{
                                                 //display: 'flex',
                                                 //justifyContent: 'center',
                                                 //alignItems: 'center',
                                                 color: 'white',
                                                 paddingTop: 0,
                                                 paddingLeft: 0,
                                                 paddingRight: 0,
                                                 paddingBottom: 50,
                                                 width: '100%',
                                                 display: 'flex',
                                                 justifyContent: 'center',
                                                 flexDirection: 'column'
                                             }}>
                                            <div id={'testLogo'} style={{
                                                borderRadius: 50,
                                                width: 60,
                                                height: 60,
                                                minWidth: 60,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'flex',
                                                border: '4px solid transparent',
                                                // cursor: 'pointer',
                                                transitionDuration: '0.2s',
                                                transitionProperty: 'transform',
                                                margin: '0 auto'
                                            }}
                                            >
                                                <div style={{
                                                    padding: 7.5,
                                                    background: 'rgba(0, 55, 78, 1)',
                                                    borderRadius: 50,
                                                    width: 57,
                                                    minWidth: 27,
                                                    height: 35,
                                                    minHeight: 35,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    border: '5px solid rgba(199, 211, 0, 1)'
                                                }}>
                                                    <TestLogo
                                                        style={{
                                                            width: 30,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>}


                                </div>
                            </div> : <>
                                <p>Du bist derzeit auf Jobsuche?</p>
                                <CustomButton
                                    buttontype={'yellowBlue'}
                                    onClick={() => {
                                        history.push('/anonym-swiitchen')
                                    }}
                                    style={{
                                        height: 40.13
                                    }}
                                >JETZT ANONYM SWIITCHEN</CustomButton>
                            </>}
                        </div>
                    </div>
                </div>
            </Grid>
            <Modal
                open={onConfirm}
                onClose={() => setOnConfirm(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={onConfirmStyle}>
                    <div style={{
                        display: 'flex',
                        position: 'absolute',
                        top: 9,
                        right: 0,
                        paddingRight: 15,
                        zIndex: 1
                    }}>
                        <ClearIcon
                            className={'not-yellow'}
                            onClick={() => {
                                setOnConfirm(false);
                            }}
                            style={{
                                height: 35,
                                width: 35,
                                marginRight: 2,
                                transition: 'all 0.2s ease-in-out',
                                textAlign: 'center',
                                fill: 'white',
                                cursor: 'pointer',
                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                            }}
                        />
                    </div>
                    <h3 style={{
                        color: 'white',
                        fontFamily: 'VagRoundedBold',
                        marginTop: 40,
                        fontSize: 22
                    }}>Prima!</h3>
                    <p style={{ color: 'white' }}>Dieses Unternehmen wird sich schnellstmöglich via Chat mit dir in
                        Verbindung setzen.</p>
                    <p style={{ color: 'white' }}>Nach wie vor bist du zu 100 % anonym.</p>
                </Box>
            </Modal>
            <Modal
                open={onDecline}
                onClose={() => setOnDecline(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={onDeclineStyle}>
                    <div style={{
                        display: 'flex',
                        position: 'absolute',
                        top: 9,
                        right: 0,
                        paddingRight: 15,
                        zIndex: 1
                    }}>
                        <ClearIcon
                            className={'not-yellow'}
                            onClick={() => {
                                setOnDecline(false);
                            }}
                            style={{
                                height: 35,
                                width: 35,
                                marginRight: 2,
                                transition: 'all 0.2s ease-in-out',
                                textAlign: 'center',
                                fill: 'white',
                                cursor: 'pointer',
                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                            }}
                        />
                    </div>
                    <h3 style={{ color: 'white', fontFamily: 'VagRoundedBold', marginTop: 40, fontSize: 22 }}>Vielen
                        Dank, dass du fair bist!</h3>
                    <p style={{ color: 'white' }}>Wir haben dieses Unternehmen informiert, dass du aktuell kein
                        Interesse an diesem Jobangebot hast.</p>
                    <p style={{ color: 'white' }}>Solltest du deine Meinung ändern, bestätige die Anfrage und tritt via
                        Chat in Kontakt - jederzeit möglich.</p>
                </Box>
            </Modal>
            <Modal
                open={onEdit}
                onClose={() => setOnEdit(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={onEditStyle}>
                    <div style={{
                        display: 'flex',
                        position: 'absolute',
                        top: 9,
                        right: 0,
                        paddingRight: 15,
                        zIndex: 1
                    }}>
                        <ClearIcon
                            className={'not-yellow'}
                            onClick={() => {
                                setOnEdit(false);
                            }}
                            style={{
                                height: 35,
                                width: 35,
                                marginRight: 2,
                                transition: 'all 0.2s ease-in-out',
                                textAlign: 'center',
                                fill: 'white',
                                cursor: 'pointer',
                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                            }}
                        />
                    </div>
                    <h3 style={{
                        color: 'white',
                        fontFamily: 'VagRoundedBold',
                        marginTop: 40,
                        fontSize: 22
                    }}>Bearbeiten</h3>
                    <p style={{ color: 'white', margin: '5px 0' }}>Bearbeite deine Daten. </p>
                    <p style={{ color: 'white', margin: '5px 0' }}>Deine bestehende Matches bleiben erhalten. </p>
                    <CustomButton
                        buttontype={'white'}
                        style={{
                            color: '#C7D300',
                            fontSize: 16,
                            width: 'auto',
                            margin: '0 10px'
                        }}
                        onClick={() => {
                            setMenuState(false)
                            history.push('/anonym-swiitchen')
                        }}
                    >Bearbeiten</CustomButton>
                </Box>
            </Modal>
            <Modal
                open={onDelete}
                onClose={() => setOnDelete(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={onDeleteStyle}>
                    <div style={{
                        display: 'flex',
                        position: 'absolute',
                        top: 9,
                        right: 0,
                        paddingRight: 15,
                        zIndex: 1
                    }}>
                        <ClearIcon
                            className={'not-yellow'}
                            onClick={() => {
                                setOnDelete(false);
                            }}
                            style={{
                                height: 35,
                                width: 35,
                                marginRight: 2,
                                transition: 'all 0.2s ease-in-out',
                                textAlign: 'center',
                                fill: 'white',
                                cursor: 'pointer',
                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                            }}
                        />
                    </div>
                    <h3 style={{ color: 'white', fontFamily: 'VagRoundedBold', marginTop: 40, fontSize: 22 }}>Bist du
                        sicher?</h3>
                    <p style={{ color: 'white' }}>Dein anonymes Profil wird Unternehmen nicht mehr sichtbar sein. </p>
                    <p style={{ color: 'white' }}>Deine Matches lösen sich automatisch auf. </p>
                    <CustomButton
                        buttontype={'white'}
                        style={{
                            color: '#C7D300',
                            fontSize: 16,
                            width: 'auto',
                            margin: '0 10px'
                        }}
                        onClick={() => {
                            deleteAnsweredQuestions();
                        }}
                    >Löschen</CustomButton>
                </Box>
            </Modal>
        </Grid>
    );
}
