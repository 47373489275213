import React, {useEffect, useRef, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {ReactComponent as AbsendenSvg} from '../images/svg/absenden.svg';
import {Grid, IconButton} from "@mui/material";
import {useHistory, useLocation} from "react-router";
import './Chat.css';
import SearchIcon from '@mui/icons-material/Search';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ReactComponent as LogoPlus} from "../images/svg/plus.svg";
import {ReactSession} from "react-client-session";
import useFetch from "./UseFetch";
import moment from "moment";
import {isMobile} from "react-device-detect";
import useWindowDimensions from "./useWindowDimensions";
import InfiniteScroll from "react-infinite-scroll-component";

const useQuery = () => new URLSearchParams(useLocation().search);


export default function Chat(props) {

    const {theme, locationChange, unreadChats, login, menuAppBarRef} = props;
    const location = useLocation();
    const api = useFetch();
    const history = useHistory();
    const query = useQuery();

    const [message, setMessage] = useState('');
    const pageNumber = useRef(1);
    const mode = useRef(false);
    const [hasMoreData, setHasMoreData] = useState(false);
    const [expandState, setExpandState] = useState(false);
    const selectedMatching = useRef(false);
    const [chatMatching, setChatMatching] = useState(false);
    const [matchings, setMatchings] = useState([]);
    const intervalRef = useRef();

    const bottomRef = useRef(null);

    useEffect(() => {
        if (!ReactSession.get('user'))  {
            menuAppBarRef.current.logNeeded();
            history.push('/');
        }
        else {
            document.title = 'swiitcher';
            locationChange(location);
            start();

            return () => {
                console.log('callback')
                if (intervalRef && intervalRef.current) {
                    console.log('clear interval')
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                    setChatMatching(null);
                }
            }
        }
    }, [])

    const start = async () => {
        await getData();
    }

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.charCode === 13 && message !== '') {
            createMessage();
        }
    };

    const createMessage = async () => {
        let url = ENV.REACT_APP_API_URL + '/chat-messages';
        let userId = ReactSession.get('userId');
        console.log('createMessage');

        let postData = {
            data: {
                attributes: {
                    matching_id: selectedMatching.current.matching.id,
                    message
                }
            }
        }

        if (ReactSession.get('user') == 'Arbeitnehmer') {
            postData['data']['attributes']['arbeitnehmerUnread'] = 0;
            postData['data']['attributes']['arbeitgeberUnread'] = 1;
        }
        else if (ReactSession.get('user') == 'Arbeitgeber') {
            postData['data']['attributes']['arbeitgeberUnread'] = 0;
            postData['data']['attributes']['arbeitnehmerUnread'] = 1;
        }

        const {response, data} = await api(url, 'POST', JSON.stringify(postData));


        if (response && response.status == 201) {
            setMessage('');
            getData();
            getMessages();
        }
    }

    const getMessages = async () => {
        let moreData = true;
        let rows = [];
        let ids = [];
        for (let i = 1; i < pageNumber.current + 1; i++) {
            let url = ENV.REACT_APP_API_URL + '/chat-messages?page%5Bsize%5D=20&page%5Bnumber%5D=' + i + '&filter[matching_id]=' + selectedMatching.current.matching.id;
            const {response, data} = await api(url);


            if (response && response.status == 200) {
                data.data.map((item, index) => {
                    if (!rows.includes(item)) {
                        rows.push(item);
                    }
                })

            }

            if (data.meta.pagination.total === rows.length) {
                moreData = false;
            }
        }

        // rows.map((item, index) => {
        //     let userId = ReactSession.get('userId')
        //     let unread = false;
        //     let chatData = {};
        //     if ((item.attributes.author_id == userId && item.attributes.arbeitgeberUnread == 1)) {
        //         updateChatMessage(item.id, {
        //             attributes: {
        //                 arbeitgeberUnread: 0
        //             }
        //         });
        //     } else if ((item.attributes.arbeitnehmer_id == userId && item.attributes.arbeitnehmerUnread == 1)) {
        //         updateChatMessage(item.id, {
        //             attributes: {
        //                 arbeitnehmerUnread: 0
        //             }
        //         });
        //     }
        // })

        if (selectedMatching.current) {
            setHasMoreData(moreData);
            // let t = Object.assign({}, selectedMatching.current);
            // t.messages = rows.reverse();

            let scrollDown = false;
            if (selectedMatching.current && rows.length > selectedMatching.current.messages.length && mode.current != 'scrollUp') {
                scrollDown = true;
            } else if (mode.current == 'scrollUp') {
                mode.current = false;
            }
            selectedMatching.current.messages = rows;
            //
            // selectedMatching.current.createdAt = new Date(Math.max(...selectedMatching.current.messages.map(e => new Date(e.attributes.createdAt))));

            updateUnread(selectedMatching);

            if (bottomRef.current && scrollDown) {
                bottomRef.current.scrollTop = 10000;
            }
            getData();
        }

    }

    const startPull = () => {
        if (selectedMatching.current) {
            updateUnread(selectedMatching)
            if (!intervalRef) {
                getMessages();
                let id = setInterval(() => {
                    getMessages();
                    }, 5000);

                intervalRef.current = id;
                setChatMatching(selectedMatching.current.matching.id);
            } else if (selectedMatching.current.matching.id !== chatMatching) {
                setChatMatching(selectedMatching.current.matching.id);
                clearInterval(intervalRef.current);
                intervalRef.current = null;
                getMessages();
                let id = setInterval(() => {
                    getMessages()
                }, 5000)
                intervalRef.current = id
            }
        } else {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
                setChatMatching(null);
            }
        }
    }

    const updateUnread = (selectedMatching) => {
        let userId = ReactSession.get('userId')
        let unread = false;
        let chatData = {};
        if ((selectedMatching.current.matching.attributes.arbeitgeber_id == userId && selectedMatching.current.matching.attributes.matchingChatArbeitgeberUnread == 1) || (selectedMatching.current.matching.attributes.arbeitgeber_id == userId && selectedMatching.current.messages && selectedMatching.current.messages.length > 0 && selectedMatching.current.messages.filter(i => selectedMatching.current.matching.id == i.attributes.matching_id && userId != i.attributes.author_id && i.attributes.arbeitgeberUnread == 1).length > 0)) {
            console.log('arbeitgeber')
            selectedMatching.current.messages.filter(i => selectedMatching.current.matching.id == i.attributes.matching_id && userId != i.attributes.author_id && i.attributes.arbeitgeberUnread == 1).map(async (item, index) => {
                await updateChatMessage(item.id, {
                    attributes: {
                        arbeitgeberUnread: 0
                    }
                });
            })
            chatData = {
                attributes: {
                    matchingChatArbeitgeberUnread: 0
                }
            }
        } else if ((selectedMatching.current.matching.attributes.arbeitnehmer_id == userId && selectedMatching.current.matching.attributes.matchingChatArbeitnehmerUnread == 1) || (selectedMatching.current.matching.attributes.arbeitnehmer_id == userId && selectedMatching.current.messages && selectedMatching.current.messages.length > 0 && selectedMatching.current.messages.filter(i => selectedMatching.current.matching.id == i.attributes.matching_id && userId != i.attributes.author_id && i.attributes.arbeitnehmerUnread == 1).length > 0)) {
            console.log('arbeitnehmer')
            selectedMatching.current.messages.filter(i => selectedMatching.current.matching.id == i.attributes.matching_id && userId != i.attributes.author_id && i.attributes.arbeitnehmerUnread == 1).map(async (item, index) => {
                await updateChatMessage(item.id, {
                    attributes: {
                        arbeitnehmerUnread: 0
                    }
                });
            })
            chatData = {
                attributes: {
                    matchingChatArbeitnehmerUnread: 0
                }
            }
        }
        if (Object.keys(chatData).length > 0) updateMatch(chatData);
        getData();
    }

    const updateMatch = async (patchData) => {
        let url = ENV.REACT_APP_API_URL + '/matching/' + selectedMatching.current.matching.id;


        const {response, data} = await api(url, 'PATCH', JSON.stringify({
            data: patchData
        }));

        if (response && response.status == 200) {
            getData();
        } else {
            //notify('API Fehler: ' + response.status, true);
        }
    }

    const updateChatMessage = async (id, patchData) => {
        console.log('updateChatMessage')
        console.log(patchData)
        let url = ENV.REACT_APP_API_URL + '/chat-message/' + id;


        const {response, data} = await api(url, 'PATCH', JSON.stringify({
            data: patchData
        }));

        console.log(data);

        if (response && response.status == 200) {
            getData();
        } else {
            //notify('API Fehler: ' + response.status, true);
        }
    }

    const truncate = (str, n) => {
        return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
    };

    const getData = async () => {
        let url = ENV.REACT_APP_API_URL + '/matchings?include=jobAdvertisement,arbeitgeber,arbeitnehmer,chatMessages' + '&filter%5Btype%5D%5Beq%5D=chat';

        //if (this.state.sort) url += '&sort=' + this.state.sort;

        let {response, data} = await api(url)

        let rows = [];
        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            //history.push('/arbeitgeber');
            return;
        }

        let activeItemToSet = null;
        if (data && data.data) {
            data.data.map((item, index) => {
                let temp = {};
                temp.matching = item;
                temp.jobAdvertisement = data.included.filter(i => i.type == 'job-advertisements' && item.attributes.job_advertisement_id == i.id)[0];
                temp.arbeitgeber = data.included.filter(i => i.type == 'users' && item.attributes.arbeitgeber_id == i.id)[0];
                temp.arbeitnehmer = data.included.filter(i => i.type == 'users' && item.attributes.arbeitnehmer_id == i.id)[0];
                // temp.messages = matchings && matchings[index] && matchings[index].messages ? matchings[index].messages : data.included.filter(i => i.type == 'chat-messages' && item.id == i.attributes.matching_id).reverse(); // .reverse()
                temp.messages = data.included.filter(i => i.type == 'chat-messages' && item.id == i.attributes.matching_id).reverse(); // .reverse()

                if (ReactSession.get('user') == 'Arbeitgeber' && item.attributes.matchingArbeitnehmer == 1 && item.attributes.matchingArbeitgeber == 1 && item.attributes.isBought == 1) {
                    rows.push(temp);
                } else if (ReactSession.get('user') == 'Arbeitnehmer' && item.attributes.matchingArbeitnehmer == 1 && item.attributes.matchingArbeitgeber == 1 && item.attributes.isBought == 1) {
                    rows.push(temp);
                }
                if (item.id == query.get('id')) activeItemToSet = temp;

            })

            rows.sort((a, b) =>
                (a.messages && a.messages.length > 0 ? a.messages[0].attributes.updatedAt : a.matching.attributes.updatedAt) >
                (b.messages && b.messages.length > 0 ? b.messages[0].attributes.updatedAt : b.matching.attributes.updatedAt) ? -1 : 1);

            setMatchings(rows);
            if (activeItemToSet) {
                selectedMatching.current = activeItemToSet;
                updateUnread(selectedMatching);
                window.history.replaceState({}, '', window.location.pathname);
            }
        } else if (response.error) {

        }
    }

    const newData = () => {
        console.log('newData')
        if (hasMoreData === true) {
            let pageN = pageNumber.current;
            let temp = ++pageN;
            mode.current = 'scrollUp';
            pageNumber.current = temp;
        }
    }


    return (
        <React.Fragment>
            <Grid container className="mainApp" style={{margin: '64px 0 0 0', padding: isMobile ? '30px 10px 50px' : '30px 50px 50px',}}>
                <Grid item xs={isMobile ? 12 : 4} className="leftPanel" style={{padding: '0 0 10px 0', height: isMobile ? 'fit-content' : null}}>
                    <p className={'headerName'}>
                        Chats & Nachrichten
                        {unreadChats ? <span className={'count'} style={{
                            top: -15,
                            right: 45,
                            width: 10,
                            height: 10
                        }}>{unreadChats}</span> : null}
                    </p>
                    <div id="inputWrapper" style={{marginTop: 8}}>
                        <input id="input" type="search"/>
                        <IconButton className={'expandIcon'} onClick={() => setExpandState(!expandState)}>
                            {expandState ?
                                <ExpandMoreIcon/> :
                                <ExpandLessIcon/>}
                        </IconButton>

                        <SearchIcon id="icon"/>
                    </div>
                    <div className="chats" style={{height: isMobile ? 'fit-content' : null}}>
                        {matchings.map((item, index) => {
                            let userId = ReactSession.get('userId')
                            let unread = false;

                            if ((item.matching.attributes.arbeitgeber_id == userId && (item.matching.attributes.matchingChatArbeitgeberUnread == 1 || item.messages.filter(i => i.attributes.arbeitgeberUnread == 1 && userId != i.attributes.author_id && i.attributes.matching_id == item.matching.id).length > 0)) ||
                                (item.matching.attributes.arbeitnehmer_id == userId && (item.matching.attributes.matchingChatArbeitnehmerUnread == 1 || item.messages.filter(i => i.attributes.arbeitnehmerUnread == 1 && userId != i.attributes.author_id && i.attributes.matching_id == item.matching.id).length > 0))
                            ) {
                                unread = true;
                            }
                            return (
                                <>
                                    <div
                                        className={'chatButton ' + (selectedMatching && selectedMatching.current && selectedMatching.current.matching.id === item.matching.id ? 'activeChat ' : unread ? 'unread' : '')}
                                        onClick={() => {
                                            if (!selectedMatching.current || selectedMatching.current.matching.id != item.matching.id) {
                                                selectedMatching.current = item;
                                                startPull();
                                            }
                                        }}>
                                        <div className="chatInfo">
                                            <div className={'wrapper'}>
                                                {/*<p className="name">{(item.arbeitgeber && item.arbeitgeber.id != userId) ? item.arbeitgeber.attributes.companyName : (item.arbeitnehmer) ? item.jobAdvertisement.attributes.title : ''}</p>*/}
                                                <p className="name">{item.jobAdvertisement.attributes.title}</p>
                                                <p className="message">{(item.messages && item.messages.length > 0 && !item.messages[0].attributes.message.startsWith('Info!')) &&
                                                    truncate(item.messages[0].attributes.message, 10)}</p>
                                            </div>
                                            <div className="status"><p
                                                // className="date">{moment(((item.messages && item.messages[0] && item.messages[0].attributes.createdAt) ?? item.matching.attributes.updatedAt)).fromNow()}</p>{/*<p className="count">10</p>*/}
                                                className="date">{moment((item.messages && item.messages[0] ? item.messages[0].attributes.createdAt : item.matching.attributes.updatedAt)).fromNow()}</p>{/*<p className="count">10</p>*/}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </Grid>

                <Grid item xs={isMobile ? 12 : 8} className="rightPanel">
                    {(selectedMatching && selectedMatching.current) &&
                        <>
                            <div className="topBar">
                                <div className="leftSide">
                                    <p className="chatName">{selectedMatching.current.arbeitgeber.id != ReactSession.get('userId') ?
                                        <Grid container style={{textAlign: 'left'}}>
                                            {isMobile ?
                                            <>
                                                <Grid item xs={12}>
                                                    {selectedMatching.current.arbeitgeber.attributes.companyName}:
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {selectedMatching.current.jobAdvertisement.attributes.title}
                                                </Grid>
                                            </> :
                                                <>
                                                    <Grid item xs={12}>
                                                        {selectedMatching.current.arbeitgeber.attributes.companyName}: {selectedMatching.current.jobAdvertisement.attributes.title}
                                                    </Grid>
                                                </>}
                                        </Grid>
                                        : 'Arbeitsuchender #' + selectedMatching.current.arbeitnehmer.id}</p>
                                    <p className="chatStatus">{moment(selectedMatching.current.matching.attributes.updatedAt).format('DD.MM.YYYY')} | {moment(selectedMatching.current.matching.attributes.updatedAt).format('HH:mm:ss')}</p>
                                </div>
                                <div className="rightSide">
                                </div>
                            </div>
                            <div style={{width: '100%'}}>
                                <div id={'scrollableDiv'} className="messageHistory userBg" ref={bottomRef} style={{
                                    position: 'relative',
                                    // overflowY: selectedMatching.matching.attributes.deleted === 1 ? 'hidden' : 'none',
                                    height: isMobile ? '100%' : null,
                                    minHeight: isMobile ? '200px' : null,
                                    maxHeight: isMobile ? '300px' : null,
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}>
                                    <InfiniteScroll
                                        dataLength={(selectedMatching && selectedMatching.current && selectedMatching.current.messages) ? selectedMatching.current.messages.length : 0} //This is important field to render the next data
                                        next={newData}
                                        hasMore={hasMoreData}
                                        loader={<h4>Nachrichten werden geladen...</h4>}
                                        endMessage={
                                            <p style={{textAlign: 'center'}}>
                                            </p>
                                        }
                                        scrollableTarget="scrollableDiv"
                                        inverse={true}
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column-reverse'
                                        }}
                                    >
                                        {(selectedMatching && selectedMatching.current && selectedMatching.current.messages) && selectedMatching.current.messages.map((item, index) => {
                                            let userId = ReactSession.get('userId');

                                            if (!item.attributes.profilDeleted && !item.attributes.jobAdvertisementDeleted ||
                                                item.attributes.profilDeleted && item.attributes.author_id != userId ||
                                                item.attributes.jobAdvertisementDeleted && item.attributes.author_id != userId
                                            )

                                            return (
                                                <>
                                                    <div style={{width: '100%'}}>
                                                        <div
                                                            className={'msg ' + (item.attributes.profilDeleted ? 'system' : item.attributes.author_id == userId ? 'messageSent' : 'messageReceived')}>
                                                            {/*<span className={'messageSenderName'}>MeinName</span><br/>*/}
                                                            <span className={'message'}>{item.attributes.message}</span>
                                                            <span
                                                                className="timestamp">{moment(item.attributes.updatedAt).fromNow()}</span>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </InfiniteScroll>

                                    {/*{(selectedMatching.current && selectedMatching.current.matching.attributes.deleted === 1) &&*/}
                                    {/*    <div className={'color-blue'} style={{*/}
                                    {/*        position: 'absolute',*/}
                                    {/*        fontFamily: 'VagRoundedBold',*/}
                                    {/*        display: 'flex',*/}
                                    {/*        alignItems: 'center',*/}
                                    {/*        justifyContent: 'center',*/}
                                    {/*        width: '100%',*/}
                                    {/*        height: '100%',*/}
                                    {/*        // border: item.attributes.active === 0 ? '3px solid rgb(100,100,100)' : 'unset',*/}
                                    {/*        borderRadius: 20,*/}
                                    {/*        top: 0,*/}
                                    {/*        left: 0,*/}
                                    {/*        background: 'rgba(222,222,222,0.95)'*/}
                                    {/*    }}>*/}
                                    {/*        Schade! Diese Stellenanzeige ist nicht mehr verfügbar&nbsp;*/}
                                    {/*    </div>}*/}
                                </div>
                            </div>
                            <div className="replyBar">
                                <div id="inputWrapper">
                                    <input
                                        id="input2"
                                        type="search"
                                        onChange={(e) => setMessage(e.target.value)}
                                        value={message}
                                        onKeyPress={handleKeypress}
                                    />

                                    <AbsendenSvg
                                        id={'icon2'}
                                        onClick={(e) => {
                                            createMessage();
                                        }}

                                    />
                                </div>
                            </div>
                        </>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

