import * as React from 'react';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Box, IconButton, InputAdornment, InputBase, Link, Modal, } from "@mui/material";
import MenuCloseIcon from '@mui/icons-material/Menu';
import { ReactComponent as LogoSvg } from './images/svg/swiitcher_logo.svg';
import { ReactComponent as LogoSvgWhite } from './images/svg/swiitcher_logo_weiss.svg';
import { useHistory } from "react-router-dom";
import { ReactSession } from "react-client-session";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import useFetch from "./components/UseFetch";
import { CssTextField3 } from "./components/CssTextField3";
import { styled } from "@mui/styles";
import { CustomButton } from "./components/CustomButton";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { PriorityHigh, Visibility, VisibilityOff } from "@mui/icons-material";
import { isMobile } from "react-device-detect";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label': {
        margin: 0,
        padding: 0,
        marginLeft: 5,
        top: -14,
        fontSize: '12px !important',
        color: 'red',
    },
    '& .MuiInputBase-input': {
        borderRadius: 25,
        position: 'relative',
        border: '2px solid #C7D300',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
}));

const MenuAppBar = forwardRef((props, ref) => {
        const { getHistory, overAllCount, menuState, userT, update, notify, location, changeMenuState } = props;
        const history = useHistory();
        const api = useFetch();

        const [isArbeitnehmer, setIsArbeitnehmer] = React.useState(ReactSession.get('login') ?? true);

        const passwordStyle = {
            position: 'absolute',
            top: '55%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '20%',
            minWidth: 200,
            maxHeight: '70%',
            overflow: 'auto',
            backgroundColor: 'rgba(218, 227, 243, 0.95)',
            border: '2px solid transparent !important',
            borderRadius: 5,
            padding: '5px 16px 20px',
            boxShadow: 24,
            zIndex: 4,
            lineHeight: '13px'
        };

        useImperativeHandle(ref, () => ({
            logOut() {
                logout();
            },
            logIn(mail, password) {
                return login(mail, password).then((e) => {
                    return e;
                });
            },
            reg(mail, accountType, company, password, passwordVerify, notify = true) {
                return register(mail, accountType, company, password, passwordVerify, notify).then((e) => {
                    return e;
                });
            },
            logNeeded(login = false) {
                return loginNeeded(login);
            },
            passForget(mail = '') {
                return passwordForget(mail);
            }
        }));


        const passwordForget = (mail) => {
            if (mail) setMailPasswordForget(mail);
            setOnPasswordForget(true)
        }

        useEffect(() => {
            if (history) getHistory(history);
        }, [history])

        const [onPasswordForget, setOnPasswordForget] = React.useState(false);
        const [onPasswordReset, setOnPasswordReset] = React.useState(false);
        const [anchorElNav, setAnchorElNav] = React.useState(false);
        const [anchorElUser, setAnchorElUser] = React.useState(false);
        const [loginOverlay, setLoginOverlay] = React.useState(false);
        const [mail, setMail] = React.useState('');
        const [mailError, setMailError] = React.useState(false);
        const [goBack, setGoBack] = React.useState(false);
        const [company, setCompany] = React.useState('');
        const [companyError, setCompanyError] = React.useState(false);
        const [mailPasswordForget, setMailPasswordForget] = React.useState('');
        const [mailPasswordForgetError, setMailPasswordForgetError] = React.useState(false);

        const [mailPassword1Reset, setMailPassword1Reset] = React.useState('');
        const [mailPassword1ResetError, setMailPassword1ResetError] = React.useState(false);

        const [mailPassword2Reset, setMailPassword2Reset] = React.useState('');
        const [mailPassword2ResetError, setMailPassword2ResetError] = React.useState(false);

        const [showPassword1, setShowPassword1] = useState(false);
        const [showPassword2, setShowPassword2] = useState(false);
        const handleClickShowPassword = () => {
            setShowPassword1(!showPassword1);
            setShowPassword2(!showPassword2);
        }
        // const [username, setUsername] = React.useState('');
        // const [usernameError, setUsernameError] = React.useState(false);
        const [password, setPassword] = React.useState('');
        const [passwordError, setPasswordError] = React.useState(false);
        const [passwordVerify, setPasswordVerify] = React.useState('');
        const [passwordVerifyError, setPasswordVerifyError] = React.useState(false);
        const [onLogin, setOnLogin] = React.useState(false);
        const [onRegistration, setOnRegistration] = React.useState(false);
        const [buttonHover, setButtonHover] = useState('');
        const [accountType, setAccountType] = useState(false);


        const [isShrink, setIsShrink] = useState([]);
        const [isShrinkPasswordForget, setIsShrinkPasswordForget] = useState([]);
        const [isShrinkPassword1Reset, setIsShrinkPassword1Reset] = useState([]);
        const [isShrinkPassword2Reset, setIsShrinkPassword2Reset] = useState([]);

        useEffect(() => {
            if (location !== history.location.pathname) {
                if ((history.location.pathname != '/Arbeitgeber' && ReactSession.get('user') == 'Arbeitnehmer') ||
                    (history.location.pathname == '/' && !ReactSession.get('user'))) {
                    setIsArbeitnehmer(true);
                } else if ((ReactSession.get('user') == 'Arbeitgeber' || (history.location.pathname == '/Arbeitgeber' && !ReactSession.get('login')))) {
                    setIsArbeitnehmer(false);
                }
            }

            if (location.token) {
                setOnPasswordReset(true);
            }
        }, [location]);
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            disableScrollLock: true,
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    minHeight: 'unset',
                },
            },
        };

        useEffect(() => {
            document.title = 'swiitcher';
        }, [])

        const login = async (mailT = null, passwordT = null, redirect = false) => {
            console.log('login');
            setMailError(false);
            setPasswordError(false);
            if (validate(mailT, passwordT)) {
                setOnLogin(true);

                let ma = mailT ?? mail;
                let pass = passwordT ?? password;

                let url = ENV.REACT_APP_API_URL + '/user/login';
                let { response, data } = await api(url, 'POST',
                    JSON.stringify({
                        email: ma.trim(),
                        password: pass.trim()
                    })).catch((error) => {
                    console.log(error);
                    return {
                        response: {
                            status: error.message,
                            error: true
                        },
                        data: null
                    }
                });
                let temp = false;

                if (response && data) {

                    if (data.errors && data.errors[0] && data.errors[0].status == '403' && data.errors[0].detail == "User does not exist") {
                        setMailError('Benutzer existiert nicht');
                    } else if (data.errors && data.errors[0] && data.errors[0].status == '403' && data.errors[0].detail == "User password is wrong") {
                        setPasswordError('Passwort ist fehlerhaft');
                    } else if (data.errors && data.errors[0] && data.errors[0].status == '403' && data.errors[0].detail == "User not confirmed") {
                        setMailError((
                            <span>Benutzer ist nicht bestätigt - <Link style={{ cursor: 'pointer' }} onClick={() => {
                                resendRegistration();
                            }}>erneut versenden</Link></span>));
                    } else if (data.errors && data.errors[0]) {
                        notify('API Fehler: ' + response.status, true);
                    } else if (data.data && data.data.token) {
                        console.log('right credentials');
                        ReactSession.set('login', mail);
                        ReactSession.set('description', data.data.user.description);
                        ReactSession.set('token', data.data.token);
                        ReactSession.set('refreshToken', data.data.refreshToken);
                        ReactSession.set('userId', data.data.user.id);
                        console.log(data.data.user.userType);
                        ReactSession.set('user', data.data.user.userType);
                        ReactSession.set('userCreatedAt', data.data.user.createdAt);
                        if (data.data.jobAdvertisementContingent) ReactSession.set('jobAdvertisementContingent', data.data.jobAdvertisementContingent);
                        ReactSession.set('fileId', data.data.user.file_id);
                        ReactSession.set('username', data.data.user.username);
                        temp = true;

                        if (data.data.user.userType == 'Arbeitgeber') {
                            setIsArbeitnehmer(false);
                            //setUser('Arbeitgeber');
                            update('Arbeitgeber');
                        } else {
                            setIsArbeitnehmer(true);
                            //setUser('Arbeitnehmer');
                            update('Arbeitnehmer');
                        }


                        notify('Du wurdest erfolgreich eingeloggt');
                        setLoginOverlay(false);
                        setPassword('');
                        setPasswordError(false);
                        if (goBack) {
                            setGoBack(false);
                            history.goBack();
                        } else if (redirect) {
                            history.push('/übersicht')
                        }
                    }

                    if (!mailT && !passwordT && false) {
                    } else {
                        return { mError: mailError, pError: passwordError, user: data.data?.user, success: temp }
                    }

                } else if (response.error) {
                    notify('API Fehler: ' + response.status, true);
                }
                setOnLogin(false);


            } else {
                let mError = '';
                let pError = '';
                if (mailT === '') {
                    mError = 'E-Mail darf nicht leer sein!';
                }
                if (!passwordT || passwordT !== passwordT.trim()) {
                    pError = 'Passwort enthält Leerzeichen!';
                }
                if (passwordT === '') {
                    pError = 'Passwort darf nicht leer sein!';
                }

                return { mError, pError, success: false }
            }

        };

        const validate = (mailT, passwordT) => {

            let ma = mailT ?? mail;
            let pass = passwordT ?? password;

            let error = false;
            if (ma === '') {
                error = true;
                setMailError('E-Mail darf nicht leer sein!');
            } else {
                setMailError(undefined);
            }
            if (pass === '') {
                error = true;
                setPasswordError('Passwort darf nicht leer sein!');
            } else {
                setPasswordError(undefined);
            }
            if (!pass || pass !== pass.trim()) {
                error = true;
                setPasswordError('Passwort enthält Leerzeichen!');
            }
            return !error;
        };

        const handleKeypress = e => {
            //it triggers by pressing the enter key
            if (e.charCode === 13) {
                login(null, null, true);
            }
        };


        const logout = () => {
            setLoginOverlay(false);
            console.log('LOGOUT')
            setTimeout( () => {
                setIsArbeitnehmer(true);
                ReactSession.set('description', undefined);
                ReactSession.set('temp-auth-key', undefined);
                ReactSession.set('user', undefined);
                ReactSession.set('token', undefined);
                ReactSession.set('login', undefined);
                ReactSession.set('menuState', false);
                update(null);
                notify('Du wurdest erfolgreich ausgeloggt');

                setTimeout(() => {
                    window.location.replace('https://www.swiitcher.de/')
                }, 500)
            }, 200)
        }

        const loginNeeded = (type = false) => {
            if (type === 'Arbeitgeber') {
                notify('Dieser Bereich ist nur für Arbeitgeber', true);
            } else if (type === 'Arbeitnehmer') {
                notify('Dieser Bereich ist nur für Arbeitnehmer', true);
            } else {
                setMailError(false);
                setPasswordError(false);
                setLoginOverlay(true);
                setGoBack(true);
                notify('Du musst dich vorher einloggen', true);
            }
            return true;
        }

        useEffect(() => {
            setMailError(false);
            // setUsernameError(false);
            setPasswordError(false);
            setPasswordVerifyError(false);
        }, [onRegistration])

        const register = async (mailT, accountTypeT, companyT, passwordT, passwordVerifyT, doNotify = true) => {
            console.log('register');

            let ma = mailT ?? mail;
            let co = companyT ?? company;
            let at = accountTypeT ?? accountType;
            let pa = passwordT ?? password;
            let paV = passwordVerifyT ?? passwordVerify;
            let mailError = null;
            let companyError = null;
            let passwordError = null;
            let passwordVerifyError = null;
            let temp = false;
            let errorType = false;
            let error = false;


            if (pa == '') {
                error = true;
                setPasswordError('Passwort darf nicht leer sein!');
                passwordError = 'Passwort darf nicht leer sein!';
            } else {
                setPasswordError(undefined);
            }

            if (pa && pa.length < 8) {
                error = true;
                setPasswordError('Passwort muss mindestens 8 Zeichen lang sein');
                passwordError = 'Passwort muss mindestens 8 Zeichen lang sein';
            } else {
                setPasswordError(undefined);
            }

            if (accountTypeT === 'Arbeitgeber' && (!companyT && company.length === 0) || !accountTypeT) {
                error = true;
                setCompanyError('Firmenname darf nicht leer sein!')
                companyError = 'Firmenname darf nicht leer sein!';
            } else {
                setCompanyError(undefined);
            }

            if (ma == '' || !accountTypeT) {
                error = true;
                setMailError('E-Mail darf nicht leer sein!');
                mailError = 'E-Mail darf nicht leer sein!';
            } else if (ma && !validateEmail(ma) || !accountTypeT) {
                error = true;
                setMailError('Keine gültige E-Mail!');
                mailError = 'Keine gültige E-Mail!';
            } else {
                setMailError(undefined);
            }

            if (paV == '' || !accountTypeT) {
                error = true;
                setPasswordVerifyError('Passwort darf nicht leer sein!');
                passwordVerifyError = 'Passwort darf nicht leer sein!';
            } else {
                setPasswordVerifyError(undefined);
            }

            if (pa !== pa.trim() || !accountTypeT) {
                error = true;
                setPasswordError('Passwörter enthalten Leerzeichen!');
                setPasswordVerifyError('Passwörter enthalten Leerzeichen!');
                passwordError = 'Passwörter enthalten Leerzeichen!';
                passwordVerifyError = 'Passwörter enthalten Leerzeichen!';
            } else if (pa.trim() !== paV.trim() || !accountTypeT) {
                error = true;
                setPasswordError('Passwörter stimmen nicht überein!');
                setPasswordVerifyError('Passwörter stimmen nicht überein!');
                passwordError = 'Passwörter stimmen nicht überein!';
                passwordVerifyError = 'Passwörter stimmen nicht überein!';
            }

            if (!accountTypeT) {
                error = false;
                setMailError(false);
                setPasswordError(false);
            }

            if (
                !error
            ) {
                let url = ENV.REACT_APP_API_URL + '/user/register';

                let dataa = {
                    data: {
                        attributes: {
                            email: ma.trim(),
                            companyName: (co && co.length > 0) ? co.trim() : null,
                            userType: at,
                            password: pa.trim(),
                        }
                    }
                };
                if (!accountTypeT || accountTypeT == 'Arbeitnehmer') {
                    dataa = {
                        data: {
                            attributes: {
                                email: ma.trim(),
                                password: pa.trim(),
                                userType: 'Arbeitnehmer'
                            }
                        }
                    };
                }
                const { response, data } = await api(url, 'POST', JSON.stringify(dataa));

                if (data && data.errors && data.errors[0] && data.errors[0].status == '401') {
                    console.log('wrong credentials');
                } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.email) {
                    error = data.errors[0].meta.email;
                    errorType = 'mail';
                    setMailError(data.errors[0].meta.email);
                    mailError = data.errors[0].meta.email;
                    // setMailError('E-Mail darf nicht leer sein!');
                } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.password) {
                    error = data.errors[0].meta.password;
                    errorType = 'password';
                    setPasswordError(data.errors[0].meta.password);
                    passwordError = data.errors[0].meta.password;
                } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.username) {
                    error = data.errors[0].meta.username;
                    errorType = 'mail';
                    setMailError(data.errors[0].meta.username);
                    mailError = data.errors[0].meta.username;
                } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.companyName) {
                    error = data.errors[0].meta.companyName;
                    errorType = 'companyName';
                    setCompanyError(data.errors[0].meta.companyName);
                    companyError = data.errors[0].meta.companyName;
                } else if (response.error) {
                    notify('API Fehler: ' + response.status, true);
                } else if (data &&
                    data.errors &&
                    data.errors[0] &&
                    data.errors[0].status == '400'
                ) {
                    console.log('bad');
                } else if ((data.errors && data.errors[0]) || response.status == '500') {
                    notify('API Fehler: ' + response.status, true);
                } else if (data.data && data.data.id) {
                    console.log('right credentials');
                    setMailError('');
                    setPasswordError('');
                    temp = true;
                    ReactSession.set('temp-auth-key', data.data.attributes.auth_key ?? data.data.auth_key)
                    if (doNotify) notify('Registrierung erfolgreich - Dir wurde eine E-Mail zugesendet. Bitte klicke auf den Link in der E-Mail um die Registrierung abzuschließen');
                }
            } else {
                console.log('KAPUTT');
            }

            if (mailT !== undefined && passwordT !== undefined && passwordVerifyT !== undefined) {
                return {
                    mError: mailError,
                    coError: companyError,
                    pError: passwordError,
                    pVError: passwordVerifyError,
                    success: temp,
                    error,
                    errorType
                }
            }
        };

        const resendRegistration = async () => {
            console.log('resendRegistration');

            let ma = mail;
            let pa = password;

            let mailError = null;
            let companyError = null;
            let passwordError = null;
            let passwordVerifyError = null;
            let temp = false;
            let errorType = false;
            let error = false;


            if (pa == '') {
                error = true;
                setPasswordError('Passwort darf nicht leer sein!');
                passwordError = 'Passwort darf nicht leer sein!';
            } else {
                setPasswordError(undefined);
            }

            if (pa && pa.length < 8) {
                error = true;
                setPasswordError('Passwort muss mindestens 8 Zeichen lang sein');
                passwordError = 'Passwort muss mindestens 8 Zeichen lang sein';
            } else {
                setPasswordError(undefined);
            }

            if (ma == '') {
                error = true;
                setMailError('E-Mail darf nicht leer sein!');
                mailError = 'E-Mail darf nicht leer sein!';
            } else if (ma && !validateEmail(ma)) {
                error = true;
                setMailError('Keine gültige E-Mail!');
                mailError = 'Keine gültige E-Mail!';
            } else {
                setMailError(undefined);
            }

            if (pa !== pa.trim()) {
                error = true;
                setPasswordError('Passwörter enthalten Leerzeichen!');
                setPasswordVerifyError('Passwörter enthalten Leerzeichen!');
                passwordError = 'Passwörter enthalten Leerzeichen!';
                passwordVerifyError = 'Passwörter enthalten Leerzeichen!';
            }

            error = false;
            setMailError(false);
            setPasswordError(false);

            if (
                !error
            ) {
                let url = ENV.REACT_APP_API_URL + '/user/register';
                let dataa = {
                    data: {
                        attributes: {
                            email: ma.trim(),
                            password: pa.trim()
                        }
                    }
                };
                const { response, data } = await api(url, 'POST', JSON.stringify(dataa));

                if (data && data.errors && data.errors[0] && data.errors[0].status == '401') {
                    console.log('wrong credentials');
                } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.email) {
                    error = data.errors[0].meta.email;
                    errorType = 'mail';
                    setMailError(data.errors[0].meta.email);
                    mailError = data.errors[0].meta.email;
                    // setMailError('E-Mail darf nicht leer sein!');
                } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.password) {
                    error = data.errors[0].meta.password;
                    errorType = 'password';
                    setPasswordError(data.errors[0].meta.password);
                    passwordError = data.errors[0].meta.password;
                } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.username) {
                    error = data.errors[0].meta.username;
                    errorType = 'mail';
                    setMailError(data.errors[0].meta.username);
                    mailError = data.errors[0].meta.username;
                } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.companyName) {
                    error = data.errors[0].meta.companyName;
                    errorType = 'companyName';
                    setCompanyError(data.errors[0].meta.companyName);
                    companyError = data.errors[0].meta.companyName;
                } else if (response.error) {
                    notify('API Fehler: ' + response.status, true);
                } else if (data &&
                    data.errors &&
                    data.errors[0] &&
                    data.errors[0].status == '400'
                ) {
                    console.log('bad');
                } else if ((data.errors && data.errors[0]) || response.status == '500') {
                    notify('API Fehler: ' + response.status, true);
                } else if (data.data && data.data.id) {
                    console.log('right credentials');
                    setMailError('');
                    setPasswordError('');
                    temp = true;
                    ReactSession.set('temp-auth-key', data.data.attributes.auth_key ?? data.data.auth_key)
                    notify('Registrierung erfolgreich - Dir wurde eine E-Mail zugesendet. Bitte klicke auf den Link in der E-Mail um die Registrierung abzuschließen');
                }
            } else {
                console.log('KAPUTT');
            }


        };

        const validateRegistration = (send = false, mailT, passwordT, passwordVerifyT, companyT, accountTypeT) => {
            let error = false;

            let ma = mailT ?? mail;
            let pa = passwordT ?? password;
            let paV = passwordVerifyT ?? passwordVerify;

            if (pa == '') {
                error = true;
                setPasswordError('Passwort darf nicht leer sein!');
            } else {
                setPasswordError(undefined);
            }

            if (accountTypeT === 'Arbeitgeber' && (!companyT && company.length === 0)) {
                error = true;
                setCompanyError('Firmenname darf nicht leer sein!')
            } else {
                setCompanyError(undefined);
            }

            if (ma == '') {
                error = true;
                setMailError('E-Mail darf nicht leer sein!');
            } else if (ma && !validateEmail(ma)) {
                error = true;
                setMailError('Keine gültige E-Mail!');
            } else {
                setMailError(undefined);
            }

            if (paV == '') {
                error = true;
                setPasswordVerifyError('Passwort darf nicht leer sein!');
            } else {
                setPasswordVerifyError(undefined);
            }

            if (pa !== pa.trim()) {
                error = true;
                setPasswordError('Passwörter enthalten Leerzeichen!');
                setPasswordVerifyError('Passwörter enthalten Leerzeichen!');
            } else if (pa.trim() !== paV.trim()) {
                error = true;
                setPasswordError('Passwörter stimmen nicht überein!');
                setPasswordVerifyError('Passwörter stimmen nicht überein!');
            }

            return !error;
        };

        const validateEmail = email => {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                );
        };

        const requestNewPassword = async () => {
            console.log('requestNewPassword');

            if (validatePasswortForget()) {
                let updatedAttributes = {
                    email: mailPasswordForget.trim()
                }

                let url = ENV.REACT_APP_API_URL + '/user/password-forgotten';
                const { response, data } = await api(url, 'POST', JSON.stringify(updatedAttributes));

                if (response.status == 200 || response.status == 201) {
                    ReactSession.set('userId', undefined);
                    ReactSession.set('token', undefined);
                    ReactSession.set('refreshToken', undefined);
                    //history.push('/');
                    setOnPasswordForget(false);
                    notify('Dir wurde eine E-Mail zugesendet. Bitte klicke auf den Link in der E-Mail um dein Passwort zu ändern');
                } else if (data && data.errors && data.errors[0] && data.errors[0].status == 403) {
                    setMailPasswordForgetError("Unbekannte E-Mail-Adresse");
                } else if (data && data.errors && data.errors[0] && data.errors[0].status == 400) {
                    setMailPasswordForgetError("Fehlerhafte E-Mail-Adresse");
                } else if ((data && data.errors && data.errors[0]) || response.error) {
                    notify('API Fehler: ' + response.status, true);
                }
            }

        };

        const updatePassword = async () => {
            console.log('requestNewPassword');


            if (validateNewPassword() && location.token) {

                let updatedAttributes = {
                    password: mailPassword1Reset,
                    token: location.token
                };

                let url = ENV.REACT_APP_API_URL + '/user/new-password';
                const { response, data } = await api(url, 'POST', JSON.stringify(updatedAttributes));

                if (response && response.status != '401' && data) {
                    ReactSession.set('newPassword', undefined);
                    ReactSession.set('token', undefined);
                    ReactSession.set('refreshToken', undefined);
                    setOnPasswordReset(false);
                    notify('Dein Passwort wurde erfolgreich aktualisiert');
                } else if ((data.errors && data.errors[0]) && response.status == '401') {
                    notify('Dieser Token ist nicht mehr gültig', true);
                } else if ((data.errors && data.errors[0]) || response.status == '500') {
                    notify('API Fehler: ' + response.status, true);
                }
            }
        };

        const validateNewPassword = () => {
            let error = false;


            let lowerCaseLetters = /[a-z]/g;
            if (!mailPassword1Reset.match(lowerCaseLetters)) {
                error = 'Passwort enthält keinen Kleinbuchstaben';
            }

            // Validate capital letters
            let upperCaseLetters = /[A-Z]/g;
            if (!mailPassword1Reset.match(upperCaseLetters)) {
                error = 'Passwort enthält keinen Großbuchstaben';
            }

            // Validate numbers
            let numbers = /[0-9]/g;
            if (!mailPassword1Reset.match(numbers)) {
                error = 'Passwort enthält keine Zahl';
            }

            // Validate special chars
            let chars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            if (!mailPassword1Reset.match(chars)) {
                error = 'Passwort enthält kein Sonderzeichen';
            }

            // Validate length
            if (mailPassword1Reset.length < 8) {
                console.log('to short')
                error = 'Passwort muss mindestens 8 Zeichen lang sein'
            }

            // Validate length
            if (mailPassword1Reset.length === '') {
                error = 'Passwort darf nicht leer sein!'
            }

            if (mailPassword1Reset.trim() !== mailPassword2Reset.trim()) {
                error = true;
                setMailPassword1ResetError('Passwörter stimmen nicht überein!');
                setMailPassword2ResetError('Passwörter stimmen nicht überein!');
            } else {
                setMailPassword1ResetError(error)
                setMailPassword2ResetError(undefined)
            }

            if (!error) {
                setMailPassword1ResetError(undefined);
                setMailPassword2ResetError(undefined);
            }

            return !error;

        };


        const validatePasswortForget = (send = false) => {
            var error = false;
            if (mailPasswordForget === '' || (send && !mailPasswordForget)) {
                setMailPasswordForgetError('E-Mail darf nicht leer sein!');
                error = true;
            } else if (!validateEmail(mailPasswordForget)) {
                setMailPasswordForgetError('Fehlerhafte E-Mail-Adresse');
                error = true;
            } else {
                setMailPasswordForgetError(undefined);
            }

            return !error;

        };

        const handleMouseIn = (type) => {
            setButtonHover(type)
        };

        const handleMouseOut = () => {
            setButtonHover(null)
        };

        // ? typo3 params
        const urlParams = new URLSearchParams(window.location.search);
        const iFrameActive = urlParams.get('iframe') === 'true'
        // ? isNewLoginParams
        const isNewLoginLocation = window.location.pathname.toLowerCase().includes('login')
        // ? dont show appBar if questions-view
        const isQuestionsView = window.location.pathname.toLowerCase().includes('anonym-swiitchen')

        if (!iFrameActive && !isNewLoginLocation && !isQuestionsView) return (
            <div style={{}}>
                <AppBar position="static"
                        style={{
                            fontFamily: 'VagRoundedBold',
                            background: isArbeitnehmer ? '#00374e' : 'white',
                            height: 64
                        }}>
                    <Container maxWidth="xxl" style={{ height: 64 }}>
                        <Toolbar disableGutters style={{ height: 64 }}>
                            {/*<img id={'logo'} src={logoSvgWhite} alt={'Logo'} style={{width: '210px'}} />*/}
                            {isArbeitnehmer && <LogoSvgWhite
                                style={{ cursor: 'pointer', width: 210, maxWidth: '40%', paddingRight: 10 }}
                                id={'logoHeaderWhite'}
                                onClick={() => {
                                    console.log("history.push('/')")
                                    history.push('/')
                                }}
                            />}
                            {!isArbeitnehmer && <LogoSvg
                                style={{ cursor: 'pointer', width: 210, maxWidth: '40%', paddingRight: 10 }}
                                id={'logoHeader'}
                                onClick={() => {
                                    console.log("history.push('/arbeitgeber')")
                                    history.push('/arbeitgeber')
                                }}
                            />}
                            <Box sx={{ flexGrow: 1 }}>
                            </Box>

                            {(!ReactSession.get('user') || ReactSession.get('user') == 'Arbeitnehmer') &&
                                <p className={'color-yellow ' + (!ReactSession.get('user') ? 'menuAppBarType ' : '')}
                                   onClick={() => history.push('/jobsuche')} style={{
                                    cursor: 'pointer',
                                    padding: '0 10px 0 0',
                                }}>Stellenangebote</p>}
                            {(ReactSession.get('user') == 'Arbeitnehmer') &&
                                <p className={(((ReactSession.get('user') && isArbeitnehmer)) ? 'color-yellow' : (ReactSession.get('user') == 'Arbeitnehmer' || (!ReactSession.get('user'))) ? '' : 'color-blue') + (!ReactSession.get('user') ? ' menuAppBarType' : '')}
                                   onClick={() => history.push('/')} style={{
                                    cursor: 'pointer',
                                    padding: '0 10px 0 0',
                                }}></p>}
                            {(!ReactSession.get('user') || ReactSession.get('user') == 'Arbeitgeber') &&
                                <p className={(((ReactSession.get('user') && !isArbeitnehmer)) ? 'color-yellow' : (ReactSession.get('user') == 'Arbeitnehmer' || (location.pathname != '/arbeitgeber' && !ReactSession.get('user'))) ? '' : '') + (!ReactSession.get('user') ? ' menuAppBarType' : '')}
                                   onClick={() => history.push('/arbeitgeber')} style={{
                                    cursor: 'pointer',
                                    padding: '0 10px 0 0',
                                }}></p>}
                            <IconButton style={{ padding: 0, transition: 'all 0.2s ease-in-out' }} onClick={() => {
                                if (!ReactSession.get('user')) {
                                    if (loginOverlay) {
                                        setButtonHover(false);
                                    }
                                    setMailError(false);
                                    setPasswordError(false);
                                    setLoginOverlay(!loginOverlay);
                                } else {
                                    changeMenuState();
                                }
                            }}>
                                {!ReactSession.get('user') &&
                                    <button className={'fadeInOut'}
                                            onMouseOver={() => handleMouseIn('LoginButton')}
                                            onMouseOut={() => handleMouseOut()}
                                            style={{
                                                fontFamily: 'VagRoundedBold',
                                                background: buttonHover === 'LoginButton' ? 'rgba(0, 55, 78, 0.5)' : '#C7D300',
                                                cursor: 'pointer',
                                                margin: 'auto',
                                                textAlign: 'center',
                                                padding: isMobile ? '7px 10px 6px' : '7px 15px 6px',
                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                color: buttonHover === 'LoginButton' ? '#C7D300' : '#00374e',
                                                border: '2px solid ' + (buttonHover === 'LoginButton' ? '#C7D300' : '#C7D300'),
                                                fontSize: 12,
                                                minWidth: 60
                                            }}>
                                        LOGIN
                                    </button>}

                                <div style={{ display: 'flex', position: 'relative' }}>
                                    {(ReactSession.get('user') && !menuState) && <MenuCloseIcon
                                        // color={login2ButtonHover ? '#C7D300' : '#00374e'}
                                        //className={(isArbeitnehmer ? 'loginSvgWhite ' : 'loginSvg ') + (login2ButtonHover ? 'yellow' : 'blue') }
                                        className={(isArbeitnehmer ? 'Test ' : 'TestF ') + ((buttonHover == 'login2Button' || menuState) ? 'blue' : 'yellow')}
                                        onMouseOver={() => setButtonHover('login2Button')}
                                        onMouseOut={() => setButtonHover('')}
                                        style={{
                                            transition: 'all 0.2s ease-in-out',
                                            width: 25,
                                            height: 25,
                                            cursor: 'pointer',
                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                            padding: 1
                                        }}
                                    />}
                                    {(ReactSession.get('user') && menuState) && <MenuOpenIcon
                                        // color={login2ButtonHover ? '#C7D300' : '#00374e'}
                                        //className={(isArbeitnehmer ? 'loginSvgWhite ' : 'loginSvg ') + (login2ButtonHover ? 'yellow' : 'blue') }
                                        className={(isArbeitnehmer ? 'Test ' : 'TestF ') + ((buttonHover == 'login2Button' || menuState) ? 'blue' : 'yellow')}
                                        onMouseOver={() => setButtonHover('login2Button')}
                                        onMouseOut={() => setButtonHover('')}
                                        style={{
                                            transition: 'all 0.2s ease-in-out',
                                            width: 25,
                                            height: 25,
                                            cursor: 'pointer',
                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                            padding: 1
                                        }}
                                    />}
                                    {(ReactSession.get('user') && !menuState && overAllCount && overAllCount > 0) ?
                                        <div className={'count'} style={{
                                            fontFamily: 'VagRoundedBold',
                                            top: -18,
                                            right: -20
                                        }}>{overAllCount}</div> : null}

                                </div>
                            </IconButton>

                            {!ReactSession.get('user') && <div style={{
                                position: 'absolute',
                                top: 68,
                                right: 0,
                                borderTopLeftRadius: 4,
                                borderTopRightRadius: 4,
                                borderBottomLeftRadius: 25,
                                borderBottomRightRadius: 25,
                                boxShadow: '0px 0px 4px 3px rgba(0,0,0,0.5)',
                                width: 230,
                                //height: onRegistration ? 445 : 245,
                                paddingBottom: 15,
                                background: 'white',
                                opacity: loginOverlay ? 1 : 0,
                                display: loginOverlay ? 'block' : 'none',
                                transition: 'all 0.05s ease-in-out'
                            }}>
                                {(!onRegistration) && <div style={{ padding: '15px 15px 15px 15px' }}>
                                    {/*<p style={{fontFamily: 'VagRoundedBold', paddingBottom: 20}}>Login</p>*/}
                                    <CssTextField3
                                        fieldtype={'ASDF'}
                                        label="E-Mail"
                                        // InputLabelProps={{
                                        //     shrink: true
                                        // }}
                                        // className={'text-input-label-blue'}
                                        style={{
                                            transition: 'all 0.2s ease',
                                            // margin: '0 0 8px 0',
                                            // minHeight: 60,
                                            marginTop: (isShrink && isShrink.includes('E-Mail') || mail) ? 20 : 5,
                                            width: '100%'
                                        }}
                                        onFocus={() => {
                                            let t = [...isShrink];
                                            t.push('E-Mail');
                                            setIsShrink(t);
                                        }}
                                        onBlur={() => {
                                            setIsShrink([]);
                                        }}
                                        autoComplete={'off'}
                                        onChange={e => {
                                            if (e.target.value.trim().length === 0) {
                                                setMailError(true);
                                            } else {
                                                setMailError(false);
                                            }
                                            setMail(e.target.value);
                                        }}
                                        className={'text-input-label-blue'}
                                        onKeyPress={handleKeypress}
                                        value={mail}
                                        error={!!mailError}
                                        helperText={mailError === true ? 'Benutzername darf nicht leer sein!' : mailError}
                                    />
                                    <CssTextField3
                                        label="Passwort"
                                        // InputLabelProps={{
                                        //     shrink: true
                                        // }}
                                        type={showPassword1 ? "text" : "password"}
                                        InputProps={{ // <-- This is where the toggle button is added.
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                    >
                                                        {showPassword1 ? <Visibility style={{ color: '#C7D300' }}/> :
                                                            <VisibilityOff style={{ color: '#C7D300' }}/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            autocomplete: 'new-password',
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        // className={'text-input-label-blue'}
                                        style={{
                                            transition: 'all 0.2s ease',
                                            marginTop: (isShrink && isShrink.includes('Passwort') || password) ? 25 : 5,
                                            marginBottom: 10,
                                            width: '100%'
                                        }}
                                        onFocus={() => {
                                            setIsShrink(['Passwort']);
                                        }}
                                        onBlur={() => {
                                            setIsShrink([]);
                                        }}
                                        onChange={e => {
                                            if (e.target.value.trim().length === 0) {
                                                setPasswordError(true);
                                            } else {
                                                setPasswordError(false);
                                            }
                                            setPassword(e.target.value);
                                        }}
                                        className={'text-input-label-blue'}
                                        onKeyPress={handleKeypress}
                                        value={password}
                                        error={!!passwordError}
                                        helperText={passwordError === true ? 'Passwort darf nicht leer sein!' : passwordError}
                                    />
                                    <CustomButton
                                        buttontype={'blueYellow'}
                                        onClick={() => {
                                            login(null, null, true);
                                        }}
                                        style={{}}
                                    >LOGIN</CustomButton>
                                    <div style={{
                                        marginTop: 16,
                                        lineHeight: 0.8,
                                        fontFamily: 'VagRoundedRegular',
                                        marginLeft: 0,
                                        textAlign: 'left',
                                        float: 'left'
                                    }}>
                                        <a
                                            onClick={() => {
                                                if (mail) setMailPasswordForget(mail);
                                                setOnPasswordForget(true)
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                                fontSize: 10,
                                                textAlign: 'left',
                                                textDecoration: 'none'
                                            }} className={'color-blue'}>Passwort
                                            vergessen?</a>
                                    </div>
                                    <div style={{
                                        textAlign: 'right',
                                        float: 'right',
                                        marginTop: 10,
                                    }}>
                                        <CustomButton
                                            buttontype={'gray'}
                                            onClick={() => {
                                                setLoginOverlay(false);
                                                history.push('/registrierung');
                                            }}
                                            style={{
                                                fontSize: 10,
                                                color: 'black',
                                            }}
                                        >Registrieren</CustomButton>
                                    </div>
                                </div>}
                            </div>}
                        </Toolbar>
                    </Container>
                </AppBar>
                <Modal
                    open={onPasswordForget}
                    onClose={() => {
                        setMailPasswordForget('');
                        setMailPasswordForgetError(false);
                        setOnPasswordForget(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={passwordStyle}>
                        <h3 style={{ marginTop: 0, marginBottom: 30, fontFamily: 'VagRoundedBold' }}>Passwort
                            vergessen<QuestionMarkIcon
                                style={{
                                    color: '#92D050',
                                    marginBottom: -5,
                                    width: 40,
                                    height: 40,
                                }}
                            /></h3>

                        <p style={{ fontSize: 12 }}>Gib hier deine E-Mail-Adresse ein. Du erhälst von uns umgehend eine Mail,
                            mit der du dein Passwort zurücksetzen kannst.</p>

                        <CssTextField3
                            label="E-Mail-Adresse"
                            // InputLabelProps={{
                            //     shrink: true
                            // }}
                            // className={'text-input-label-blue'}
                            style={{
                                transition: 'all 0.2s ease',
                                // margin: '0 0 8px 0',
                                // minHeight: 60,
                                marginTop: (isShrinkPasswordForget && isShrinkPasswordForget.includes('E-Mail') || mailPasswordForget) ? 20 : 5,
                                width: '100%'
                            }}
                            onFocus={() => {
                                let t = [...isShrinkPasswordForget];
                                t.push('E-Mail');
                                setIsShrinkPasswordForget(t);
                            }}
                            onBlur={() => {
                                setIsShrinkPasswordForget([]);
                            }}
                            autoComplete={'off'}
                            onChange={e => {
                                if (e.target.value.trim().length === 0) {
                                    setMailPasswordForgetError(true);
                                } else {
                                    setMailPasswordForgetError(false);
                                }
                                setMailPasswordForget(e.target.value);
                            }}
                            className={'label-black'}
                            // onKeyPress={handleKeypress}
                            value={mailPasswordForget}
                            error={!!mailPasswordForgetError}
                            helperText={mailPasswordForgetError}
                        />
                        <CustomButton
                            buttontype={'yellowBlue'}
                            onClick={() => {
                                requestNewPassword();
                                console.log('Passwort vergessen senden')
                            }}
                            style={{
                                marginTop: 20,
                                width: '100%'
                            }}
                        >Senden</CustomButton>
                        <p style={{ fontSize: 10 }}>E-Mail-Adresse vergessen? Dann schreib uns gerne eine Nachricht an <a
                            href={'mailto:info@swiitcher.de'}>info@swiitcher.de</a></p>
                    </Box>
                </Modal>
                <Modal
                    open={onPasswordReset}
                    onClose={() => {
                        setMailPassword1Reset('');
                        setMailPassword1ResetError(false);
                        setMailPassword2Reset('');
                        setMailPassword2ResetError(false);
                        setOnPasswordReset(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={passwordStyle}>
                        <h3 style={{ marginTop: 0, marginBottom: 30, fontFamily: 'VagRoundedBold' }}>Neues Passwort
                            vergeben<PriorityHigh
                                style={{
                                    color: '#92D050',
                                    marginBottom: -5,
                                    width: 40,
                                    height: 40,
                                }}
                            /></h3>

                        <p style={{ fontSize: 12 }}>Dein Passwort muss min. 8 Zeichen lang sein, einen Groß- und einen
                            Kleinbuchstaben sowie ein Sonderzeichen bzw. eine Zahl enthalten. Es muss sich von deinem alten
                            Passwort unterscheiden.</p>

                        <CssTextField3
                            label="Passwort"
                            // InputLabelProps={{
                            //     shrink: true
                            // }}
                            // className={'text-input-label-blue'}
                            style={{
                                transition: 'all 0.2s ease',
                                // margin: '0 0 8px 0',
                                // minHeight: 60,
                                marginTop: (isShrinkPassword1Reset && isShrinkPassword1Reset.includes('E-Mail') || mailPassword1Reset) ? 20 : 5,
                                width: '100%'
                            }}
                            autoFocus
                            type={showPassword1 ? "text" : "password"}
                            InputProps={{ // <-- This is where the toggle button is added.
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {showPassword1 ? <Visibility style={{ color: '#C7D300' }}/> :
                                                <VisibilityOff style={{ color: '#C7D300' }}/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                autocomplete: 'new-password',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            onFocus={() => {
                                let t = [...isShrinkPassword1Reset];
                                t.push('E-Mail');
                                setIsShrinkPassword1Reset(t);
                            }}
                            onBlur={() => {
                                setIsShrinkPassword1Reset([]);
                            }}
                            autoComplete="new-password"
                            onChange={e => {
                                if (e.target.value.trim().length === 0) {
                                    setMailPassword1ResetError(true);
                                } else {
                                    setMailPassword1ResetError(false);
                                }
                                setMailPassword1Reset(e.target.value);
                            }}
                            className={'label-black'}
                            // onKeyPress={handleKeypress}
                            value={mailPassword1Reset}
                            error={!!mailPassword1ResetError}
                            helperText={mailPassword1ResetError}
                        />
                        <CssTextField3
                            label="Passwort wiederholen"
                            // InputLabelProps={{
                            //     shrink: true
                            // }}
                            // className={'text-input-label-blue'}
                            style={{
                                transition: 'all 0.2s ease',
                                // margin: '0 0 8px 0',
                                // minHeight: 60,
                                marginTop: (isShrinkPassword2Reset && isShrinkPassword2Reset.includes('E-Mail') || mailPassword2Reset) ? 20 : 5,
                                width: '100%'
                            }}
                            type={showPassword2 ? "text" : "password"}
                            InputProps={{ // <-- This is where the toggle button is added.
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {showPassword2 ? <Visibility style={{ color: '#C7D300' }}/> :
                                                <VisibilityOff style={{ color: '#C7D300' }}/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                autocomplete: 'new-password',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            onFocus={() => {
                                let t = [...isShrinkPassword2Reset];
                                t.push('E-Mail');
                                setIsShrinkPassword2Reset(t);
                            }}
                            onBlur={() => {
                                setIsShrinkPassword2Reset([]);
                            }}
                            autoComplete="new-password"
                            onChange={e => {
                                if (e.target.value.trim().length === 0) {
                                    setMailPassword2ResetError(true);
                                } else {
                                    setMailPassword2ResetError(false);
                                }
                                setMailPassword2Reset(e.target.value);
                            }}
                            className={'label-black'}
                            // onKeyPress={handleKeypress}
                            value={mailPassword2Reset}
                            error={!!mailPassword2ResetError}
                            helperText={mailPassword2ResetError}
                        />
                        <CustomButton
                            buttontype={'yellowBlue'}
                            onClick={() => {
                                updatePassword();
                                console.log('Neues Passwort senden')
                            }}
                            style={{
                                marginTop: 20,
                                width: '100%'
                            }}
                        >Speichern</CustomButton>
                    </Box>
                </Modal>
            </div>
        );
    })
;
export default MenuAppBar;
